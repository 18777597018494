var Backbone = require('backbone');
var $ = require("jquery");
var helpers = require('../../config/helpers');
var UsersCollection = require('../collections/users');
var UserBriefView = require('../views/list_user');
var NewsCollection = require('../collections/news');
var NewsView = require('../views/news');
var template = require("../templates/social.hbs");
var Events = require("../events");

module.exports = Backbone.View.extend({
    tagName: "div",
    className: "social-container",
    events: {
        'click .social-header': "headerClick",
        'click .social-tab-item': "tabClick",
    },
    headerClick: function() {
        this.$el.toggleClass('open');
    },
    tabClick: function(e) {
        e.preventDefault();
        $('.social-tab-item').removeClass('active');
        $(e.currentTarget).addClass('active');
        this.$el.find('.social-list').attr('data-active', $(e.currentTarget).attr('data-toggles'));
        if ($(e.currentTarget).attr('data-toggles') === "selling") this.$el.find('.selling-list-hide').click();
    },
    initialize: function() {
        this.initNews();
        this.initFollowers();
        this.initFollows();
        this.$socialToggle = $('header .menu-item.toggle-social');
        var Handlebars = require('hbsfy/runtime');
        var hbsConfig = require('../../config/handlebars');
        hbsConfig.generic(Handlebars);
        hbsConfig.ad(Handlebars);
    },
    initFollowers: function() {
        var self = this;
        this.followers = new UsersCollection({
            path: 'followers'
        });
        this.followers.fetch({
            reset: true
        });
        window._cache.social.followers = this.followers;
        this.listenTo(this.followers, "change", this.renderFollowers);
        this.listenTo(this.followers, "reset", this.renderFollowers);
    },
    initFollows: function() {
        var self = this;
        this.follows = new UsersCollection({
            path: 'follows'
        });
        this.follows.fetch({
            reset: true
        });
        window._cache.social.follows = this.follows;
        this.listenTo(this.follows, "change", this.renderFollows);
        this.listenTo(this.follows, "reset", this.renderFollows);
    },
    initNews: function() {
        var self = this;
        this.news_collection = new NewsCollection();
        this.news_collection.fetch({
            reset: true
        });
        window._cache.social.news = this.news_collection;
        this.listenTo(this.news_collection, "change", this.renderNews);
        this.listenTo(this.news_collection, "reset", this.renderNews);
    },
    renderFollowers: function() {
        // console.log("Rendering Followers");
        var self = this;
        this.followers_views = this.followers_views || {};
        this.followers.each(function(el) {
            self.followers_views[el.id] = self.followers_views[el.id] || new UserBriefView({ model: el });
            self.$followersListView.append(self.followers_views[el.id].render().el);
        });
    },
    renderFollows: function() {
        // console.log("Rendering Follows");
        var self = this;
        this.follows_views = this.follows_views || {};
        this.follows.each(function(el) {
            self.follows_views[el.id] = self.follows_views[el.id] || new UserBriefView({ model: el });
            self.$followsListView.append(self.follows_views[el.id].render().el);
        });
    },
    renderNews: function() {
        var self = this;
        this.news_collection.each(function(el) {
            var newsView = window._cache.views.news[el.id] || new NewsView({ model: el });
            self.$newsListView.append(newsView.render().el);
        });
    },
    countUnread: function() {
        var tabs = this.$el.find('.social-tab-item');
        var unread = this.news_collection.getUnread();
        tabs.filter('[data-toggles="news"]').attr('unread', unread);
        this.$socialToggle.attr('unread', unread);
    },
    render: function() {
        var self = this;
        if (!this.$el.children().length) this.$el.html(template());
        this.$newsListView = this.$el.find('.news-list');
        this.$followersListView = this.$el.find('.followers .users-list');
        this.$followsListView = this.$el.find('.following .users-list');
        this.countUnread();
        return this;
    },
});