var Backbone = require('backbone');
var Conversation = require('../models/conversation');

module.exports = Backbone.Collection.extend({
    model: Conversation,
    url: function () {
        var url = "/api/";
        if (!this.ad) {
            url += "conversations/buying";
        } else {
            url += "conversations/buying";
        }
        return url;
    },
    page: 1,
    category: 0,
    parse: function(resp, xhr) {
        return resp.conversations;
    },
    comparator: function(model) {
        return -1 * new Date(model.get("updated_at"));
    }
});