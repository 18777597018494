var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  return "col-xs-6";
  },"3":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <a class=\"register-button btn-plick btn-flat triggers-login\" href=\"\" target=\"_blank\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Register/Log in", "menu", {"name":"translate","hash":{},"data":data})))
    + "</a>\n";
},"5":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "<div class=\"col-sm-6 flex-center\">\n    <img src=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.image : depth0)) != null ? stack1.url : stack1), depth0))
    + "\">\n</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.image : depth0)) != null ? stack1.url : stack1), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "text-container flex-left\">\n\n    <h1 class=\"big-title\">"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</h1>\n    <h3>"
    + escapeExpression(((helper = (helper = helpers.subtitle || (depth0 != null ? depth0.subtitle : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"subtitle","hash":{},"data":data}) : helper)))
    + "</h3>\n    <div class=\"download-buttons\">\n        <a href=\"https://go.plick.se/campaign/download-the-app\"\n            onclick=\"dataLayer.push({ event: 'appstore', link: 'iTunes', item: 'landing'});\"\n            target=\"_blank\" class=\"app-store-link btn-plick btn-black\">\n            "
    + escapeExpression(((helper = (helper = helpers.download_app_button_label || (depth0 != null ? depth0.download_app_button_label : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"download_app_button_label","hash":{},"data":data}) : helper)))
    + "\n        </a>\n    </div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.show_register_button : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.image : depth0)) != null ? stack1.url : stack1), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});