var Backbone = require('backbone');
var MiniConversationView = require('../views/conversation_brief');
var ConversationCollection = require('../collections/conversations');
var Conversation = require('../models/conversation');
var Events = require("../events");
var $ = require("jquery");

module.exports = Backbone.View.extend({
	tagName: "div",
	className: "conversation-table",
	events : {
		"click .conversation-list" : "listClick",
	},
	initialize: function (options) {
    	var self = this;
		self.conversations = new ConversationCollection();
    	if (!options || !options.conversations) {
    		self.conversations.fetch({
		        reset: true
		    });
    	} else if (options && options.conversations) {
    		self.conversations = options.conversations;
    		self.render();
    	}
	    this.listenTo(this.conversations, "reset", this.renderConversations);
	    this.listenTo(this.conversations, "change", this.renderConversations);
    	Events.on("update:timestamps", this.updateTimestamps.bind(this));
  	},
	initHeader: function() {

	},
	getUnread: function() {
		var unread_count = 0;
		this.conversations.each(function(conversation) {
			if (conversation.get('unread_count')) unread_count += conversation.get('unread_count');
		});
		return unread_count;
	},
	listClick: function(e) {
		var cLink = $(e.target).parents('.conversation-link');
		if (cLink.length) {
			var c = window._cache.conversations[cLink.attr('data-id')];
			c.renderAndFetch();
			e.preventDefault();
		}
	},
	updateTimestamps: function() {
		this.conversations.each(function(conversation) {
		  var view = window._cache.views.conversation_mini[conversation.id];
		  view.renderTimestamp();
		});
	},
	renderConversations: function() {
		var self = this;
		this.unread_count = 0;
		self.conversations.sort();
		self.conversations.each(function(conversation) {
			window._cache.conversations[conversation.id] = conversation;
			// console.log("Conversation " + conversation.id + " saved in cache");
			var view = window._cache.views.conversation_mini[conversation.id] || new MiniConversationView({model:conversation});
			window._cache.views.conversation_mini[conversation.id] = view.render();
			self.$list.append(window._cache.views.conversation_mini[conversation.id].el);
			if (window._cache.items[conversation.ad.id]) window._cache.items[conversation.ad.id].conversations.add(conversation);
			self.unread_count += conversation.attributes.unread_count;
		});
	},
	render: function() {
		var self = this;
		if (this.item) this.initHeader();
		if (!self.$list) {
			self.$list = $('<div class="conversation-list scroll-y"></div>');
			self.$el.append(self.$list);
		}
		self.renderConversations();
		return self;
	}
});