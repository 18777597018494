var Backbone = require('backbone');
var Router = require("./router");
var Events = require("./events");
require('./views/collection');

var App = {};
App.start = function() {
    window.$ = Backbone.$;
    extendJquery($);
    new Router();
    Backbone.history.start({ pushState: true });
};

function formatAdToPixelTrack({ hash_id, currency = "SEK", price = 0 }) {
    return {
        value: (price / 100),
        currency: currency,
        content_ids: [hash_id]
    }
}
function formatAdToSnapchatPixelTrack({ hash_id, currency = "SEK", price = 0 }) {
  return {
      item_ids: [hash_id]
  }
}
document.addEventListener("trigger-event", function(e) {
    Events.trigger('router:' + e.detail);
});

window.elementInViewport = function(el) {
    var top = el.offsetTop;
    var left = el.offsetLeft;
    var width = el.offsetWidth;
    var height = el.offsetHeight;

    while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
    }

    return (
        top >= window.pageYOffset &&
        left >= window.pageXOffset &&
        (top + height) <= (window.pageYOffset + window.innerHeight) &&
        (left + width) <= (window.pageXOffset + window.innerWidth)
    );
}
window.raf = (function() {
    return window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        function(callback) {
            window.setTimeout(callback, 1000 / 60);
        };
})();
window._tracking = {};
window._tracking.getCurrentUserId = function() {
  if(!!window._SESSION && !!window._SESSION.current_user) {
    return window._SESSION.current_user.hash_id
  }
  return null
}
window._tracking.login = function(type, user) {
    if (window.dataLayer) {
        window.dataLayer.push({
            'event': 'login',
            'category': type,
            'user_id': user.hash_id
        })
    }
    if (window.fbq) {
        window.fbq('track', 'AddPaymentInfo');
    }
};
window._tracking.interaction = function(type, label, attributes = {}) {
    if (window.dataLayer) {
        label = label ||  "N/A";
        window.dataLayer.push({
            'event': 'interaction',
            'category': type,
            'label': label
        });
    }
    if (type == 'like' && window.fbq) {
        window.fbq('track', 'AddToCart', formatAdToPixelTrack(attributes));
    }
    if (type == 'contact' && window.fbq) {
        window.fbq('track', 'InitiateCheckout', formatAdToPixelTrack(attributes));
    }
};

window._tracking.conversation = function(type) {
    if (window.dataLayer) {
        window.dataLayer.push({
            'event': 'conversation',
            'category': type
        })
    }
    if (window.fbq) {
        window.fbq('track', 'Purchase');
    }
};
window._tracking.registration = function(type) {
    if (window.dataLayer) {
        window.dataLayer.push({
            'event': 'registration',
            'category': type
        })
    }
};
window._tracking.view = function(attributes, route) {
    if (window.fbq && attributes) {
        window.fbq('track', 'ViewContent', formatAdToPixelTrack(attributes));
    }
    if (window.snaptr && attributes) {
      window.snaptr('track', 'VIEW_CONTENT', formatAdToSnapchatPixelTrack(attributes));
    }
    if(window.dataLayer) {
      window.dataLayer.push({
          event: 'pageview',
          url: window.location.href,
          user_id: this.getCurrentUserId()
      });
    }
}

window._tracking.impressions = function(ads, list, pos) {
        if (ads.length && window.dataLayer) {
            var impressions = [];
            if (!ads[0]) ads = ads.toArray();
            for (var i = 0; i < ads.length; i++) {
                var ad = ads[i].attributes || ads[i];
                impressions.push({
                    'name': ad.hash_id, // Name or ID is required.
                    'id': ad.hash_id,
                    'price': (ad.price / 100),
                    'brand': ad.user.hash_id,
                    'category': ad.category.name,
                    'list': list,
                    'position': pos + i + 1
                });
            }
            var impression = {
                'ecommerce': {
                    'currencyCode': 'SEK', // Local currency is optional.
                    'impressions': impressions
                },
                'event': 'impressionsPushed'
            };
            dataLayer.push(impression);
        }
    }

window._tracking.trackAppstoreClick = function(link, currency, adId, price, category, brand, title) {
    if (window.dataLayer) {
        window.dataLayer.push({ 
            event: 'appstore',
            link: link,
            item: adId, 
            ecommerce: {
                'currencyCode': currency,
                'add': {
                    'products': [{
                        'name' : title,
                        'id' : adId,
                        'price': (price / 100),
                        'category': category,
                        'quantity': 1,
                        'brand': brand, 
                    }]
                }
            }
        });
    }
}
    /*
      jQuery deparam is an extraction of the deparam method from Ben Alman's jQuery BBQ
      http://benalman.com/projects/jquery-bbq-plugin/
    */
function extendJquery($) {
    $.deparam = function(params, coerce) {
        var obj = {},
            coerce_types = { 'true': !0, 'false': !1, 'null': null };

        // Iterate over all name=value pairs.
        $.each(params.replace(/\+/g, ' ').split('&'), function(j, v) {
            var param = v.split('='),
                key = decodeURIComponent(param[0]),
                val,
                cur = obj,
                i = 0,

                // If key is more complex than 'foo', like 'a[]' or 'a[b][c]', split it
                // into its component parts.
                keys = key.split(']['),
                keys_last = keys.length - 1;

            // If the first keys part contains [ and the last ends with ], then []
            // are correctly balanced.
            if (/\[/.test(keys[0]) && /\]$/.test(keys[keys_last])) {
                // Remove the trailing ] from the last keys part.
                keys[keys_last] = keys[keys_last].replace(/\]$/, '');

                // Split first keys part into two parts on the [ and add them back onto
                // the beginning of the keys array.
                keys = keys.shift().split('[').concat(keys);

                keys_last = keys.length - 1;
            } else {
                // Basic 'foo' style key.
                keys_last = 0;
            }

            // Are we dealing with a name=value pair, or just a name?
            if (param.length === 2) {
                val = decodeURIComponent(param[1]);

                // Coerce values.
                if (coerce) {
                    val = val && !isNaN(val) ? +val // number
                        :
                        val === 'undefined' ? undefined // undefined
                        :
                        coerce_types[val] !== undefined ? coerce_types[val] // true, false, null
                        :
                        val; // string
                }

                if (keys_last) {
                    // Complex key, build deep object structure based on a few rules:
                    // * The 'cur' pointer starts at the object top-level.
                    // * [] = array push (n is set to array length), [n] = array if n is 
                    //   numeric, otherwise object.
                    // * If at the last keys part, set the value.
                    // * For each keys part, if the current level is undefined create an
                    //   object or array based on the type of the next keys part.
                    // * Move the 'cur' pointer to the next level.
                    // * Rinse & repeat.
                    for (; i <= keys_last; i++) {
                        key = keys[i] === '' ? cur.length : keys[i];
                        cur = cur[key] = i < keys_last ?
                            cur[key] || (keys[i + 1] && isNaN(keys[i + 1]) ? {} : []) :
                            val;
                    }

                } else {
                    // Simple key, even simpler rules, since only scalars and shallow
                    // arrays are allowed.

                    if ($.isArray(obj[key])) {
                        // val is already an array, so push on the next value.
                        obj[key].push(val);

                    } else if (obj[key] !== undefined) {
                        // val isn't an array, but since a second value has been specified,
                        // convert val into an array.
                        obj[key] = [obj[key], val];

                    } else {
                        // val is a scalar.
                        obj[key] = val;
                    }
                }

            } else if (key) {
                // No value was defined, so set something meaningful.
                obj[key] = coerce ?
                    undefined :
                    '';
            }
        });

        return obj;
    };
};
module.exports = App;