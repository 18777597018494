var Backbone = require('backbone');
var $ = require("jquery");
var Events = require("../events");
var helpers = require("../../config/helpers");
var Handlebars = require('hbsfy/runtime');
Backbone.$ = $;

var template = require("../templates/conversation_brief.hbs");

module.exports = Backbone.View.extend({
    tagName: "div",
    className: "conversation-brief",
    events: {
        "click .user-link": "userView",
    },
    initialize: function() {
        var self = this;
        this.listenTo(this.model, "change", this.render);
    },
    userView: function(e) {
        e.preventDefault();
        var user = this.model.notMe();
        user.renderAndFetch();
    },
    detailedView: function(e) {
        e.preventDefault();
        this.model.renderAndFetch();
        $('body').attr('show', 'chat');
    },
    pushNewMessage: function(notification) {
        this.model.attributes.latest_message = notification.conversation.pushedMessage;
        this.render();
    },
    setUnread: function()  {
        this.$el.attr('unread', this.model.get('unread_count'));
    },
    renderTimestamp: function() {
        this.$el.find('.time-ago').text(helpers.timeAgo(this.model.get('latest_message').created_at));
    },
    render: function() {
        var max = 27;
        var short_message = this.model.get('latest_message').body ||  "";
        if (short_message.length > max) short_message = short_message.substring(0, max - 3) + "...";
        var hbsConfig = require('../../config/handlebars');
        hbsConfig.generic(Handlebars);
        this.$el.html(template({
            conversation: this.model.attributes,
            user_photo: this.model.notMe().get('user_photo'),
            user_name: this.model.notMe().get('name'),
            user_url: '/säljare/' + this.model.notMe().id,
            ad_photo: this.model.getAd().get('ad_photos')[0].photo,
            ad_url: '/' + this.model.getAd().get('categoryURL') + '/' + this.model.getAd().id,
            short_message: short_message
        }));
        this.$el.find('.short-message').text(short_message);
        this.$el.find('.time-ago').text(helpers.timeAgo(this.model.get('latest_message').created_at));
        this.setUnread();
        return this;
    }
});