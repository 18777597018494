var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "<div class=\"conversation-wrapper\">\n	<div class=\"col-xs-3 no-padding\">\n		<div class=\"image-wrapper\">\n			<a href=\""
    + escapeExpression(((helper = (helper = helpers.user_url || (depth0 != null ? depth0.user_url : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"user_url","hash":{},"data":data}) : helper)))
    + "\">\n				<img class=\"circle\" src=\""
    + escapeExpression(((helper = (helper = helpers.user_photo || (depth0 != null ? depth0.user_photo : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"user_photo","hash":{},"data":data}) : helper)))
    + "\">\n			</a>\n		</div>\n	</div>\n	<a class=\"conversation-link\" href=\"\" data-id=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.conversation : depth0)) != null ? stack1.hash_id : stack1), depth0))
    + "\">\n		<div class=\"col-xs-6 conversation-details no-padding vertical-align-outer\">\n			<div class=\"vertical-align-inner\">\n				<p class=\"bold\">"
    + escapeExpression(((helper = (helper = helpers.user_name || (depth0 != null ? depth0.user_name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"user_name","hash":{},"data":data}) : helper)))
    + "</p>\n				<p class=\"short-message dark-text\" title=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.conversation : depth0)) != null ? stack1.latest_message : stack1)) != null ? stack1.body : stack1), depth0))
    + "\">"
    + escapeExpression(((helper = (helper = helpers.short_message || (depth0 != null ? depth0.short_message : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"short_message","hash":{},"data":data}) : helper)))
    + "</p>\n				<p class=\"time-ago\">"
    + escapeExpression(((helpers.timeAgo || (depth0 && depth0.timeAgo) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.conversation : depth0)) != null ? stack1.latest_message : stack1)) != null ? stack1.created_at : stack1), {"name":"timeAgo","hash":{},"data":data})))
    + "</p>\n			</div>\n		</div>\n	</a>\n	<div class=\"col-xs-3 no-padding\">\n		<div class=\"image-wrapper\">\n			<a href=\""
    + escapeExpression(((helper = (helper = helpers.ad_url || (depth0 != null ? depth0.ad_url : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ad_url","hash":{},"data":data}) : helper)))
    + "\">\n				<img src=\""
    + escapeExpression(((helper = (helper = helpers.ad_photo || (depth0 != null ? depth0.ad_photo : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ad_photo","hash":{},"data":data}) : helper)))
    + "\">\n			</a>\n		</div>\n	</div>\n</div>";
},"useData":true});