var Backbone = require('backbone');
var Events = require("../events");
var Conversation = require('../models/conversation');
var $ = require("jquery");
var User = require("./user");

module.exports =  Backbone.Model.extend({
    idAttribute: "hash_id",
    url: function() {
        var url = "/api/items/" + this.get('hash_id');
        if (this.attributes.cache === false) url += "?cache=false";
        return url;
    },
    parse: function(resp, xhr) {
        return resp.ad || resp;
    },
    initialize: function() {
        var self = this;
        this.parseConversations();
        this.listenTo(this.conversations, "reset", this.parseConversations);
        this.listenTo(this.conversations, "change", function() {
            console.log("Selling Conversations Change: triggereing change on Parent Ad");
            self.trigger('change');
        });
        this.initUser();
    },
    initUser: function() {
        if (this.attributes.user) {
            window._cache.users[this.attributes.user.hash_id] = window._cache.users[this.attributes.user.hash_id] || new User(this.attributes.user);
            this.user = window._cache.users[this.attributes.user.hash_id];
        }
    },
    ensureSquare: function(refresh = true) {
        if (this.attributes.ensureSquare) return false;
        var self = this;
        this.attributes.ensureSquare = true;
        $.get('/ensure_square_photos?hash_id=' + this.id, function() {
            console.log("Ensured square photos");
            if (refresh) Events.trigger('router:refresh');
        });
    },
    getUnread: function() {
        var unread = 0;
        if (this.conversations) {
            this.conversations.each(function(el) {
                unread += el.get('unread_count');
            })
        }
        return unread;
    },
    dataMerge: function(attributes) {
        Object.assign(this.attributes, attributes);
        this.trigger('change');
        return this;
    },
    updateConversation: function(conversation) {
        if (this.attributes.conversations) {

        }
    },
    fetchConversations: function(next) {
        var self = this;
        if (!this.requestingConversations) {
            this.requestingConversations = true;
            $.ajax({
                url: '/api/items/' + self.id + '/conversations',
                type: 'GET',
                contentType: 'application/json',
                success: function(resp) {
                    self.requestingConversations = false;
                    if (resp.conversations) {
                        for (var i in resp.conversations) {
                            if (window._cache.conversations[resp.conversations[i].hash_id]) {
                                window._cache.conversations[resp.conversations[i].hash_id].update_attributes(resp.conversations[i]);
                            } else {
                                window._cache.conversations[resp.conversations[i].hash_id] = new Conversation(resp.conversations[i])
                                self.conversations.add(window._cache.conversations[resp.conversations[i].hash_id]);
                                window._cache.conversations[resp.conversations[i].hash_id].ad = self;
                            }
                        }
                        next();
                    }
                }
            }).error(function(error, string, errorstring) {
                this.requestingConversations = false;
                if (error.status === 401) {
                    self.authFailed();
                }
                next();
            });
        }
    },
    like: function() {
        var self = this;
        var isLiked = this.hasLiked();
        var method = isLiked ? "DELETE" : "POST";
        $.ajax({
            url: '/api/items/' + this.id + '/likes',
            type: method,
            contentType: 'application/json',
            success: function(resp) {
                if (method === "DELETE" && self.hasLiked()) {
                    self.attributes.likes_count--;
                    self.attributes.is_liked = false;
                } else if (method === "POST") {
                    window._tracking.interaction('like', self.id, self.attributes);
                    if (!self.attributes.is_liked) {
                        self.attributes.likes_count++;
                        self.attributes.is_liked = true;
                    }
                }
                self.trigger('change');
            },
            error: function(resp) {
                if (method == "DELETE" && self.attributes.is_liked) self.attributes.is_liked = false;
                if (method == "POST" && !self.attributes.is_liked) self.attributes.is_liked = true;
                self.trigger('change');
            },
        });
    },
    hasLiked: function() {
        if (!window._SESSION.current_user) return false;
        return this.attributes.is_liked;
    },
    parseConversations: function() {
        var self = this;
        var ConversationsCollection = require("../collections/conversations");
        this.conversations = this.conversations ||  new ConversationsCollection();
        this.conversations.add(this.attributes.conversations);
        this.conversations.each(function(conversation) {
            if (window._cache.conversations[conversation.id]) {
                window._cache.conversations[conversation.id].update_attributes(conversation.attributes);
            } else {
                window._cache.conversations[conversation.id] = conversation;
                window._cache.conversations[conversation.id].ad = self;
            }
        });
    },
    getUser: function() {
        var User = require('./user');
        var user = new User(this.get("user"));
        return user;
    }
});