var Backbone = require('backbone');
var $ = require("jquery");
var Events = require("../events");
var helpers = require("../../config/helpers");
Backbone.$ = $;

var template = require("../templates/ad.hbs");

module.exports = Backbone.View.extend({
    tagName: "div",
    className: "ad ad-brief",
    events: {
        "click .contact-button": "contactClick",
        "click .like-button": "toggleLike",
    },
    toggleLike: function(e) {
        e.preventDefault();
        this.model.like();
    },
    contactClick: function() {
        var self = this;
        if (self.model.conversations.first()) {
            self.model.conversations.first().renderAndFetch();
        } else {
            self.model.fetchConversations(function() {
                if (self.model.conversations.first()) self.model.conversations.first().renderAndFetch();
                else Events.trigger('conversation:new', self.model);
            });
        }
    },
    attributes: function() {
        var id = "ad-brief-" + this.model.attributes.hash_id;
        return { id: id, "data-id": this.model.attributes.hash_id, class: self.className };
    },
    initialize: function(options) {
        this.parentView = options.parentView;
        window._cache.items[this.model.id] = window._cache.items[this.model.id] || this.model;
        // this.render();
        this.listenTo(this.model, "like", this.newLike);
    },
    newLike: function() {
        var self = this;
        this.$el.css("animation", "shake 0.82s cubic-bezier(.36,.07,.19,.97) both");
        setTimeout(function() {
            self.$el.css("animation", "");
        }, 2000);
    },
    render: function() {
        var self = this;
        if (!self.$el.children().length) self.$el.html(template(self.model.attributes));
        if (self.model.attributes.sold) self.$el.addClass('sold');
        else self.$el.removeClass('sold');
        self.$el.find('.details').unbind('click').click(function(e) {
            e.preventDefault();
            var pos = self.$el.parents('.ads').children('.ad').index(self.$el) + 1;
            var list = "Index";
            if (self.$el.parents('#user-ads').length) list = "Profile";
            if (window.dataLayer) dataLayer.push({
                event: 'pclick',
                category: self.model.attributes.category.name,
                label: self.model.id,
                'ecommerce': {
                    'currencyCode': self.model.attributes.currency, 
                    'click': {
                        'actionField': { 'list': list }, // Optional list property.
                        'products': [{
                            'name': self.model.attributes.title, // Name or ID is required.
                            'id': self.model.attributes.hash_id,
                            'price': (self.model.attributes.price / 100),
                            'brand': self.model.attributes.brand?.name,
                            'category': self.model.attributes.category.name,
                            'position': pos
                        }]
                    }
                }
            });
            self.parentView.scrollTop = $('.content-wrapper').scrollTop();
            // Events.trigger("router:navigate", self.model.get('categoryURL') + '/' + self.model.id, {trigger: true});
        });
        // console.log("Rendering", self.model.id)
        this.$el.find('.image-wrapper img').on('load', function(e) {
            if (this.height != this.width) {
                console.log("Image not Square!");
                self.model.ensureSquare(false);
            }
        });
        return self;
    },
    convert: function() {
        var $el = $('#' + this.attributes().id);
        if ($el.length) {
            this.setElement($el);
            this.render();
            this.converted = true;
            // console.log("Rendered " + this.attributes().id);
        }
        return this;
    }
});