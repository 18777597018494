var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, functionType="function", buffer = "        <div class=\"col-xs-"
    + escapeExpression(lambda((depths[1] != null ? depths[1].children_width_mobile : depths[1]), depth0))
    + " col-sm-"
    + escapeExpression(lambda((depths[1] != null ? depths[1].children_width_desktop : depths[1]), depth0))
    + "\">\n        <a href=\"/"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "users", "slugs", {"name":"translate","hash":{},"data":data})))
    + "/"
    + escapeExpression(((helper = (helper = helpers.hash_id || (depth0 != null ? depth0.hash_id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"hash_id","hash":{},"data":data}) : helper)))
    + "\">\n            <div class=\"featured-user\">\n            <div class=\"featured-user-items\">\n                <div class=\"row\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.ads : depth0), {"name":"each","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                </div>\n            </div>\n            <div class=\"featured-user-info\">\n                <div class=\"row\">\n                <div class=\"col-xs-2\">\n                    <img src=\""
    + escapeExpression(((helper = (helper = helpers.user_photo || (depth0 != null ? depth0.user_photo : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"user_photo","hash":{},"data":data}) : helper)))
    + "\" loading=\"lazy\"/>\n                </div>\n                <div class=\"col-xs-10 col-xs-offset-2\">\n                    <span class=\"featured-user-name\">"
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, (depth0 != null ? depth0.name : depth0), {"name":"sanitizeHTML","hash":{},"data":data})))
    + "</span>\n                </div>\n                </div>\n            </div>\n            </div>\n        </a>\n        </div>\n";
},"2":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "                    <div class=\"col-xs-3\">\n                    <img src=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ad_photos : depth0)) != null ? stack1['0'] : stack1)) != null ? stack1.mini : stack1), depth0))
    + "\" srcset=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ad_photos : depth0)) != null ? stack1['0'] : stack1)) != null ? stack1.thumbnail : stack1), depth0))
    + " 2x\" loading=\"lazy\"/>\n                    </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"landing-block-header\">\n    <h2>"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Popular", "words", {"name":"translate","hash":{},"data":data})))
    + " "
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "users", "words", {"name":"translate","hash":{},"data":data})))
    + "</h2>\n</div>\n<div class=\"landing-block-content\">\n    <div class=\"row\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.users : depth0), {"name":"each","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </div>\n</div>";
},"useData":true,"useDepths":true});