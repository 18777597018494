var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "  <div class=\"col-xs-12 col-sm-6 flex-center\">\n";
  stack1 = ((helpers.ifCond || (depth0 && depth0.ifCond) || helperMissing).call(depth0, (depth0 != null ? depth0.lottie_or_image : depth0), "===", "lottie", {"name":"ifCond","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.ifCond || (depth0 && depth0.ifCond) || helperMissing).call(depth0, (depth0 != null ? depth0.lottie_or_image : depth0), "===", "image", {"name":"ifCond","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </div>\n";
},"2":function(depth0,helpers,partials,data) {
  return "          <script src=\"https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs\" type=\"module\"></script>\n          <dotlottie-player class=\"image-part\" src=\"/videos/intro-animation.json\" background=\"transparent\" speed=\"1\" direction=\"1\" mode=\"normal\" no-cors loop autoplay></dotlottie-player>\n";
  },"4":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "          <img class=\"image-part\" src=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.image : depth0)) != null ? stack1.url : stack1), depth0))
    + "\">\n";
},"6":function(depth0,helpers,partials,data) {
  return " col-xs-12 col-sm-6 ";
  },"8":function(depth0,helpers,partials,data) {
  var stack1, helper, lambda=this.lambda, escapeExpression=this.escapeExpression, functionType="function", helperMissing=helpers.helperMissing;
  return "      <div class=\"statement-row\">\n        <img src=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.icon : depth0)) != null ? stack1.url : stack1), depth0))
    + "\" class=\"icon\">\n        <div class=\"statement\">\n          <p><strong>"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</strong></p>\n          <p class=\"substatement\">"
    + escapeExpression(((helper = (helper = helpers.subtitle || (depth0 != null ? depth0.subtitle : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"subtitle","hash":{},"data":data}) : helper)))
    + "</p>\n        </div>\n      </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.image : depth0)) != null ? stack1.url : stack1), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "<div class=\"";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.image : depth0)) != null ? stack1.url : stack1), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "text-container flex-center\">\n\n    <h2 class=\"title\">"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</h2><br>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.text : depth0), {"name":"each","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "     <a href=\""
    + escapeExpression(((helper = (helper = helpers.call_to_action_url || (depth0 != null ? depth0.call_to_action_url : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"call_to_action_url","hash":{},"data":data}) : helper)))
    + "\"\n        onclick=\"dataLayer.push({ event: 'CTA', link: 'iTunes', item: 'image_and_text_cta'});\"\n        target=\"_blank\" class=\"app-store-link btn-plick btn-black\">\n        "
    + escapeExpression(((helper = (helper = helpers.call_to_action_label || (depth0 != null ? depth0.call_to_action_label : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"call_to_action_label","hash":{},"data":data}) : helper)))
    + "\n    </a>\n</div>";
},"useData":true});