var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "                        <img class=\"profile-image\" src='"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.user_photo : stack1), depth0))
    + "'>\n";
},"3":function(depth0,helpers,partials,data) {
  return "                        <img class=\"profile-image\" src=\"/images/profilbild.png\">\n";
  },"5":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.location : stack1), depth0))
    + ",\n                            ";
},"7":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                            <div class=\"profile-meta\">\n                                <p class=\"text-left user-description \">"
    + escapeExpression(((helpers.breaklines || (depth0 && depth0.breaklines) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.description : stack1), {"name":"breaklines","hash":{},"data":data})))
    + "</p>\n                            </div>\n";
},"9":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                            <button class=\"info-link follow-button is-following\"><span\n                                    class=\"info-link-title\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Following", "words", {"name":"translate","hash":{},"data":data})))
    + "</span></button>\n";
},"11":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                            <button class=\"info-link follow-button triggers-login\"><span\n                                    class=\"info-link-title\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Follow", "words", {"name":"translate","hash":{},"data":data})))
    + "</span></button>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = "<div class=\"container-fluid single-user-wrapper\">\n    <div class=\"row\">\n        <div class=\"col-sm-12 col-xs-12 single-user\">\n            <div class=\"profile-wrapper\">\n                <div class=\"profile-content\">\n                    <div class=\"profile-image-container\">\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.user_photo : stack1), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "                    </div>\n                    <div class=\"profile-name-location\">\n                        <h1 class=\"profile-name\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1), depth0))
    + "</h1>\n                        <h2 class=\"profile-location\">";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.location : stack1), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.country : stack1), "words", {"name":"translate","hash":{},"data":data})))
    + "</h2>\n                        <div class=\"rating-info\">\n                            "
    + escapeExpression(((helpers.ratingStars || (depth0 && depth0.ratingStars) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.rating : stack1), {"name":"ratingStars","hash":{},"data":data})))
    + "\n                        </div>\n                        <div class=\"profile-info\">\n                            <span class=\"followers\"><strong>"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.followers_count : stack1), depth0))
    + " </strong>"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Followers", "words", {"name":"translate","hash":{},"data":data})))
    + "</span>\n                            <span class=\"followers\"><strong>"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.following_count : stack1), depth0))
    + " </strong>"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Following", "words", {"name":"translate","hash":{},"data":data})))
    + "</span>\n                        </div>\n";
  stack1 = ((helpers.filterEmptyDescription || (depth0 && depth0.filterEmptyDescription) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.description : stack1), {"name":"filterEmptyDescription","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "                        <div class=\"profile-actions\">\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.is_following : stack1), {"name":"if","hash":{},"fn":this.program(9, data),"inverse":this.program(11, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                        </div>\n\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});