var Backbone = require('backbone');
var $ = require("jquery");
var Events = require("../events");
var helpers = require("../../config/helpers");
Backbone.$ = $;

var template = require("../templates/list_user.hbs");

module.exports = Backbone.View.extend({
  tagName: "div",
  className: "single-list-user",
  events: {
    "click .conversation-link": "detailedView",
    "click .user-link"   : "userView",
    "click .followee-link"   : "followeeView",
    "click .ad-link"   : "adView",
    "click .btn-follow" : "followButtonClick",
  },
  userView: function() {
    $('body').attr('show','');
  },
  followButtonClick: function(e) {
    e.preventDefault();
    var button = $(e.currentTarget);
    if ( button.is('.is-following') ) {
      this.model.unfollow();
    } else {
      this.model.follow();
    }
  },
  initialize: function () {
    var self = this;
    // console.log("Listening to event:", "conversation:update:" + this.model.id);
    this.listenTo(this.model, "change", this.render);
  },
  detailedView: function (e) {
    e.preventDefault();
    this.model.renderAndFetch();
  },
  render: function() {
    this.$el.html(template({user: this.model.attributes}));
    this.$el.attr('unread', 0);
    return this;
  }
});