var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <div class=\"landing-block-header\">\n        <h2>"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</h2>\n    </div>\n";
},"3":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, lambda=this.lambda, escapeExpression=this.escapeExpression, functionType="function", helperMissing=helpers.helperMissing, buffer = "        <div class=\"ad col-xs-"
    + escapeExpression(lambda((depths[1] != null ? depths[1].children_width_mobile : depths[1]), depth0))
    + " col-sm-4\">\n            <a href='";
  stack1 = helpers['if'].call(depth0, (depths[1] != null ? depths[1].released : depths[1]), {"name":"if","hash":{},"fn":this.program(4, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "' id=\"ad-brief-"
    + escapeExpression(lambda((depth0 != null ? depth0.hash_id : depth0), depth0))
    + "\" data-id=\""
    + escapeExpression(lambda((depth0 != null ? depth0.hash_id : depth0), depth0))
    + "\"\n                class=\"landing-ad black\">\n                <div class=\"square-image-wrapper\">\n                    <img class=\"square-image\" src=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.image : depth0)) != null ? stack1.url : stack1), depth0))
    + "\" srcset=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.image : depth0)) != null ? stack1.url : stack1), depth0))
    + " 2x\"\n                        alt=\"ad image\" loading=\"lazy\" />\n                    <div class=\"info-box\">\n                        <div class=\"pull-left\">\n                            <p class=\"price\">"
    + escapeExpression(((helper = (helper = helpers.price || (depth0 != null ? depth0.price : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"price","hash":{},"data":data}) : helper)))
    + "</p>\n                        </div>\n                        <div class=\"pull-right\">\n                            <p class=\"size\"><b>"
    + escapeExpression(((helper = (helper = helpers.size || (depth0 != null ? depth0.size : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"size","hash":{},"data":data}) : helper)))
    + "</b></p>\n                        </div>\n                    </div>\n                </div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.sold : depth0), {"name":"if","hash":{},"fn":this.program(7, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </a>\n        </div>\n";
},"4":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing;
  stack1 = ((helpers.filterEmptyDescription || (depth0 && depth0.filterEmptyDescription) || helperMissing).call(depth0, (depth0 != null ? depth0.ad_link : depth0), {"name":"filterEmptyDescription","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { return stack1; }
  else { return ''; }
  },"5":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad_link : depth0)) != null ? stack1.url : stack1), depth0));
  },"7":function(depth0,helpers,partials,data,depths) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "                    <div class=\"overlay-sold col-xs-"
    + escapeExpression(lambda((depths[1] != null ? depths[1].children_width_mobile : depths[1]), depth0))
    + " col-sm-4\">\n                        <img class=\"overlay-image\" src=\"/images/sold.png\" loading=\"lazy\">\n                    </div>\n";
},"9":function(depth0,helpers,partials,data) {
  var stack1, helper, lambda=this.lambda, escapeExpression=this.escapeExpression, functionType="function", helperMissing=helpers.helperMissing;
  return "    <div class=\"overlay\">\n        <img class=\"overlay-image\" src=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.overlay_image : depth0)) != null ? stack1.url : stack1), depth0))
    + "\" loading=\"lazy\"> \n        <div class=\"count-down\">\n            <p class=\"collection-count-down\" data-timestamp=\""
    + escapeExpression(((helper = (helper = helpers.release_date || (depth0 != null ? depth0.release_date : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"release_date","hash":{},"data":data}) : helper)))
    + "\"></p>\n        </div>\n    </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, helperMissing=helpers.helperMissing, functionType="function", escapeExpression=this.escapeExpression, buffer = "";
  stack1 = ((helpers.filterEmptyDescription || (depth0 && depth0.filterEmptyDescription) || helperMissing).call(depth0, (depth0 != null ? depth0.title : depth0), {"name":"filterEmptyDescription","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "<div class=\"landing-block-content collection-feed\">\n    <div class=\"row tight\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.ads : depth0), {"name":"each","hash":{},"fn":this.program(3, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        <div class=\"ad col-xs-"
    + escapeExpression(((helper = (helper = helpers.children_width_mobile || (depth0 != null ? depth0.children_width_mobile : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"children_width_mobile","hash":{},"data":data}) : helper)))
    + " hidden-sm hidden-md hidden-lg\">\n            <a href='#shop-collection' class=\"landing-ad\">\n                <div class=\"square-image-wrapper\">\n                    <img class=\"square-image\" src=\"/images/collection-placeholder.png\" alt=\"ad image\" loading=\"lazy\"/>\n                </div>\n            </a>\n        </div>\n    </div>\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.released : depth0), {"name":"unless","hash":{},"fn":this.program(9, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>";
},"useData":true,"useDepths":true});