var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "				<a class=\"item-link\" href=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.news : depth0)) != null ? stack1.ad : stack1)) != null ? stack1.share_url : stack1), depth0))
    + "\">\n					<img class=\"ad-photo\" src=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.news : depth0)) != null ? stack1.ad : stack1)) != null ? stack1.ad_photos : stack1)) != null ? stack1['0'] : stack1)) != null ? stack1.thumbnail : stack1), depth0))
    + "\">\n				</a>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "				<a class=\"followee-link\" href=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.news : depth0)) != null ? stack1.following : stack1)) != null ? stack1.preferredURL : stack1), depth0))
    + "\">\n					<img class=\"circle\" src=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.news : depth0)) != null ? stack1.following : stack1)) != null ? stack1.user_photo : stack1), depth0))
    + "\">\n				</a>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = "<div class=\"single-news-wrapper\">\n	<div class=\"col-xs-3 no-padding\">\n		<div class=\"image-wrapper\">\n			<a class=\"user-link\" href=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.news : depth0)) != null ? stack1.user : stack1)) != null ? stack1.preferredURL : stack1), depth0))
    + "\">\n				<img class=\"circle\" src=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.news : depth0)) != null ? stack1.user : stack1)) != null ? stack1.user_photo : stack1), depth0))
    + "\">\n			</a>\n		</div>\n	</div>\n	<div class=\"col-xs-6 conversation-details no-padding vertical-align-outer\">\n		<div class=\"vertical-align-inner\">\n			<p class=\"short-message dark-text\">"
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, (depth0 != null ? depth0.news_message : depth0), {"name":"sanitizeHTML","hash":{},"data":data})))
    + "</p>\n			<p class=\"time-ago\">"
    + escapeExpression(((helpers.timeAgo || (depth0 && depth0.timeAgo) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.news : depth0)) != null ? stack1.created_at : stack1), {"name":"timeAgo","hash":{},"data":data})))
    + "</p>\n		</div>\n	</div>\n	<div class=\"col-xs-3 no-padding\">\n		<div class=\"image-wrapper\">\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.news : depth0)) != null ? stack1.ad : stack1), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "		</div>\n	</div>\n</div>";
},"useData":true});