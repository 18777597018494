var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "		<p class=\"message-time-ago\">"
    + escapeExpression(((helpers.timeAgo || (depth0 && depth0.timeAgo) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.created_at : stack1), {"name":"timeAgo","hash":{},"data":data})))
    + "</p>\n		<div class=\"profile-image\">\n			<img src=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.user : stack1)) != null ? stack1.user_photo : stack1), depth0))
    + "\">\n		</div>\n		<div class=\"message-body\">\n			<p>"
    + escapeExpression(((helpers.replaceEmojis || (depth0 && depth0.replaceEmojis) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.body : stack1), {"name":"replaceEmojis","hash":{},"data":data})))
    + "</p>\n		</div>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "		<p class=\"message-time-ago\">"
    + escapeExpression(((helpers.timeAgo || (depth0 && depth0.timeAgo) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.created_at : stack1), {"name":"timeAgo","hash":{},"data":data})))
    + "</p>\n		<div class=\"profile-image\">\n			<img src=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.user : stack1)) != null ? stack1.user_photo : stack1), depth0))
    + "\">\n		</div>\n		<div class=\"message-body\">\n			<img class=\"message-image\" src=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.image : stack1), depth0))
    + "\">\n			<p>"
    + escapeExpression(((helpers.replaceEmojis || (depth0 && depth0.replaceEmojis) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.body : stack1), {"name":"replaceEmojis","hash":{},"data":data})))
    + "</p>\n		</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "<div class=\"message-wrapper\">\n";
  stack1 = ((helpers.ifCond || (depth0 && depth0.ifCond) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.message_type : stack1), "===", "message", {"name":"ifCond","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.ifCond || (depth0 && depth0.ifCond) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.message_type : stack1), "===", "image", {"name":"ifCond","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>";
},"useData":true});