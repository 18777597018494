var Backbone = require('backbone');
var News = require('../models/news');
module.exports = Backbone.Collection.extend({
    model: News,
    url: function () {
      var url = "/api/news";
      return url;
    },
    page: 1,
    category: 0,
    parse: function(resp, xhr) {
        return resp.news;
    },
    comparator: function(model) {
        return -1 * new Date(model.attributes.created_at);
    },
    getUnread: function() {
        var unread = 0;
        this.each(function(el) {
            if (el.attributes.unread) unread++;
        });
        return unread;
    }
});