module.exports = {
	_tags: {
		"blue": "blau",
		"yellow" : "gelb",
		"brown" : "braun",
		"black" : "schwarz",
		"red" : "rot",
		"green" : "grün",
		"pink" : "rosa",
	},
	_categories: {
		"Shoes" : "Schuhe",
		"shoes" : "schuhe",
		"Skirts" : "Röcke",
		"skirts" : "röcke",
		"Shirts" : "Hemden",
		"shirts" : "hemden",
		"Coats & Jackets" : "Mäntel & Jacken",
		"coats-jackets" : "jacken",
		"Other" : "Verschiedenes",
		"other" : "verschiedenes",
		"Bags & Purses" : "Taschen & Geldbörsen",
		"bags-purses" : "taschen-geldborsen",
		"Dresses" : "Kleider",
		"dresses" : "kleider",
		"Jeans & Trousers" : "Jeans & Hosen",
		"jeans-trousers": "jeans-hosen",
		"Knitwear" : "Stricksachen",
		"knitwear" : "stricksachen",
		"Tops" : "Oberteile" ,
		"tops" : "oberteile" ,
		"Cardigans & Jumpers" : "Pullover & Strickjacken",
		"cardigans-jumpers": "pullover-strickjacken",
		"Blouses" : "Blusen",
		"blouses" : "blusen",
		"Suits & Blazers": "Anzüge",
		"suits-blazers" : "anzüge",
		"Hoodies & Sweatshirts" : "Kapuzenpullis & Sweatshirts",
		"hoodies-sweatshirts" : "sweatshirts",
		"Accessories" : "Accessoires",
		"accessories" : "accessoires",
	},
	_filter: {
		"Search Here": "Finden",
		"Cancel": "Abbrechen",
		"Refine": "Verfeinern Suche",
		"Fit": "Passen",
		"Womens": "Damen",
		"Mens": "Herren",
		"Unisex": "Unisex",
		"Categories": "Kategorien",
		"Sizes": "Grösse",
		"Search": "Finden",
		"Clear": "Suche löschen",
	},
	_item: {
		"Description" : "Beschreibung",
		"Download the App to buy this" : "Loggen Sie sich jetzt ein und kaufen Sie diesen Artikel.",
		"More" : "Mehr von ",
		"SIZE" : "Gr.",
		"Size" : "Größe",
		"Brand" : "Marken",
		"Category" : "Kategorien",
		" EUR"  : "EUR",
		"PRICE" : "PREIS",
		"Related Items" : "Ähnliche Artikel",
		"Other People Also Looked At" : "Käufer haben sich auch folgende Artikel angesehen",
		"More items from" : "Weitere Artikel von",
		"Check out these related items! 😍" : "Ähnliche Artikel 😍",
		"Login to buy this item" : "Loggen Sie sich jetzt ein und kaufen diesen Artikel",
	},
	_words: {
		"and" : "und",
		"or"  : "oder",
		"Go to" : "Geh zum",
		"SOLD"  : "VERKAUFT",
		"Share" : "Teilen",
		"Shop" : "Kaufen",
		"Contact" : "Kontaktiere",
		"Sell" : "Verkaufen",
		"Buy" : "Kaufen",
		"Items" : "Artikel",
		"Followers" : "Abonnenten",
		"Following" : "Abonniert",
		"like" : "Gefällt mir",
		"likes" : "Gefällt das",
		"users" : "benutzer",
		"Germany" : "Deutschland",
		"Brand" : "Marken",			
		"Tag": "Tag",
		"From" : "Aus",
		"from" : "von",
		"in" : "in",
		"and on" : "und in",
		"Categories": "Kategorien",
		"Brands" : "Marken",
		"Popular" : "Beliebte",
		"Users" : "Verkäufer"

	},
	_general: {
		"How do I buy and sell on Plick?" : "Wie kaufe und verkaufe ich auf Plick?",
		"your once-loved favorites with Plick!": "Deine einst geliebten Kleider in der App",
		"Do you have a goldmine sitting in your wardrobe? It’s always a great time to tackle a closet clean out and find a new home for your once-loved favorites. There’s someone out there looking for just what’s sitting in your closet!": "Auf der Suche nach neuen Mode-Trends und Vintage-Highlights? Bei Plick finden Sie günstige Alternativen zu teurer Kleidung und Accessoires, die wie neu aussehen.",
		"your new outfit in the app": "dein neues Outfit in der App",
		"Before buying the same pair of sneakers as all your friends, go check out Plick and find a one-of-a-kind pair. If you're looking for like-new merch for 10% of the original price, you're only one click away. Simply download Plick and create a profile.":"Bist du ein Schnäppchenjäger? Dann ist Plick die App für dich! Hier gibt es jede Menge tolle Sachen zu kaufen! Laden Sie die App herunter und erstellen Sie noch ein Konto!",
		"Give viewers a full look at the item. Include a full front shot of the garment, a full back shot, and a shot of you wearing it.": "Machen Sie einfach ein paar gute Fotos und beschreiben Sie Ihren Artikel.",
		"Describe your item and make it available to other fashionistas.": "Nutze die passenden Schlagworte in der Artikelbeschreibung, zum Beispiel Marke, Zustand, Größe usw.",
		"Open the door to hundreds of thousand second hand items.": "Du kannst auf das Suchen-Symbol drücken und den Namen des Produkts, das du kaufen möchtest, eingeben.",
		"Chat": "Chat",
		"Snap a Photo": "Nimm ein Foto",
		"Search": "Finden",
		"Tag it": "Tag",
		"Agree with the seller and the item can be yours in a couple of seconds!": "Denk dran, wenn du findest, wonach du gesucht hast, sprich mit dem Verkäufer, um mehr über das Produkt zu erfahren.",
		"Plick uses cookies to improve your experience!" : "Um dir den bestmöglichen Service zu bieten, verwendet Plick Cookies. Mit dem Weiternavigieren auf Plick akzeptierst du die Cookie-Bedingungen.",
		"I Accept!" : "Schließen",
		"Get the App!": "Laden Sie die App!",
		"Hey!" : "Hallo!",
		"Get a better Plick experience by downloading the app for free!":"Lade dir die kostenlose App für Android oder iOS herunter und erlebe Plick - egal an welchem Ort!",
		"Plick is available at" : "Herunterladen von",
		"Plick är en marknadsplats där du kan sälja och köpa second hand och vintage kläder, acessoarer och skor. Plick finns som app och på webben.": "Auf der Suche nach neuen Mode-Trends und Vintage-Highlights? Bei Plick finden Sie günstige Alternativen zu teurer Kleidung und Accessoires, die wie neu aussehen.",
		"Affordable Fashion Brands on Plick" : " "
	},
	_menu: {
		"Click Here to Sell an Item!": "Stelle einen Artikel auf Plick!",
		"Post an Item": "Verkaufe jetzt",
		"Profile": "Mein Benutzerkonto",
		"Sign In": "Einloggen",
		"About Plick": "Über Plick",
		"Contact Us" : "Schreib uns",
		"Social Media" : "Social Media",
		"Newsletter" : "Newsletter",
		"Log Out": "Ausloggen",
	},
	_slugs: {
		"sellers": "verkaufer",
		"users": "verkaufer",
		"brands": "marken",
		"city": "Stadt"
	},
	_login: {
		"Create Account": "Neuanmelden",
		"Register with Facebook": "Registrieren mit Facebook",
		"Continue with Facebook": "Weiter mit Facebook",
		"Register Without Facebook": "Registrieren ohne Facebook",
		"By registering an account, I accept": "Indem du auf 'Registrieren' klickst, stimmst du den",
		"Plick's Terms & Condition": "Nutzungsbedingungen und Datenschutzrichtlinien zu.",
		"Sign In": "Einloggen",
		"Password": "Passwort",
		"Username": "Mitgliedsname",
		"Repeat Password": "Passwort wiederholen",
		"Join Plick!": "Neu Registrieren",
        "Forgotten password": "Passwort vergessen?",
	},
	_lang: {
		"EN" : "DE",
	},
	_variables: {
		"https://plick.se": "https://plickmarkt.de"
	}
}