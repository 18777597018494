module.exports = {
    _tags: {
        "blue": "blå",
        "yellow": "gul",
        "brown": "brun",
        "black": "svart",
        "blue": "blå",
        "red": "röd",
        "green": "grön",
        "pink": "rosa",
    },
    _categories: {
        "Shoes": "Skor",
        "shoes": "skor",
        "Skirts": "Kjolar",
        "skirts": "kjolar",
        "Shirts": "Skjortor",
        "shirts": "skjortor",
        "Coats & Jackets": "Jackor",
        "coats-jackets": "jackor",
        "Other": "Övrigt",
        "other": "ovrigt",
        "Bags & Purses": "Väskor",
        "bags-purses": "vaskor",
        "Dresses": "Klänningar",
        "dresses": "klanningar",
        "Jeans & Trousers": "Jeans & Byxor",
        "jeans-trousers": "byxor",
        "Knitwear": "Stickat",
        "knitwear": "stickat",
        "Tops": "Toppar",
        "tops": "toppar",
        "Cardigans & Jumpers": "Tröjor & Koftor",
        "cardigans-jumpers": "trojor",
        "Blouses": "Blusar",
        "blouses": "blusar",
        "Suits & Blazers": "Kostymer",
        "suits-blazers": "kostymer",
        "Hoodies & Sweatshirts": "Huvtröjor & Träningströjor",
        "hoodies-sweatshirts": "huvtrojor",
        "Accessories": "Accessoarer",
        "accessories": "accessoarer",
    },
    _filter: {
        "Search Here": "Sök här",
        "Cancel": "Avbryt",
        "Refine": "Filtrera",
        "Fit": "Passform",
        "Womens": "Dam",
        "Mens": "Herr",
        "Unisex": "Unisex",
        "Categories": "Kategorier",
        "Sizes": "Storlekar",
        "Search": "Sök",
        "Clear": "Rensa",
    },
    _item: {
        "Description": "Säljarens beskrivning",
        "Download the App to buy this": "Ladda ner appen gratis för att köpa",
        "More": "Mera från kategorin ",
        "Size": "Stl",
        "Size": "Storlek",
        "Brand": "Märke",
        "Tag it": "Tagga",
        "Category": "Kategori",
        " SEK": "kr",
        "Price": "Pris",
        "Related Items": "Relaterade annonser",
        "Other People Also Looked At": "Andra kollade också på",
        "More items from": "Fler annonser av",
        "Check out these related items! 😍": "Kolla in dessa liknande annonser 😍",
        "Login to buy this item": "Logga in för att köpa",
        "Buy Now": "Köp",
        "Status" : "Status",
        "Sold" : "Såld",
        "Can meet up" : "Kan mötas upp",
        "Bidding" : "Budgivning",
        "Leading bid" : "Ledande bud",
        "Number of bids" : "Antal bud",
        "Starting price" : "Utropspris",
        "Ending" : "Slutar",
        "See bids" : "Se bud",
    },
    _words: {
        "and": "och",
        "or": "eller",
        "Go to": "Gå till",
        "SOLD": "SÅLD",
        "Share": "Dela",
        "Shop": "butik",
        "Contact": "Kontakt",
        "Sell": "Sälj",
        "Buy": "Köp",
        "Items": "Annonser",
        "Followers": "Följare",
        "Following": "Följer",
        "Follow": "Följ",
        "like": "gilla",
        "likes": "gillar",
        "comments": "kommentarer",
        "users": "säljare",
        "sweden": "Sverige",
        "Sweden": "Sverige",
        "Brand": "Märke",
        "Tag": "Tagga",
        "Users": "Användare",
        "Popular": "Populära",
        "Brands": "Märken",
        "Categories": "Kategorier",
        "from": "från",
        "and on": "och i",
        "in": "i",
        "Details": "Detaljer",
        "Shipping options": "Fraktalternativ",
    },
    _general: {
        "Download the app, create a profile and start buying and selling. Think second hand first. Plick is available on the": "Du behöver bara ladda ner appen och skapa en profil sedan kan du börja köpa och sälja. Tänk Second Hand i första hand! Appen hittar du i",
        "How do I buy and sell on Plick?": "Hur köper och säljer jag på Plick?",
        "your once-loved favorites with Plick!": "dina bortglömda favoriter i appen",
        "Do you have a goldmine sitting in your wardrobe? It’s always a great time to tackle a closet clean out and find a new home for your once-loved favorites. There’s someone out there looking for just what’s sitting in your closet!": "Har du tänkt på alla gamla favoritplagg som hamnar längre och längre in i garderoben? Med Plick får du en chans att ge dem nytt liv och dessutom tjänar du en slant på det. Din garderob kan vara en guldgruva för någon annan!",
        "your new outfit in the app": "din nya outfit i appen",
        "Before buying the same pair of sneakers as all your friends, go check out Plick and find a one-of-a-kind pair. If you're looking for like-new merch for 10% of the original price, you're only one click away. Simply download Plick and create a profile.": "Tänk på de där skorna eller den där klänningen du alltid drömt om men inte riktigt haft råd med. Ladda ner Plick och skapa en profil. En sökning bort kanske du hittar drömplagget till en tiondel av priset. Vågar du chansa på att inte skapa ett konto?",
        "Snap a Photo": "Fota",
        "Give viewers a full look at the item. Include a full front shot of the garment, a full back shot, and a shot of you wearing it.": "Häng upp plagget på en galge eller fota det på dig.",
        "Describe your item and make it available to other fashionistas.": "Beskriv ditt plagg och gör det tillgängligt för andra fashionistas.",
        "Open the door to hundreds of thousand second hand items.": "Öppna dörren till 100-tusentals second handplagg.",
        "Chat": "Snacka",
        "Agree with the seller and the item can be yours in a couple of seconds!": "Kom överens med säljaren och plagget kan bli ditt på några sekunder!",
        "Plick uses cookies to improve your experience!": "Plick använder kakor för att förbättra din användarupplevelse!",
        "I Accept!": "Jag fattar!",
        "Get the App!": "Hämta appen!",
        "Hey!": "Hej!",
        "Get a better Plick experience by downloading the app for free!": "I plick-appen har du fri tillgång till alla funktioner och kan gratis lägga upp saker till försäljning. ladda hem appen gratis för att köpa, sälja, följa och likea.",
        "This link would have taken you to the app if you had it. But fear not - plick is completely free to download and after you've done that you can try the link again to find what you were looking for.":  "Denna länk hade tagit dig till appen om du haft den. Men frukta ej - plick är helt gratis att ladda ner och efter att du gjort det kan du prova länken igen för att hitta det du sökte efter.",
        "In the app, you get full access to Plik's entire range and can shop - with buyer protection. In addition, you can sell everything you no longer use - without seller fees!": "I appen får du full tillgång till hela plicks utbud och kan shoppa - med köparskydd. Dessutom kan du sälja allt det du inte längre använder - utan säljaravgifter!",
        "Found where apps are found": "Finns där appar finns",
        "Plick is available at": "plick finns på",
        "Affordable Fashion Brands on Plick": "Billiga Märkeskläder på Plick",
        "Check out this Plick user!": "Kolla in den här användaren på Plick!",
        "Notify me when the collection has been released!" : "Avisera mig när kollektionen har släppts!",
        "Ooops... the page can not be found": "Ooops... sidan går inte att hitta",
        "We have searched everywhere but the page you are looking for does not seem to be anywhere... sorry!": "Vi har letat under varje annons men sidan du letar efter verkar inte finnas någonstans... sorry!",
        "While we look further, you can search in the menu.": "Medan vi letar vidare kan du söka vidare i menyn.",
        "Did you get here by using a link?": "Kom du hit via en länk?",
        "Contact us at ": "kontakta oss på ",
        " so that we can investigate it further.": " så undersöker vi mer!",
    },
    _menu: {
        "Click Here to Sell an Item!": "Klicka här för att skapa en annons!",
        "Post an ad": "Skapa annons",
        "Profile": "Min profil",
        "Register/Log in": "Registrera/logga in",
        "Log in" : "Logga in",
        "About Plick": "Om Plick",
        "Career": "Karriär",
        
        "Contact Us": "kontakta oss",
        "Social Media": "Sociala Media",
        "Newsletter": "Nyhetsbrev",
        "FAQ": "Vanliga Frågor",
        "Log Out": "Logga ut",
        "Download the iOS app" : "Ladda ner iOS appen",
        "Download the Android app" : "Ladda ner Android appen",
        "Create ad" : "Skapa annons",
    },
    _slugs: {
        "sellers": "säljare",
        "users": "säljare",
        "brands": "märkeskläder",
        "city": "stad",
        "faq": "vanliga-fragor"
    },
    _login: {
        "Create Account": "Skapa konto",
        "Register with Facebook": "Skapa konto med facebook",
        "Continue with Facebook": "Logga in med facebook",
        "Register Without Facebook": "Skapa konto utan facebook",
        "By registering an account, I accept": "Genom att registrera mig godkänner jag",
        "Plick's Terms & Condition": "Plicks användarvillkor",
        "Sign In": "Logga in",
        "Password": "Lösenord",
        "Username": "Användarnamn",
        "Repeat Password": "Upprepa lösenord",
        "Join Plick!": "Gå med i Plick!",
        "Forgotten password": "Glömt lösenordet?",
    },
    _integration: {
        "Open app to finish": "Öppna appen för att slutföra",
    },
    _LANGUAGE: {
        "EN": "SV",
    },
    _weekdays: {
        "Monday": "Måndag",
        "Tuesday": "Tisdag",
        "Wednesday": "Onsdag",
        "Thursday": "Torsdag",
        "Friday": "Fredag",
        "Saturday": "Lördag",
        "Sunday": "Söndag",
    },
    _months: {
        "Jan": "Jan",
        "Feb": "Feb",
        "Mars": "Mars",
        "Apr": "Apr",
        "May": "Maj",
        "June": "Jun",
        "July": "Juli",
        "Aug": "Aug",
        "Sep": "Sep",
        "Oct": "Okt",
        "Nov": "Nov",
        "Dec": "Dec",
    },
}