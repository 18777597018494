var Backbone = require("backbone");
var _ = require("underscore");
var $ = require("jquery");

Backbone.$ = $;
var $el = $(window);

var object = {};
_.extend(object, Backbone.Events);

object.trackScrolling = function()  {
    $el.on("scroll", _.throttle(function(event) {
        body = document.body;
        threshold = body.scrollHeight - window.innerHeight - 100;
        if (body.scrollTop > threshold) {
            // object.trigger("page:scrollbottom");
        }
    }, 500));
}

object.trackScrollTo = function() {
    this.listenTo(Backbone, "page:scrollto", function(offset) {
        document.body.scrollTop = offset;
    });
}

module.exports = object;