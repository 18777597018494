var Backbone = require('backbone');
var User = require('../models/user');
module.exports = Backbone.Collection.extend({
    model: User,
    url: function () {
        var url = "/api/users/";
        if (this.user) url += this.user.id;
        if (this.path) url += this.path;
        return url;
    },
    page: 1,
    category: 0,
    initialize: function(options) {
        if (options) {
            this.path = options.path;
            this.user = options.user;
        }
    },
    parse: function(resp, xhr) {
        return resp.users;
    }
});