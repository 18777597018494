var Backbone = require('backbone');
var $ = require("jquery");
var Events = require("../events");
Backbone.$ = $;
var template = require("../templates/user.hbs");
var detailsTemplate = require("../templates/user_details.hbs");
var Handlebars = require('hbsfy/runtime');


var hbsConfig = require('../../config/handlebars');
hbsConfig.generic(Handlebars);
Handlebars.registerPartial('share_container', require('../templates/share_container.hbs'));
//Handlebars.registerPartial('how_to_buy', require('../templates/how_to_buy.hbs'));
Handlebars.registerPartial('user_details', require('../templates/user_details.hbs'));

module.exports = Backbone.View.extend({
    tagName: "div",
    className: "user",
    events: {
        "click .follow-button": "followClick"
    },
    initialize: function() {
        this.header = $('header');
        this.listenTo(this.model, "change", this.render);
        Events.trigger("header:single-user", this);
        if (window._chat_sockets) window._chat_sockets.viewProfile(this.model.id);
    },
    shareProfile: function(e) {
        e.preventDefault();
        FB.ui({
            method: 'share',
            href: window.location.href,
        }, function(response) {
            console.log("Shared to facebook!");
            if (response && window.dataLayer) {
                window.dataLayer.push({
                    "event": "share",
                    "action": "facebook",
                    "category": "profile"
                })
            }
        });
    },
    followClick: function() {
        if (!window._SESSION.current_user) return;
        if (this.model.attributes.is_following) return this.model.unfollow();
        this.model.follow();
    },
    convert: function() {
        var $el = $("#user-" + this.model.id);
        if ($el.length) {
            this.setElement($el);
            this.$el = $el;
            console.log("Userview Converted!");
            return true;
        } else {
            return false;
        }
    },
    render: function() {
        if (!this.convert()) {
            this.template = template({ user: this.model.attributes });
            this.$el.html(this.template);
        }
        var details = detailsTemplate({ user: this.model.attributes })
        this.$el.find(".single-user-wrapper").html($(details).children());
        this.delegateEvents();
        return this;
    }
});