var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  return "rel=\"nofollow\"\n";
  },"3":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <div class=\"sold-overlay vertical-align-outer text-center\">\n                <div class=\"vertical-align-inner\">\n                    <p class=\"sold-text brown white-text\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "SOLD", ((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.country : stack1), {"name":"translate","hash":{},"data":data})))
    + "</p>\n                </div>\n            </div>\n";
},"5":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.brand : depth0)) != null ? stack1.logo : stack1), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            <div class=\"size-overlay\">\n                <span class=\"size-span\">"
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, (depth0 != null ? depth0.size : depth0), {"name":"sanitizeHTML","hash":{},"data":data})))
    + "</span>\n            </div>\n";
},"6":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "                <div class=\"logo-overlay\">\n                    <img class=\"logo\" src=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.brand : depth0)) != null ? stack1.logo : stack1), depth0))
    + "\">\n                </div>\n";
},"8":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "        <img class=\"box-button\" src=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.user_photo : stack1), depth0))
    + "\">\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = "<a href='/"
    + escapeExpression(((helper = (helper = helpers.categoryURL || (depth0 != null ? depth0.categoryURL : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"categoryURL","hash":{},"data":data}) : helper)))
    + "/"
    + escapeExpression(((helper = (helper = helpers.slug || (depth0 != null ? depth0.slug : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"slug","hash":{},"data":data}) : helper)))
    + "' id=\"ad-brief-"
    + escapeExpression(((helper = (helper = helpers.hash_id || (depth0 != null ? depth0.hash_id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"hash_id","hash":{},"data":data}) : helper)))
    + "\" data-id=\""
    + escapeExpression(((helper = (helper = helpers.hash_id || (depth0 != null ? depth0.hash_id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"hash_id","hash":{},"data":data}) : helper)))
    + "\" class=\"details\" \n";
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.private_meta : stack1)) != null ? stack1.noindex : stack1), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n	<div class=\"image-wrapper bg-white\">\n    	<img src=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ad_photos : depth0)) != null ? stack1['0'] : stack1)) != null ? stack1.thumbnail : stack1), depth0))
    + "\" alt=\""
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, (depth0 != null ? depth0.description : depth0), {"name":"sanitizeHTML","hash":{},"data":data})))
    + "\" loading=\"lazy\"/>\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.sold : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.brand : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </div>\n    <div class=\"info-wrapper bg-white\">\n        <div class=\"info-box text-center\">\n            <h2 class=\"black-text brief-title\">"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</h2>\n            <span class=\"price-box black-text\">"
    + escapeExpression(((helpers.parsePrice || (depth0 && depth0.parsePrice) || helperMissing).call(depth0, (depth0 != null ? depth0.price : depth0), (depth0 != null ? depth0.currency : depth0), {"name":"parsePrice","hash":{},"data":data})))
    + "</span>\n        </div>\n    </div>\n</a>\n<div class=\"hover-box hidden\">\n    <a href=\"\" class=\"info-link triggers-login contact-button\" title=\""
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "contact", {"name":"translate","hash":{},"data":data})))
    + "\"><span class=\"icon ion-ios-chatbubble\"></span></a>\n    <a href=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.share_url : stack1), depth0))
    + "\" title=\""
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1), {"name":"sanitizeHTML","hash":{},"data":data})))
    + "\">\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.user_photo : stack1), {"name":"if","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    \n    </a>\n</div>";
},"useData":true});