module.exports = {
	_tags: {
		"blue": "blå",
		"yellow" : "gul",
		"brown" : "brun",
		"black" : "sort",
		"blue" : "blå",
		"red" : "rød",
		"green" : "grøn",
		"pink" : "lyserød",
	},
	_categories: {
		"Shoes" : "Sko",
		"shoes" : "sko",
		"Skirts" : "Nederdele",
		"skirts" : "nederdele",
		"Shirts" : "Skjorter",
		"shirts" : "skjorter",
		"Coats & Jackets" : "Jakker",
		"coats-jackets" : "jakker",
		"Other" : "Andre",
		"other" : "andre",
		"Bags & Purses" : "Tasker",
		"bags-purses" : "tasker",
		"Dresses" : "Kjoler",
		"dresses" : "kjoler",
		"Jeans & Trousers" : "Jeans & bukser",
		"jeans-trousers": "bukser",
		"Knitwear" : "Strik",
		"knitwear" : "strik",
		"Tops" : "Toppe" ,
		"tops" : "toppe" ,
		"Cardigans & Jumpers" : "Cardigans & strik",
		"cardigans-jumpers": "cardigans",
		"Blouses" : "Bluser",
		"blouses" : "bluser",
		"Suits & Blazers": "Blazere & jakkesæt",
		"suits-blazers" : "jakkesæt",
		"Hoodies & Sweatshirts" : "Hættetrøjer & Sweatshirts",
		"hoodies-sweatshirts" : "sweatshirts",
		"Accessories" : "Accessories",
		"accessories" : "accessories",
	},
	_filter: {
		"Search Here": "Søg her",
		"Cancel": "Tilbage",
		"Refine": "Filter",
		"Fit": "Størrelse",
		"Womens": "Kvinder",
		"Mens": "Mænd",
		"Unisex": "Unisex",
		"Categories": "Kategori",
		"Sizes": "Størrelse",
		"Search": "Søg",
		"Clear": "Rense",
	},
	_item: {
		"Description" : "Sælgere beskrivelse",
		"Download the App to buy this" : "Download appen for at købe denne vare",
		"More" : "Mere fra ",
		"SIZE" : "STL",
		"Size" : "Størrelse",
		"Brand" : "Mærke",
		"Tag it": "Tagga",
		"Category" : "Kategori",
		" DKK"  : "kr",
		"PRICE" : "PRIS",
		"Price": "Pris",
		"Related Items" : "Relaterade annoncer",
		"Other People Also Looked At" : "Andre kiggede også på",
		"More items from" : "Flere annoncer fra",
		"Check out these related items! 😍" : "Tjek disse relaterede varer! 😍",
		"Login to buy this item" : "Log ind for at købe denne vare",
		"Can meet up" : "Mødes og handle",
        "Bidding" : "Budgivning",
        "Leading bid" : "Førende bud",
        "Number of bids" : "Antal bud",
        "Starting price" : "Startpris",
        "Ending" : "Slutar",
        "See bids" : "Se bud",
		"Buy Now": "Køb nu",
	},
	_words: {
		"and" : "og",
		"or"  : "eller",
		"Go to" : "Gå til",
		"SOLD"  : "LUKKET",
		"Share" : "Dele",
		"Shop" : "butik",
		"Contact" : "Kontakt",
		"Sell" : "Sælg",
		"Buy" : "Køb",
		"Items" : "Annoncer",
		"Followers" : "Følgere",
		"Following" : "Følger",
		"Follow" : "Følg",
		"like" : "like",
		"likes" : "likes",
		"users" : "sælger",
		"sweden" : "Sverige",
		"Sweden" : "Sverige",
		"denmark" : "Danmark",
		"Denmark" : "Danmark",
		"Brand" : "Mærke",			
		"Tag": "Tag",
		"From" : "Fra",
		"from" : "fra",
		"in" : "i",
		"and on" : "og i",
		"Categories": "Kategorier",
		"Brands" : "Mærker",
		"Popular" : "Populære",
		"Users" : "brugere",
		"Brands" : "Varemærker",
		"Details": "Detaljer",
		"Shipping options": "Forsendelsesmuligheder",
	},
	_general: {
		"Download the app, create a profile and start buying and selling. Think second hand first. Plick is available on the": "Køb og sælg unikke secondhand items. Plick er stedet hvor preloved tøj bliver til guld hos en ny ejer. Plick er live i",
		"How do I buy and sell on Plick?" : "Hvordan køber og sælger jeg på Plick?",
		"your once-loved favorites with Plick!": "dit ‘preloved’ ting i appen",
		"Do you have a goldmine sitting in your wardrobe? It’s always a great time to tackle a closet clean out and find a new home for your once-loved favorites. There’s someone out there looking for just what’s sitting in your closet!": "Har du guld i dit klædeskab? Så hvis du har gode ting i gemmerne, du ikke får brugt, så sidder der med sikkerhed en Plick-bruger og venter på lige netop dit guld!",
		"your new outfit in the app": "din nye outfit i appen",
		"Before buying the same pair of sneakers as all your friends, go check out Plick and find a one-of-a-kind pair. If you're looking for like-new merch for 10% of the original price, you're only one click away. Simply download Plick and create a profile.":"Nogle gange ser man en tendens og tænker: “det må jeg bare eje”. Kender du den følelse? Det gør vi! Men det er ikke altid, at budgettet tillader, at man bruger flere tusinde kroner på en (måske) flygtig tendens. Hvad gør man så? Man køber selvfølgelig secondhand!",
		"Snap a Photo": "Tag et billede",
		"Give viewers a full look at the item. Include a full front shot of the garment, a full back shot, and a shot of you wearing it.": "Jo flere og bedre billeder, jo mere sikker føler køberen sig på varen.",
		"Describe your item and make it available to other fashionistas.": "Vedhæft tydelige billeder af varen, gerne på ensartet baggrund.",
		"Open the door to hundreds of thousand second hand items.": "Brug søgningen til at afgrænse de tusindvis af varer.",
		"Chat": "Spørg",
		"Agree with the seller and the item can be yours in a couple of seconds!": "Besvar annoncen, hvis du har spørgsmål til sælgeren.",
		"Plick uses cookies to improve your experience!" : "Kære bruger. Vi benytter cookies på Plick til at forbedre jeres brugeroplevelse. Vi samler anonym data omkring hvilke produkter og sider i benytter mest! Håber det er ok?",
		"I Accept!" : "OK!",
		"Get the App!": "Hent appen!",
		"Hey!" : "Hej!",
		"Get a better Plick experience by downloading the app for free!":"Download appen gratis, hvis du er interesseret i eller er ivrige efter at finde gode tilbud blandt tusindvis af andre beklædningsgenstande.",
		"Plick is available at" : "Plick er live i",
		"Plick är en marknadsplats där du kan sälja och köpa second hand och vintage kläder, acessoarer och skor. Plick finns som app och på webben." :"Download appen gratis, hvis du er interesseret i eller er ivrige efter at finde gode tilbud blandt tusindvis af andre beklædningsgenstande.",
		"Affordable Fashion Brands on Plick" : " "
	},
	_menu: {
		"Click Here to Sell an Item!": "Klik her for at oprette en annonce!",
		"Post an ad": "Opret annonce",
		"Register/Log in": "Opret profil/Log ind",
		"Profile": "Min profil",
		"Sign In": "Log ind",
		"About Plick": "Om Plick",
		"Career": "Karriere",
		"Contact Us" : "Kontakt os",
		"Social Media" : "Social Media",
		"Newsletter" : "Newsletter",
		"Log Out": "Log ud",
		"Download the iOS app" : "Download iOS appen",
        "Download the Android app" : "Download Android appen",
	},
	_slugs: {
		"sellers": "salgere",
		"users": "salgere",
		"brands": "marker",
		"city": "by"
	},
	_login: {
		"Create Account": "Opret profil",
		"Register with Facebook": "Opret profil med Facebook",
		"Continue with Facebook": "Log ind med Facebook",
		"Register Without Facebook": "Opret profil uden facebook",
		"By registering an account, I accept": "Når du opretter din profil accepterer du samtidig",
		"Plick's Terms & Condition": "Plicks' betingelser",
		"Sign In": "Log ind",
		"Password": "Adgangskode",
		"Username": "Brugernavn",
		"Repeat Password": "Gentag adgangskode",
		"Join Plick!": "Opret profil!",
        "Forgotten password": "Glemt dit kodeord?",
	},
	_lang: {
		"EN" : "DA",
	},
	_variables: {
		"https://plick.se": "https://plick.dk"
	}
}