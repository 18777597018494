var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data,depths) {
  var helper, lambda=this.lambda, escapeExpression=this.escapeExpression, functionType="function", helperMissing=helpers.helperMissing;
  return "        <div class=\"col-xs-"
    + escapeExpression(lambda((depths[1] != null ? depths[1].children_width_mobile : depths[1]), depth0))
    + " col-sm-"
    + escapeExpression(lambda((depths[1] != null ? depths[1].children_width_desktop : depths[1]), depth0))
    + "\">\n        <a href=\""
    + escapeExpression(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"link","hash":{},"data":data}) : helper)))
    + "\">\n            <div class=\"simple-block brand\">\n            "
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\n            </div>\n        </a>\n        </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"landing-block-header\">\n    <h2>"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Popular", "words", {"name":"translate","hash":{},"data":data})))
    + " <span class=\"lighter\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Tags", "words", {"name":"translate","hash":{},"data":data})))
    + "</span></h2>\n</div>\n<div class=\"landing-block-content\">\n    <div class=\"row\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.tags : depth0), {"name":"each","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </div>\n</div>";
},"useData":true,"useDepths":true});