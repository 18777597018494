var $ = require("jquery");
var Ad = require("./models/ad");
var User = require("./models/user");
var AdCollectionView = require("./views/ads");
window._bootstrap = window._bootstrap || {};
window._cache = {
    social: {},
    users: {},
    items: {},
    conversations: {},
    cities: {},
    page_data: {},
    views: {
        users: {},
        items: {},
        collections: {
            get: (collection) => {
                return window._cache.views.collections[window.location.href] || new AdCollectionView({
                    collection: collection,
                });
            }
        },
        item_conversations: {},
        conversation_mini: {},
        conversations: {},
        news: {},
        pages: {}
    },
    getBrands: function(next) {
        if (window._bootstrap.brands) window._cache.brands = window._bootstrap.brands;
        if (window._cache.brands) console.log("Brands are fetched from cache");
        if (window._cache.brands) return next(window._cache.brands);
        $.get('/api/brands', function(data) {
            console.log("Brands are refetched");
            window._cache.brands = data;
            if (next) next(window._cache.brands);
        });
    },
    getBrandsById: function(next) {
        this.getBrands(function(brands) {
            var bbi = {};
            for (var key in brands) {
                bbi[brands[key].id] = brands[key];
            }
            next(bbi);
        });
    },
    getCategories: function(next) {
        if (window._bootstrap.categories) window._cache.categories = window._bootstrap.categories;
        if (window._cache.categories) console.log("categories are fetched from cache");
        if (window._cache.categories) return next(window._cache.categories);
        $.get('/api/categories', function(data) {
            console.log("categories are refetched");
            window._cache.categories = data.categories;
            if (next) next(window._cache.categories);
        });
    },
    getCategoriesById: function(next) {
        this.getCategories(function(categories) {
            var result = {};
            for (var i = categories.length - 1; i >= 0; i--) {
                result[categories[i].id] = categories[i];
            }
            next(result);
        });
    },
    getPage: async function(slug, cache = true) {
        slug = slug.replace('å', 'a').replace('ä', 'a').replace('ö', 'o');
        if (cache === false) window._bootstrap.page_data[slug] = null;
        if (cache === false) window._cache.page_data[slug] = null;
        if (window._bootstrap.page_data[slug]) return window._bootstrap.page_data[slug];
        if (!window._cache.page_data[slug]) {
            let path = '/api/custom/marketplace' + slug + window.location.search
            if (cache === false) {
                if (path.indexOf('?') > -1) path += '&';
                else path += '?';
                path += 'cache=false';
            }
            const response = await fetch(path);
            const data = await response.json();
            console.log("Page '" + slug + "'is fetched and cached");
            window._cache.page_data[slug] = data;
            if (data.ad) this.items[data.ad.hash_id] = new Ad(data.ad);
        }
        return window._cache.page_data[slug];
    },
}