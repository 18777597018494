var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.ifCond || (depth0 && depth0.ifCond) || helperMissing).call(depth0, (data && data.index), "<", 4, {"name":"ifCond","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "					<div class=\"sub-conversation\" unread=\""
    + escapeExpression(lambda((depth0 != null ? depth0.unread_count : depth0), depth0))
    + "\">\n						<img src=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.buyer : depth0)) != null ? stack1.user_photo : stack1), depth0))
    + "\" class=\"buyer-profile-image\">\n					</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = "\n<div class=\"item-wrapper\">\n	<div class=\"item-part\">\n		<a  id=\"item-brief-"
    + escapeExpression(((helper = (helper = helpers.hash_id || (depth0 != null ? depth0.hash_id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"hash_id","hash":{},"data":data}) : helper)))
    + "\" data-id=\""
    + escapeExpression(((helper = (helper = helpers.hash_id || (depth0 != null ? depth0.hash_id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"hash_id","hash":{},"data":data}) : helper)))
    + "\" class=\"details\">\n			<img class=\"item-photo\" src=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ad_photos : depth0)) != null ? stack1['0'] : stack1)) != null ? stack1.thumbnail : stack1), depth0))
    + "\">\n		</a>\n	</div>\n	<div class=\"conversation-part\">\n		<div class=\"latest-message\">"
    + escapeExpression(((helpers.messagePreviewText || (depth0 && depth0.messagePreviewText) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.conversations : depth0)) != null ? stack1['0'] : stack1)) != null ? stack1.latest_message : stack1), 40, {"name":"messagePreviewText","hash":{},"data":data})))
    + "</div>\n		<div class=\"latest-conversations\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.conversations : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "		</div>\n		<div class=\"total-new-messages\"></div>\n		<div class=\"total-conversations\">\n		</div>\n	</div>\n</div>";
},"useData":true});