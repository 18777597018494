var Backbone = require('backbone');
var $ = require("jquery");
var Events = require("../events");
var Handlebars = require('hbsfy/runtime');
var LoadMoreView = require("./load_more");

Backbone.$ = $;

var AdView = require("../views/ad");

module.exports = Backbone.View.extend({
    tagName: "div",
    className: "ads",
    events: {
        "click .load": "triggerLoadMore",
    },
    attributes: function() {
        var id = "ads-view";
        if (this.collection.user) id += "-" + this.collection.user.id;
        return { id: id };
    },
    initialize: function(options) {
        var self = this;
        // this.listenTo(this.collection, "add", this.renderAd);
        Events.on("ads:loadmore", function() {
            self.loadMoreAds();
        });
        this.adViews = [];
        this.displayAds = [];
        this.isQueued = 0;
        if (!this.collection.user) {
            self.list = "Index";
            // this.searchbar = new Searchbar({collection : this.collection});
            this.loader = new LoadMoreView({});
        } else {
            self.list = "Profile";
        }
        var hbsConfig = require('../../config/handlebars');
        hbsConfig.generic(Handlebars);
        self.initAds();
    },
    initAds: function() {
        var self = this;
        Events.trigger("loader:start");
        this.collection.fetch({
            reset: true,
            success: function(ads) {
                Events.trigger("loader:stop");
                self.isLoading = false;
                self.render();
                window._tracking.impressions(ads, self.list, 0);
                self.listenTo(self.collection, "reset", self.reset);
            }
        });
    },
    reset: function() {
        this.adViews = [];
        this.displayAds = [];
        this.render();
    },
    render: function() {
        var self = this;
        this.convert();
        if (!this.collection.user) {
            this.$el.append(this.loader.render().el);
            this.loader.delegateEvents();
        }
        this.collection.each(function(ad, i) {
            self.renderAd(ad, i);
        }, this);
        return this;
    },
    renderAd: function(ad) {
        var self = this;
        var adView = new AdView({ model: ad, parentView: this });
        //    console.log(ad.id);
        adView.collection = this;
        this.adViews.push(adView);
        adView.convert();
        if (!adView.converted) {
            this.$el.append(adView.render().el);
        }
    },
    loadMoreAds: function() {
        if (!this.collection || this.collection.done) return;
        console.log("Loading ads...");
        var self = this;
        $.each(this.adViews, function() {
            this.$el.removeClass('loading');
        });
        if (this.loader) this.$el.append(this.loader.render().el);
        Events.trigger("loader:start");
        this.loading = true;
        this.collection.loadNextPage(function(ads) {
            Events.trigger("loader:stop");
            self.loading = false;
            window._tracking.impressions(ads, self.list, self.$el.children().length);
            self.render();
        })
    },
    triggerLoadMore: function(event) {
        this.loader.triggerLoadMore(event);
    },
    bindScroll: function() {
        var self = this;
        this.$el.unbind('scroll').scroll(function() {
            self.position = self.el.scrollTop;
        });
        return this;
    },
    convert: function() {
        var self = this;
        var $el = $("#" + self.attributes().id);
        if ($el.length && $el.attr('data-server-render')) {
            self.setElement($el);
            self.adViews = [];
            $el.attr('data-server-render', '');
            console.log("Collection View Converted!")
            return true;
        } else {
            return false;
        }
    }
});