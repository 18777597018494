var sizes = {
    'small': "S",
    'medium': "M",
    'large': "L"
}
var languages = {
    'sweden': {
        "yellow": "gul",
        "blue": "blå",
        "SOLD": "SÅLD",
        "Share": "Dela",
        "users": "säljare",
        "Shop": "butik",
        "Contact": "Kontakt",
        "Description": "Säljarens beskrivning",
        "Download the App to buy this": "Ladda ner appen gratis för att köpa",
        "More": "Mera från kategorin ",
        "Items": "Annonser",
        "Followers": "Följare",
        "Following": "Följer",
        "SIZE": "STL",
        "Size": "Storlek",
        "Category": "Kategori",
        "SEK": "kr",
        "PRICE": "PRIS",
        "Related Items": "Relaterade annonser",
        "Other People Also Looked At": "Andra kollade också på",
        "More items from": "Fler annonser av",
        "Shoes": "Skor",
        "shoes": "skor",
        "Skirts": "Kjolar",
        "skirts": "kjolar",
        "Shirts": "Skjortor",
        "shirts": "skjortor",
        "Coats & Jackets": "Jackor",
        "coats-jackets": "jackor",
        "Other": "Övrigt",
        "other": "ovrigt",
        "Bags & Purses": "Väskor",
        "bags-purses": "vaskor",
        "Dresses": "Klänningar",
        "dresses": "klanningar",
        "Jeans & Trousers": "Jeans & Byxor",
        "jeans-trousers": "byxor",
        "Knitwear": "Stickat",
        "knitwear": "stickat",
        "Tops": "Toppar",
        "tops": "toppar",
        "Cardigans & Jumpers": "Tröjor & Koftor",
        "cardigans-jumpers": "trojor",
        "Blouses": "Blusar",
        "blouses": "blusar",
        "sweden": "Sverige",
        "Suits & Blazers": "Kostymer",
        "suits-blazers": "kostymer",
        "Hoodies & Sweatshirts": "Huvtröjor & Träningströjor",
        "hoodies-sweatshirts": "huvtrojor",
        "Accessories": "Accessoarer",
        "accessories": "accessoarer",
        "Item": "objektet",
        "Bag": "väska",
        "Jacket": "jacka",
        "Dress": "klänning",
        "Sweatshirt": "tröja",
        "Shirt": "tröja",
        "Blouse": "blus",
        "Shorts": "shorts",
        "Skirt": "kjol",
        "T-Shirt": "t-shirt",
        "Top": "topp",
        "Suit": "kostym",
        "Tags": "Taggar",
        "Cardigan": "tröja",
        "Check out these related items! 😍": "Kolla in dessa liknande annonser 😍",
        "Get a better Plick experience by downloading the app for free!": "",
        "Download the app for free if you're interested in the": "Ladda ner appen gratis om du är intresserad av",
        "or thousands of other bargains": "eller är sugen på att fynda bland tusentals andra plagg",
        "Buy the": "Köp",
        "in the app": "i appen",
        "Brand": "Märke",
        "from": "från",
        "yellow": "gul",
        "brown": "brun",
        "black": "svart",
        "blue": "blå",
        "red": "röd",
        "green": "grön",
        "pink": "rosa",
        "How do I buy and sell on Plick?": "Hur köper och säljer jag på Plick?",
        "Women's Clothes": "Damkläder",
        "Men's Clothes": "Herrkläder",
        "Men's Shoes": "Herrskor",
        "Women's Shoes": "Damskor",
        "Login to buy this item": "Logga in för att köpa",
        "Refine": "Justera",
        "like": "gilla",
        "likes": "gillar",
        "contact": "kontakta"
    }
}
module.exports = {
    new_translate: function(library) {
        return function(string, context) {
            context = "_" + (context || "general");
            if (!library[context]) return string;
            return library[context][string] || string;
        }
    },
    slug_translate: function(library) {
        library = library._slugs || {};
        return function(string, reverse, fb) {
            if (!!reverse) {
                for (var slug in library) {
                    if (library[slug] === string) return slug;
                }
                return fb ||  slug;
            }
            return library[string] || string;
        }
    },
    translate: function(string, country, reverse) {
        if (country && typeof country === 'string') {
            var language = country.toLowerCase();
            if (!language || language === "finland" || language === "sverige") language = 'sweden';
        } else {
            var language = 'sweden'
        }
        if (!reverse) {
            if (!languages[language]) return string;
            return languages[language][string] || string;
        } else {
            for (var lang in languages) {
                for (var key in languages[lang]) {
                    //console.log("Comparing " +languages[lang][key].toLowerCase() + " to " + entry);
                    if (languages[lang][key] === string) {
                        var result = key;
                    }
                }
            }
            // console.log("Turned out to be: "+ result);
            return result || string;
        }
    },
    detect: function(string) {
        for (var lang in languages) {
            for (var key in languages[lang]) {
                //console.log("Comparing " +languages[lang][key].toLowerCase() + " to " + entry);
                if (languages[lang][key] === string) {
                    var result = lang;
                    break;
                }
            }
            if (result) break;
        }
        return result || "";
    },
    sizes: function() {
        return sizes;
    }
}