var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<img src=\"https://s3-eu-central-1.amazonaws.com/plick-stocks/wp-content/uploads/2021/11/24111752/ezgif.com-gif-maker-2.gif\" alt=\"missing page\", width=\"200\">\n<div class=\"missing-page-content\">\n    <h1>"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Ooops... the page can not be found", "general", {"name":"translate","hash":{},"data":data})))
    + "</h1>\n    <br>\n    <p>"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "We have searched everywhere but the page you are looking for does not seem to be anywhere... sorry!", "general", {"name":"translate","hash":{},"data":data})))
    + "</p>\n    <br>\n    <p>"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "While we look further, you can search in the menu.", "general", {"name":"translate","hash":{},"data":data})))
    + "</p>\n    <br>\n    <p class=\"bold\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Did you get here by using a link?", "general", {"name":"translate","hash":{},"data":data})))
    + "</p>\n    <p>"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Contact us at ", "general", {"name":"translate","hash":{},"data":data})))
    + " <a href = \"mailto: support@plick.se\">support@plick.se</a>"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, " so that we can investigate it further.", "general", {"name":"translate","hash":{},"data":data})))
    + "</p>\n</div>";
},"useData":true});