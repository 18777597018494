var Backbone = require('backbone');
var $ = require("jquery");
var Events = require("../events");
var Handlebars = require('hbsfy/runtime');
var helpers = require('../../config/helpers');
Backbone.$ = $;

var template = require("../templates/ad_detailed.hbs");

module.exports = Backbone.View.extend({
  events: {
    "click .category": "showCat",
    "click .contact-button" : "contactClick",
    "click .show-get-the-app" : "showGetTheApp",
    "click .follow-button" : "followUser",
    "click .expand" : "expandDesc",
    "click .item-photos img" : "thumbnailClick",
    "click .like-button" : "toggleLike",
  },
  tagName: "div",
  className: "adDetailed",
  initialize: function () {
    this.initTemplate();
    this.listenTo(this.model, "change", this.render);
    Events.trigger("header:single-ad", this);
    if (window._chat_sockets) window._chat_sockets.viewAd(this.model.id);
  },
  showCat: function(e) {
    e.preventDefault();
    var url = "";
    var href = $(e.currentTarget).attr('href')
    if (href.length) href = href.substring(1);
    Events.trigger("router:navigate", href ,{trigger: true});
  },
  render: function() {
    var self = this;
    var html = template( {ad : this.model.attributes } );
    this.$el.html( html );
    this.$el.attr('data-hash', this.model.id);
    if (this.model.attributes.sold) this.$el.addClass('sold');
    if (this.model.attributes.user_ads) window._tracking.impressions(this.model.attributes.user_ads, 'More From This User',0);
    if (this.model.attributes.related) window._tracking.impressions(this.model.attributes.related, 'Related Items',0);
    if (this.model.attributes.similar) window._tracking.impressions(this.model.attributes.similar, 'Similar Items',0);
    window._tracking.view(this.model.attributes);
    this.delegateEvents();
    this.$el.find('.item-photos img').on('load', function(e) {
      if (this.height != this.width) {
        self.model.ensureSquare();
      }
    });
    return this;
  },
  initTemplate: function () {
    var hbsConfig = require('../../config/handlebars');
    hbsConfig.generic(Handlebars);
    hbsConfig.ad(Handlebars);
    Handlebars.registerPartial('share_container', require('../templates/share_container.hbs'));
    Handlebars.registerPartial('how_to_buy', require('../templates/how_to_buy.hbs'));
    Handlebars.registerPartial('ad', require('../templates/ad.hbs'));
  },
  trackClick: function(ad, pos, list) {
    if (window.dataLayer) dataLayer.push({ 
      event: 'pclick', 
      category: ad.category.name, 
      label: ad.hash_id,
      'ecommerce': {
        'currencyCode': ad.currency, 
        'click': {
          'actionField': {'list': list},      // Optional list property.
          'products': [{
            'name': ad.title,       // Name or ID is required.
            'id':  ad.hash_id,
            'price':  (ad.price / 100),
            'brand':  ad.brand?.name,
            'category':  ad.category.name,
            'position': pos
           }]
        }
      }
    });
  },
  toggleLike: function(e) {
    e.preventDefault();
    this.model.like();
  },
  thumbnailClick: function (e) {
    e.preventDefault();
    var self = this;
    var target = $(e.currentTarget);
    if (!target.hasClass('active')) {
      target.addClass('active').siblings().removeClass('active');
    }
  },
  followUser: function(target) {
    var user = this.model.getUser();
    user.follow();
  },
  contactClick: function(target) {
    var self = this;
    // Events.trigger('click:contact', { item: this.model });
    if (self.model.conversations.first()) {
      self.model.conversations.first().renderAndFetch();
    } else {
      self.model.fetchConversations(function() {
        if (self.model.conversations.first()) self.model.conversations.first().renderAndFetch();
        else Events.trigger('conversation:new', self.model);
      });
    }
    window._tracking.interaction('contact', self.model.id, self.model.attributes);
  },
  appStore: function(target) {
    target.preventDefault();
    var trackObj = { 'event' : 'contact-button' };
    trackObj.id = this.model.id;
    trackObj.loc = this.model.attributes.user.location;
    trackObj.user = this.model.attributes.user.name;
    if (this.model.attributes.category && this.model.attributes.category.name) trackObj.category = this.model.attributes.category.name;
    trackObj.eventCallback = function() {
      document.location = $('.contact-button').attr('href');
    }
    dataLayer.push(trackObj);
  },
  showGetTheApp: function(target) {
      target.preventDefault();
      $('.get-the-app').toggleClass('show');
      if ($('.get-the-app').is('.show')) {
          var linkname = "Unknown";
          if ($(target.currentTarget).is('.ear')) {
              linkname = "Ear Click";
          }
          this.trackAppstoreDrawer("open", linkname, target.currentTarget.id);
      }
  },
  trackAppstoreDrawer: function(action, linkname, trigger) {
    if (window.dataLayer) {
        window.dataLayer.push({
            "event": "get-the-app",
            "action": action,
            "category": "App Download Drawer Impression",
            "label": linkname,
            "trigger": trigger,
        })
    }
  },
  expandDesc: function(target) {
    this.$el.find('.item-description').addClass('show-desc');
  }
});