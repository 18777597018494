var Backbone = require('backbone');
var $ = require("jquery");
var Events = require("../events");
var template = require("../templates/header.hbs");
var Handlebars = require('hbsfy/runtime');
var searchbarTemplate = require("../templates/landing.hbs");
var miniBrandTemplate = require("../templates/mini_brand_template.hbs");
var helpers = require("../../config/helpers");
var AdCollection = require("../collections/ads");
var AdCollectionView = require("../views/ads");
var translate = require('../../config/translations.js').new_translate(__translations);
Backbone.$ = $;

module.exports = Backbone.View.extend({
    events: {
        "submit .search-form": "searchWithQuery",
        "click .toggle-button": "filterButtonClick",
        "click .search-autocomplete-container": "autocompleteClick",
        "click .filter-overlay": "closeFilter",
        "click .filter-clear": "clearForm",
        "click .search-empty": "clearSearch",
        "focus #search-input": "focusSearchInput",
        // "focusout #search-input, .autocomplete-result": "focusoutAutocomplete",
        "keyup #search-input": "searchAutocomplete",
        "keyup #filter-search-input": "syncFilterSearchToGlobal",
        "keydown .search-autocomplete-container": "keyUpOnAutocompleteResults",
    },
    tagName: "div",
    className: "full-width search-bar-view",
    id: "search-bar",
    initialize: function(options) {
        var self = this;
        this.scrollDown();
        this.i = 0;
        this.isMobile = $(window).width() < 768;
        var isMobile = this.isMobile;
        Events.on("header:single-ad", function(adView) {
            console.log("Captured Single Ad Event!");
            self.state = "ad";
            self.adView = adView;
            self.renderState();
        });
        Events.on("header:single-user", function(userView) {
            console.log("Captured Single User Event! Leaving state: ", self.state);
            self.state = "user";
            self.userView = userView;
            self.renderState();
            $('.content-wrapper,body').animate({ scrollTop: 0 }, 200);
        });
        Events.on("header:single-brand", function(brand) {
            self.brand = brand;
            self.state = "brand";
            self.renderState();
        });
        Events.on("header:single-category", function(category) {
            self.category = category;
            self.state = "category";
            self.renderState();
        });
        Events.on("header:single-search", function(params) {
            self.state = "search";
            self.renderState(params);
        });
        Events.on("header:landing", function() {
            self.state = "landing";
            self.renderState();
        });
        Events.on("header:feed", function() {
            self.state = "feed";
            self.renderState();
        });
        Backbone.history.on("all", function(route, router) {
            self.closeMegaMenu();
        });
        $(window.document).scroll(function(e) {
            if (!self.scrolling) {
                self.scrolling = true;
                window.raf(self.trackScroll.bind(self));
            }
        }).trigger('scroll');
        $('.toggle-get-the-app').click(function(e) {
            e.preventDefault();
            $('.get-the-app').toggleClass('show');
            if ($('.get-the-app').is('.show')) {
                var linkname = "Unknown";
                if ($(e.currentTarget).is('.ear')) {
                    linkname = "Ear Click";
                }
                self.trackAppstoreDrawer("open", linkname);
            }
        });
        $('.close-get-the-app').click(function(e) {
            e.preventDefault();
            $('.get-the-app').removeClass('show');
        });
        $(document).on('click', '.open-get-the-app', function(e) {
            e.preventDefault();
            $('.get-the-app').addClass('show');
            var linkname = "Unknown";
            if ($(e.currentTarget).is('.appstore-button')) {
                linkname = "Single Item Green Button";
            } else if ($(e.currentTarget).is('.info-link')) {
                if ($(e.currentTarget).children().is('.ion-ios-chatbubble')) {
                    linkname = "User Chat Bubble";
                } else if ($(e.currentTarget).children().is('.ion-pin')) {
                    linkname = "User Follow";
                }
            }
            self.trackAppstoreDrawer("open", linkname);
        });
        $(document).on('click', '.share', function(e) {
            if ($(e.currentTarget).is('.facebook')) {
                self.shareToFacebook(e);
            } else if ($(e.currentTarget).is('.email')) {
                self.shareToEmail(e);
            } else if ($(e.currentTarget).is('.sms')) {
                self.shareToSMS(e);
            }
        });
        $('.toggle-chat').click(function(e) {
            console.log("chat toggled");
            var showing = $('body').attr('show');
            if (showing === "chat") {
                $('body').attr('show', '')
            } else {
                $('body').attr('show', 'chat').removeClass('show-sub-menu');
            }
        });
        $('.toggle-filter').click(this.toggleFilter);
        $('.toggle-social').click(function(e) {
            console.log("social toggled");
            var showing = $('body').attr('show');
            if (showing === "social") {
                $('body').attr('show', '')
            } else {
                $('body').attr('show', 'social').removeClass('show-sub-menu');
            }
        });
        $('.toggle-search').click(function(e) {
            console.log("search toggled");
            $('body').toggleClass("show-search");
            $('body').attr('show', '')
            $('#search-input').focus();
        });
        $('.search-close').click(function() {
            $('body').removeClass("show-search");
        });
        $(document).click(function(e) {
            if ($(e.target).closest('.conversation-container').length === 0 && $(e.target).closest('.toggle-chat').length === 0) {
                // $('body').removeClass('show-chat');
            }
            if ($(e.target).closest('.sub-menu').length === 0 && $(e.target).closest('.toggle-mega-menu').length === 0) {
                $('body').removeClass('show-sub-menu');
            }
        });
        $('.menu-item.toggle-mega-menu').click(function(e) {
            if (!$(e.target).attr('href')) e.preventDefault();
            $('body').toggleClass('show-sub-menu');
            $('body').attr('show', '');
        })
        $('.toggle-submenu').click(function(e) {
            var self = $(this);
            e.preventDefault();
            if (isMobile) {
                $('.toggle-submenu').not($(this)).removeClass('active');
                $(this).toggleClass('active');
                $(this).siblings('.submenu').find('a').click(function() {
                    self.removeClass('active');
                });
            }
        });
        // this.updateForm();
        this.animateSVG();
        this.renderInputRange();

        if (document.cookie.replace(/(?:(?:^|.*;\s*)cookiesAccepted\s*\=\s*([^;]*).*$)|^.*$/, "$1") !== "true") {
            var cookieNotice = $('.cookie-notice');
            cookieNotice.show();
            cookieNotice.find('button').click(function(e) {
                e.preventDefault();
                document.cookie = "cookiesAccepted=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
                cookieNotice.fadeOut();
            });
        }

        // if (document.cookie.replace(/(?:(?:^|.*;\s*)hideSellNotice\s*\=\s*([^;]*).*$)|^.*$/, "$1") !== "true") {
        //     var sellNotice = $('.toggle-sell .info-bubble');
        //     var closeSellNotice = sellNotice.find('.close-bubble');
        //     sellNotice.show();
        //     sellNotice.click(function() {
        //         document.cookie = "hideSellNotice=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
        //         sellNotice.hide();
        //     });
        //     closeSellNotice.click(function(e) { // Hide bubble but don't trigger gtm
        //         e.preventDefault();
        //         e.stopPropagation();
        //         document.cookie = "hideSellNotice=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
        //         sellNotice.hide();
        //     });
        // }


        // mega menu
        $("#mega-menu-close-button").on('touchend', self.closeMegaMenu);
        $(".mega-menu-wrapper").on('mouseleave', self.closeMegaMenu);
        $(".mega-menu-item").on('touchstart mouseenter', self.activateMegaMenu);
        $(document).click(function(e) {
            if (!$(e.target).parents('.search-input-container').length) {
                self.$autoCompleteResults.removeClass('active');
            }
        });
    },
    activateMegaMenu: function(e) {
        var id = $(this).data('menu-id');
        $(".mega-menu-item").not("[data-menu-id='" + id + "']").removeClass('active');
        $(this).addClass('active');
        $(".mega-menu").not("[data-menu-id='" + id + "']").removeClass('active');
        $(".mega-menu[data-menu-id='" + id + "']").addClass("active");
        if($('.mega-menu-content').length >= 0) {
            $('.mega-menu-content').addClass('active');
        }
    },
    closeMegaMenu: function(e) {
        $('.mega-menu-content').removeClass('active');
        $(".mega-menu-item").removeClass('active');
        $(".mega-menu").removeClass('active');
    },
    trackAppstoreDrawer: function(action, linkname) {
        if (window.dataLayer) {
            window.dataLayer.push({
                "event": "get-the-app",
                "action": action,
                "category": "App Download Drawer Impression",
                "label": linkname
            })
        }
    },
    shareToSMS: function(e) {
        var a = $(e.currentTarget);
        var type = "annonsen";
        if (this.state === "user") type = "profilen";
        var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        var link = "sms:";
        if (iOS) link += "?&";
        else link += "?";
        link += "body=" + helpers.getSMSBody(type, window.location.href);
        a.attr("href", link);
        if (response && window.dataLayer) {
            window.dataLayer.push({
                "event": "share",
                "action": "sms",
                "category": "profile"
            })
        }
    },
    shareToEmail: function(e) {
        var a = $(e.currentTarget);
        var type = "annonsen";
        if (this.state === "user") type = "profilen";
        var link = "mailto:?subject=" + helpers.getMailSubject(type) + "&body=" + helpers.getMailBody(type, window.location.href);
        a.attr("href", link);
        if (window.dataLayer) {
            window.dataLayer.push({
                "event": "share",
                "action": "email",
                "category": "profile"
            })
        }
    },
    shareToFacebook: function(e) {
        e.preventDefault();
        FB.ui({
            method: 'share',
            href: window.location.href,
        }, function(response) {
            console.log("Shared to facebook!");
            if (response && window.dataLayer) {
                window.dataLayer.push({
                    "event": "share",
                    "action": "facebook",
                    "category": "profile"
                })
            }
        });
    },
    focusoutAutocomplete: function(e) {
        var self = this;
        setTimeout(function() {
            var focus = $(':focus');
            if (!focus.is('.autocomplete-result') && !focus.is('#search-input')) {
                self.$autoCompleteResults.removeClass('active');
            }
        }, 50);
    },
    focusSearchInput: function(e) {
        this.$autoCompleteResults.addClass('active');
    },
    clearSearch: function(e) {
        $('#search-input').val('');
    },
    searchAutocomplete: function(e) {
        var code = e.keyCode || e.which;
        if (code == 13) this.$form.submit();
        this.syncGlobalToFilterSearch(e);
        //var val = this.$query.val();
        var val = e.target.value;
        if (code == 40) {
            $('.autocomplete-result').first().focus();
        } else if (code !== 9 && val.length > 0) {
            var self = this;
            var key = ++self.i;
            setTimeout(function() {
                if (key === self.i) {
                    $.get('/api/items/autocomplete?query=' + encodeURIComponent($(e.currentTarget).val()), function(data) {
                        var current = self.$autoCompleteResults.children().remove();
                        if (data.brands && data.brands.length) {
                            for (var i = 0; i < data.brands.length; i++) {
                                var template = miniBrandTemplate({ brand: data.brands[i] });
                                self.$autoCompleteResults.append(template);
                            }
                        }
                        if (data.results && data.results.length) {
                            for (var i = 0; i < data.results.length; i++) {
                                var template = $('<a href="/?query=' + helpers.sanitize(data.results[i].query) + '" class="autocomplete-result query"><span class="query">' + helpers.sanitize(data.results[i].query) + '</span></a>');
                                self.$autoCompleteResults.append(template);
                            }
                        }
                    });
                }
            }, 1)
        } else if (val.length === 0) {
            this.$autoCompleteResults.empty();
        }
    },
    keyUpOnAutocompleteResults: function(e) {
        var code = e.keyCode || e.which;
        if (code == 38) {
            if ($('.autocomplete-result:focus').is(':first-child')) $('#search-input').focus()
            else $('.autocomplete-result:focus').prev().focus()
            e.preventDefault();
        } else if (code == 40) {
            $('.autocomplete-result:focus').next().focus()
            e.preventDefault();
        } else if (code == 27) {
            this.$autoCompleteResults.removeClass('active');
        }
    },
    autocompleteClick: function(e) {
        // e.preventDefault();
        var target = $(e.target);
        console.log(target);
        if (!target.is('.autocomplete-result')) target = target.parents('.autocomplete-result')
        if (target.is('.brand')) {
            // Events.trigger("router:navigate", "/märkeskläder/" + target.data('slug'), { trigger: true });
            $('#search-input').val(target.text().trim());
        } else {
            $('#search-input').val(target.find('.query').text());
            // this.$form.submit();
        }
        target.siblings().remove();
        this.$autoCompleteResults.removeClass('active');
        $('body').removeClass('show-search');
    },
    trackScroll: function(e) {
        var y = this.parent.scrollTop || document.body.scrollTop  || window.scrollY;
        if (y) {
            trackEndlessScroll(this, y);
            window.raf(this.trackScroll.bind(this));
        } else {
            this.scrolling = false;
        }

        function trackEndlessScroll(self, y) {
            var adCollectionView;
            if (self.state == "landing") return;
            if (self.state != "user") {
                adCollectionView = self.adCollectionView;
            } else {
                adCollectionView = self.userView.adCollectionView;
            }
            if (adCollectionView && !adCollectionView.loading) {
                if (y + $(window).height() >= self.$content.outerHeight() - 200) {
                    adCollectionView.loadMoreAds();
                }
            }
        }
    },
    scrollDown: function(e) {
        $('.ads').animate({ scrollTop: 180 }, 1000);
    },
    toggleFilter: function(e) {
        if (e) e.preventDefault();
        $('.filter-container').toggleClass('showing-filter');
    },
    openFilter: function(e) {
        if (e) e.preventDefault();
        $('.filter-container').addClass('showing-filter');
    },
    closeFilter: function(e) {
        if (e) e.preventDefault();
        this.$form.submit();
        $('.filter-container').removeClass('showing-filter');
    },
    filterButtonClick: function(e) {
        var $b = $(e.currentTarget);
        var $input = $b.children('input');
        $input.prop("checked", !$input.is(':checked'));

        this.search();
        this.toggleSizeGroups();
    },
    toggleSizeGroups: function() {
        var size_groups = $('.size-buttons').find('.range');
        var titles = $('.size-buttons').find('h3');
        var active_cats = this.adCollectionView.collection.params.category_ids || [];
        var active_gender = this.adCollectionView.collection.params.gender || "";

        function ensureStrings(list) {
            $.each(list, function(k, v) {
                list[k] = v + '';
            });
        }

        size_groups.hide();
        titles.hide();

        if (!active_cats.length && !active_gender.length) {
            size_groups.show();
            titles.show();
            return;
        }

        if (active_gender == "U") {
            size_groups.hide();
            titles.hide();
            return;
        }

        ensureStrings(active_cats);
        size_groups.each(function(i) {
            var size_group = $(this);
            var categories = $(this).attr('data-categories') || "";
            var gender = $(this).attr('data-gender') || "";
            categories = categories.split(',');
            $.each(categories, function(k, cat) {
                if ((active_cats.indexOf(cat) != -1 || !active_cats.length) && (active_gender == gender || !active_gender)) {
                    size_group.show();
                    titles.eq(i).show();
                }
            });
        });
    },
    render: function() {
        if ($('#' + this.id).length) {
            this.setElement($('#' + this.id));
        } else {
            var hbsConfig = require('../../config/handlebars');
            hbsConfig.generic(Handlebars);
            hbsConfig.blocks(Handlebars);
            this.$el.html(template(this));
        }
        this.initShortcuts();
        this.initSizes();
        this.updateForm();
        return this;
    },
    //TODO: else do the same, else do the same, else do the same
    renderState: function(params) {
        var self = this;
        if (this.state === "user" && this.userView) {
            if (!this.userView.$el.is(':visible')) {
                self.userView.searchBar = self;
                self.clearForm();
                self.search(false, true);
                self.updateForm();
            }
        } else if (this.state === "ad") {
            self.renderSearchbar();
            self.clearForm();
        } else if (this.state === "brand" && this.brand) {
            self.renderSearchbar();
        } else if (this.state === "feed") {
            self.renderSearchbar();
        } else if (this.state === "category" && this.category) {
            self.renderSearchbar();
        } else if (this.state === "search") {
            self.renderSearchbar();
        }
        return this;
    },
    setMetaData: function(h1, desc) {
        $('h1.main-title').text(helpers.sanitize(h1));
        // $('h2.SEO-content').text(helpers.sanitize(desc));
        $('meta[property="og:description"], meta[name="description"]').attr('content', helpers.sanitize(desc));
    },
    renderSearchbar: function() {
        var self = this;
        if (!this.$el.find('.header-content').children().is('.search-bar')) {
            var template = searchbarTemplate();
            var $template = $('<div class="header-content brand waiting">' + template + '</div>');
            self.deRender();
            $template.removeClass('waiting');
            self.initShortcuts();
            self.undelegateEvents();
            self.delegateEvents();
        }
    },
    deRender: function(next) {
        var self = this;
        var current = self.$el.find('.header-content');
        if (!(self.state === "user" && current === this.userView.$el)) {
            current.addClass('passed');
            setTimeout(function() {
                current.remove();
            }, 300);
        }
    },
    initShortcuts: function() {
        this.$stickyFilter = this.$el.find('.filter-container');
        this.$stickyMenu = this.$el.find('.menu-container');
        this.$form = this.$el.find('.search-form');
        this.$gender = this.$form.find('[name="gender"]');
        this.$category = this.$form.find('[name="category_ids[]"]');
        this.$brand_ids = this.$form.find('[name="brand_ids[]"]');
        this.$query = this.$el.find('#search-input');
        this.$filterQuery = this.$el.find('#filter-search-input');
        this.$size_ids = this.$form.find('#size_ids');
        this.parent = this.el.parentNode;
        this.$header = $('header');
        this.$headerBadge = this.$header.find('.plick-badge-wrapper');
        this.$headerLogo = this.$header.find('.plick-header-logo');
        this.$autoCompleteResults = this.$el.find('.search-autocomplete-container');
        this.$content = $('#content');
        this.badgeHang = this.$headerBadge.height() - this.$header.height() + 15;
    },
    syncFilterSearchToGlobal: function(e) {
        if (e) e.preventDefault();
        var val = e.target.value;
        this.$query.val(val);
    },
    syncGlobalToFilterSearch: function(e) {
        if (e) e.preventDefault();
        var val = e.target.value;
        this.$filterQuery.val(val);
    },
    searchWithQuery: function(e) {
        if (e) e.preventDefault();
        if (this.adCollectionView) delete this.adCollectionView.collection.brand;
        this.search();
        $('body').removeClass('show-search');
        $('.filter-container').removeClass('showing-filter');
        $('input').blur(); // Hide keyboard on mobile

        $.each($('body').attr('class').split(' '), function(k, val) {
            if (val.substring(0, 4) === "show") {
                $('body').removeClass(val);
            }
        });
        $('body').addClass('show-index');
    },
    /*removeUnmatchedSize: function(this, self) {
      if($(this).is('#size_ids')) {
        var gender = $('.search-form input[name=gender]').val();
        console.log("a",self.$form,gender)
      //  return $(this).find('optgroup[data-gender='+gender+']').find('option:selected').length;
      }
      return true;
    },*/
    search: function(e, dontPush) {
        if (e) e.preventDefault();
        var self = this;
        var collection = new AdCollection();
        collection
            .initParams(
                this.$form.find(":input")
                .filter(function() {
                    if ($(this).is('#size_ids')) {
                        var gender = self.$gender.filter(':checked').val();
                        return !gender || $(this).find('optgroup[data-gender=' + gender + ']').find('option:selected').length;
                    }
                    return true;
                })
                .filter(function() { return !!this.value; })
                .serialize()
            );
            
        window._cache.getBrandsById(function(brandsById) {
            window._cache.getCategoriesById(function(catsById) {
                var url = '';
                if (self.state === "user" && !Object.keys(collection.params).length) {
                    collection.user = self.userView.model;
                }
                if (collection.user) {
                    url += translate('users', "slugs") + '/' + collection.user.id;
                } else if (collection.params.brand_ids && collection.params.brand_ids.length > 0 && collection.params.brand_ids[0]) {
                    url += translate('brands', "slugs") + '/' + brandsById[collection.params.brand_ids[0]].slug;
                }
                if (collection.params.category_ids && collection.params.category_ids.length > 0) {
                    url += '/' + catsById[collection.params.category_ids[0]].slug;
                }
                url += "?" + collection.queryString(true);
                if (window._cache.views.collections[collection.url()]) console.log("Fetching AdsView from cache", collection.url());
                var adCollectionView = window._cache.views.collections[collection.url()] || new AdCollectionView({
                    collection: collection,
                    header: this,
                });
                var container = $("#feed");
                if (adCollectionView.collection.user) {
                    container = $('#items-wrapper');
                    self.userView.adCollectionView = adCollectionView;
                }
                container.html(adCollectionView.render().el);
                $("#blocks").html("");
                // url = url.replace('&', '?');
                if (!dontPush) {
                    Events.trigger("router:navigate", url, { trigger: true });
                    if (adCollectionView.collection.brand) Events.trigger("header:single-brand", adCollectionView.collection.brand);
                    else if (adCollectionView.collection.category) Events.trigger("header:single-category", adCollectionView.collection.category);
                    else if (!adCollectionView.collection.user) {
                        Events.trigger("header:single-search", adCollectionView.collection.params);
                    }
                }
                self.adCollectionView = adCollectionView;
                window._cache.views.collections[window.location.href] = adCollectionView;
            });
        });
        self.renderFilterSummary();
    },
    renderFilterSummary: function() {
        var self = this;
        console.log(this.isMobile)
        if (!this.isMobile) return;
        var activeItemsWrapper = $('.filter-status');
        var query = this.adCollectionView.collection.params.query || "";
        var summary = "";
        console.log("params", this.adCollectionView.collection.params);
        window._cache.getCategoriesById(function(catsById) {
            var cats = self.adCollectionView.collection.params.category_ids || [];
            var activeCats = "";
            $.each(cats, function(k, v) {
                var cat = catsById[v].name;
                activeCats += cat + ", ";
            });
            summary += query ? query + ', ' : '';
            summary += activeCats;
            summary.slice(0, -2);
            if (!summary) summary = "Filtrerat";
            activeItemsWrapper.find('.active-items').text(summary);
            var filterParameters = self.adCollectionView.collection.params;
            var filterParameterKeys = Object.keys(filterParameters);
            if (!filterParameterKeys.length ||
                (filterParameterKeys.length === 1 && filterParameterKeys[0] === "order_by")) {
                activeItemsWrapper.hide();
            } else {
                activeItemsWrapper.show();
            }
        });
    },
    updateForm: function() {
        var self = this;
        if (this.adCollectionView && this.adCollectionView.collection) {
            if (this.adCollectionView.collection.params.query) this.$query.attr('value', this.adCollectionView.collection.params.query);
            if (this.adCollectionView.collection.params.sizes) this.$size.attr('value', this.adCollectionView.collection.params.size);
            if (this.adCollectionView.collection.params.gender) this.$gender.prop('checked', false).filter('[value="' + this.adCollectionView.collection.params.gender + '"]').prop('checked', true);
            var cats = this.adCollectionView.collection.params.category_ids ||  [];
            this.$category.each(function(index, el) {
                $(el).prop('checked', cats.indexOf(el.value) > -1);
            });
            var brands = this.adCollectionView.collection.params.brand_ids || [];
            this.$brand_ids.val(brands[0]);
        }
    },
    setTitle: function(title) {
        $('#primary-title').text(title);
    },
    clearCategory: function() {
        this.$el.find('[name="category"]:checked').click();
    },
    clearForm: function() {
        this.$gender.prop('checked', false);
        this.$category.prop('checked', false);
        this.$size_ids.find('option').prop('selected', false);
        this.$brand_ids.val('');
        this.resetInputRange();
        this.$query.val('');
        this.$filterQuery.val('');
    },
    convert: function() {
        var $el = $("#" + this.id);
        if ($el.length) {
            this.setElement($el[0]);
            this.delegateEvents();
        }
    },
    initSizes: function() {
        this.$size_ids
    },
    animateSVG: function(target) {
        var scrollWrapper = $('body .content-wrapper');
        var MAXLENGTH = 400;
        var stroke;

        function beginAnimation(element, fillAll) {
            var windowHeight = $(window).height();
            var entering = -element.offset().top + windowHeight;
            var percentage = entering / (windowHeight * 0.7);
            if (percentage > 1) percentage = 1;
            if (percentage < 0) percentage = 0;

            if (!element.hasClass('pop-animate')) {
                // Fill line depending on scroll position
                var filledLine = (MAXLENGTH * (1 - percentage));

                // Fill whole line if scrolling stops
                if (fillAll && percentage > 0.1) {
                    filledLine = 0;
                }
                /*if(parseInt(element.css("stroke-dashoffset")) != 0) */
                element.css({ "stroke-dashoffset": filledLine });
                if (element.css('fill') != 'none' && percentage > 0.4) {
                    element.css('fill-opacity', 1);
                }
            } else {
                if (percentage > 0.6) {
                    element.addClass('pop-show');
                    element.addClass('pop-show-pop');
                }
            }
        }

        var isMobile = $(window).width() < 768;
        var scrollPause;

        if (!isMobile) {
            scrollWrapper.on('scroll', function() {
                stroke = $('svg.animate').find("line, polygon, circle, path, rect, ellipse").add('.pop-animate');
                stroke.css({ 'stroke-dasharray': MAXLENGTH, 'fill-opacity': 0 });

                var fillPercentage = false;
                clearTimeout(scrollPause);
                scrollPause = setTimeout(function() {
                    stroke.each(function() {
                        beginAnimation($(this), true);
                    });
                }, 800);

                // Begin animating each stroke
                stroke.each(function() {
                    beginAnimation($(this), false);
                });
            });
        }
    },
    resetInputRange: function() {
        var ranges = $('.range');
        ranges.each(function() {
            var bullets = $(this).find('.range-bullet');
            var rangeLineActive = $(this).find('.range-line-active');
            bullets.first().css('left', '0');
            bullets.last().css('left', '100%');
            rangeLineActive.css({ 'width': '100%', 'left': '0' });
        });
    },
    renderInputRange: function() {
        var self = this;

        function makeInputRange(element) {
            //element.hide();
            var range = $("<div class='range'></div>");
            var rangeLine = $("<div class='range-line'></div>");
            var rangeLineActive = $("<div class='range-line-active'></div>");
            var rangeLineClickArea = $("<div class='range-line-click-area'></div>");
            var bulletFirst = $("<div class='range-bullet'></div>");
            var bulletLast = $("<div class='range-bullet'></div>");
            var stepsWrapper = $("<div class='range-steps'></div>");
            var reset = $("<button class='range-reset'>Återställ</button>")
            range
                .append(reset)
                .append(bulletFirst)
                .append(bulletLast)
                .append(rangeLine)
                .append(rangeLineActive)
                .append(rangeLineClickArea)
                .append(stepsWrapper)
                .attr('data-gender', element.data('gender'))
                .attr('data-categories', element.data('categories'));

            element.parent().parent().append("<h3 class='range-title'>" + element.data('name') + "</h3>").append(range);
            var steps = element.children('option');
            var numOfSteps = steps.length;
            var isDraggingLeft = false;
            var isDraggingRight = false;
            var currentLeftStep = 0;
            var currentRightStep = numOfSteps - 1;
            var initialRangeLineWidth = rangeLineActive.width();
            var currentMostLeftBullet = bulletFirst;
            var currentMostRightBullet = bulletLast;
            var stepLength = 100 / (numOfSteps - 1);

            /*function init() {
              var element.selectedIndex
            }*/

            steps.each(function(i) {
                stepsWrapper.append("<div class='range-step' style='left:" + 100 / (numOfSteps - 1) * i + "%'>" + $(this).data('name') + "</div>");
            });

            bulletFirst
                .on('mousedown touchstart', function() {
                    isDraggingLeft = true;
                    isDragging();
                });
            bulletLast
                .on('mousedown touchstart', function() {
                    isDraggingRight = true;
                    isDragging();
                });

            function determineMostRightLeftBullet() {
                currentMostLeftBullet = parseInt(bulletFirst.css('left')) < parseInt(bulletLast.css('left')) ? bulletFirst : bulletLast;
                currentMostRightBullet = parseInt(bulletFirst.css('left')) < parseInt(bulletLast.css('left')) ? bulletLast : bulletFirst;
            }

            function snapToStep(bullet) {
                var closest;
                var currentLeft = parseInt(bullet.css('left')) / rangeLine.width() * 100;
                var step = Math.round(currentLeft / stepLength);
                bullet.css('left', stepLength * step / 100 * rangeLine.width());
                return step;
            }

            function getClosestBullet(pos) {
                var first = Math.abs(pos - parseInt(bulletFirst.css('left')));
                var last = Math.abs(pos - parseInt(bulletLast.css('left')));
                return last > first ? bulletFirst : bulletLast;
            }

            function moveBulletToStep(step, bullet, no_submit) {
                steps.removeAttr('selected');
                if (currentLeftStep === 0 && currentRightStep === steps.length - 1) {

                } else {
                    steps.eq(currentLeftStep).prop('selected', true);
                    steps.eq(currentRightStep).prop('selected', true);
                }
                determineMostRightLeftBullet();

                rangeLineActive.css({
                    'left': currentMostLeftBullet.css('left'),
                    'width': initialRangeLineWidth - parseInt(currentMostLeftBullet.css('left')) - (initialRangeLineWidth - parseInt(currentMostRightBullet.css('left')))
                });
                if (!no_submit) self.$form.submit();
            }

            reset.click(function() {
                console.log("reseteset")
                bulletFirst.css('left', 0);
                bulletLast.css('left', '100%');
                currentLeftStep = 0;
                currentRightStep = numOfSteps - 1;
                moveBulletToStep(0, bulletFirst, true);
                moveBulletToStep(numOfSteps, bulletLast);
            });

            rangeLineClickArea.click(function(e) {
                var left = e.clientX - rangeLine.offset().left;
                var bullet = getClosestBullet(left);
                bullet.css('left', left);
                var currentStep = snapToStep(bullet);
                if (currentMostLeftBullet === bullet) {
                    currentLeftStep = currentStep;
                } else {
                    currentRightStep = currentStep;
                }
                moveBulletToStep(currentStep, bullet);
            });

            function isDragging() {
                $(document)
                    .on('mouseup touchend', function() {
                        if (isDraggingLeft) {
                            currentLeftStep = snapToStep(bulletFirst);
                            moveBulletToStep(currentLeftStep);
                        }
                        if (isDraggingRight) {
                            currentRightStep = snapToStep(bulletLast);
                            moveBulletToStep(currentRightStep);
                        }
                        isDraggingLeft = false;
                        isDraggingRight = false;
                        $(document).off('mouseup touchend mousemove touchmove');
                    })
                    .on('mousemove touchmove', function(e) {
                        if (isDraggingLeft || isDraggingRight) {
                            var x = e.type == "touchmove" ? e.originalEvent.touches[0].clientX : e.clientX;
                            var rangeLineOffset = rangeLine.offset().left;
                            var left = x - rangeLineOffset + 4;
                            var max = rangeLine.width();
                            left = left > 0 ? left : 0;
                            left = left < max ? left : max;
                            determineMostRightLeftBullet();
                            rangeLineActive
                                .css('left', currentMostLeftBullet.css('left'))
                                .width(parseInt(currentMostRightBullet.css('left')) - parseInt(currentMostLeftBullet.css('left')));
                        }
                        if (isDraggingLeft) {
                            bulletFirst.css('left', left);
                        }
                        if (isDraggingRight) {
                            bulletLast.css('left', left)
                        }
                        return false;
                    })
            };
        }

        var ranges = $('#size_ids optgroup');
        ranges.each(function() {
            makeInputRange($(this));
        });
    }
});