var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "	<h1 class=\"text-center\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.title : stack1), depth0))
    + "</h1>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = "	<div class=\"additional-ads similar text-center\">\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.sold : stack1), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.program(6, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.alternatives : stack1)) != null ? stack1.length : stack1), {"name":"if","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "	</div>\n";
},"4":function(depth0,helpers,partials,data) {
  return "			<p class=\"removed-text\">Annonsen är tyvärr såld...</p>\n";
  },"6":function(depth0,helpers,partials,data) {
  return "			<p class=\"removed-text\">Annonsen är tyvärr borttagen...</p>\n";
  },"8":function(depth0,helpers,partials,data) {
  var stack1, buffer = "			<p class=\"alternatives-text\">Men kolla in dessa liknande annonser 😍</p>\n			<div class=\"ads\">\n";
  stack1 = helpers.each.call(depth0, ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.alternatives : stack1), {"name":"each","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "			</div>\n";
},"9":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "					<div id=\"ad-brief-"
    + escapeExpression(lambda((depth0 != null ? depth0.hash_id : depth0), depth0))
    + "\" data-id=\""
    + escapeExpression(lambda((depth0 != null ? depth0.hash_id : depth0), depth0))
    + "\", class=\"ad ad-brief\">\n";
  stack1 = this.invokePartial(require("./ad.hbs"), '						', 'ad', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "					</div>\n";
},"11":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = "	<div class=\"content resist-sans\">\n		<div class=\"row\">\n			<div class=\"col-xs-12 col-md-6 item-photos-container\">\n				<div class=\"item-photos\">\n					"
    + escapeExpression(((helpers.renderImages || (depth0 && depth0.renderImages) || helperMissing).call(depth0, (depth0 != null ? depth0.ad : depth0), {"name":"renderImages","hash":{},"data":data})))
    + "\n";
  stack1 = helpers.each.call(depth0, ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.ad_photos : stack1), {"name":"each","hash":{},"fn":this.program(12, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "					<div class=\"animating\">\n						"
    + escapeExpression(((helpers.renderImages || (depth0 && depth0.renderImages) || helperMissing).call(depth0, (depth0 != null ? depth0.ad : depth0), {"name":"renderImages","hash":{},"data":data})))
    + "\n					</div>\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.sold : stack1), {"name":"if","hash":{},"fn":this.program(14, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "				</div>\n			</div>\n			<div class=\"col-xs-12 col-md-6\">\n				<div class=\"item-description\">\n					<div id=\"item-user\" class=\"user-header\">\n						<a href=\""
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.user : stack1)) != null ? stack1.preferredURL : stack1), {"name":"sanitizeHTML","hash":{},"data":data})))
    + "\" class=\"user-link\">\n							<div class=\"profile-image-container\">\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.user : stack1)) != null ? stack1.user_photo : stack1), {"name":"if","hash":{},"fn":this.program(16, data),"inverse":this.program(18, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "							</div>\n							<div class=\"user-info-container new-vertical-center\">\n								<div class=\"user-info-wrapper new-vertical-center-inner\">\n									<h3 class=\"user-name\">"
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), {"name":"sanitizeHTML","hash":{},"data":data})))
    + "</h3>\n									<p class=\"user-location medium\">";
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.user : stack1)) != null ? stack1.location : stack1), {"name":"if","hash":{},"fn":this.program(20, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.user : stack1)) != null ? stack1.country : stack1), depth0))
    + "</p>\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.user : stack1)) != null ? stack1['user-rating'] : stack1), {"name":"if","hash":{},"fn":this.program(22, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "								</div>\n							</div>\n						</a>\n						<div class=\"user-info-links\">\n							<a href=\"\" class=\"info-link triggers-login contact-button\"><span\n									class=\"icon ion-ios-chatbubble\"></span><span\n									class=\"info-link-title\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Contact", "words", {"name":"translate","hash":{},"data":data})))
    + "</span></a>\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.user : stack1)) != null ? stack1.is_following : stack1), {"name":"if","hash":{},"fn":this.program(24, data),"inverse":this.program(26, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "						</div>\n					</div>\n					<div class=\"interactions\">\n						\n						<div class=\"ad-buttons\">\n							<a href=\"\" class=\"like-button btn-plick btn-flat btn-white triggers-login\">\n								<span class=\"icon ion-ios-heart";
  stack1 = helpers.unless.call(depth0, ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.is_liked : stack1), {"name":"unless","hash":{},"fn":this.program(28, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"\n									style=\""
    + escapeExpression(((helpers.getPulseDuration || (depth0 && depth0.getPulseDuration) || helperMissing).call(depth0, (depth0 != null ? depth0.ad : depth0), {"name":"getPulseDuration","hash":{},"data":data})))
    + "\"></span>\n							</a>\n							<a href=\"\" class=\"comment-button btn-plick btn-flat btn-white show-get-the-app\" id=\"comment-button\">\n								<span class=\"icon ion-ios-chatbubble-outline\"\n									style=\""
    + escapeExpression(((helpers.getPulseDuration || (depth0 && depth0.getPulseDuration) || helperMissing).call(depth0, (depth0 != null ? depth0.ad : depth0), {"name":"getPulseDuration","hash":{},"data":data})))
    + "\"></span>\n							</a>\n							<a href=\"\" class=\"external-share-button btn-plick btn-flat btn-white show-get-the-app\" id=\"external-share-button\">\n								<span class=\"icon ion-ios-redo-outline\"\n									style=\""
    + escapeExpression(((helpers.getPulseDuration || (depth0 && depth0.getPulseDuration) || helperMissing).call(depth0, (depth0 != null ? depth0.ad : depth0), {"name":"getPulseDuration","hash":{},"data":data})))
    + "\"></span>\n							</a>\n							<a href=\"\" class=\"internal-share-button btn-plick btn-flat btn-white show-get-the-app\" id=\"internal-share-button\">\n								<span class=\"icon ion-ios-paperplane-outline\"\n									style=\""
    + escapeExpression(((helpers.getPulseDuration || (depth0 && depth0.getPulseDuration) || helperMissing).call(depth0, (depth0 != null ? depth0.ad : depth0), {"name":"getPulseDuration","hash":{},"data":data})))
    + "\"></span>\n							</a>\n						</div>\n						<div class=\"ad-buttons-count\">\n							<a id=\"likes-count-button\" class=\"show-get-the-app\"><span class=\"caption\"></span> "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.likes_count : stack1), depth0))
    + " likes</a>\n							<a id=\"comments-count-button\" class=\"show-get-the-app\"><span class=\"caption\"></span>, "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.comments_count : stack1), depth0))
    + " "
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "comments", "words", {"name":"translate","hash":{},"data":data})))
    + "</a>\n						</div>\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.bidding : stack1)) != null ? stack1.bids_count : stack1), {"name":"if","hash":{},"fn":this.program(30, data),"inverse":this.program(33, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.bidding : stack1)) != null ? stack1.bids_count : stack1), {"name":"if","hash":{},"fn":this.program(35, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers.unless.call(depth0, ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.sold : stack1), {"name":"unless","hash":{},"fn":this.program(37, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.bidding : stack1)) != null ? stack1.bids_count : stack1), {"name":"if","hash":{},"fn":this.program(42, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "						<a href=\"\"\n							class=\"hidden-xs hidden-sm hidden-md hidden-lg message-button btn-plick btn-flat btn-white\"><span\n								class=\"icon ion-ios-chatbubble-outline\"></span><span class=\"caption\"> Ställ en fråga via\n								mail</span></a>\n					</div>\n					<h2 class=\"caps desc-title\">"
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.title : stack1), {"name":"sanitizeHTML","hash":{},"data":data})))
    + " </h2>\n					<div class=\"description-container text-color-light\">\n						<p class=\"\">"
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.description : stack1), {"name":"sanitizeHTML","hash":{},"data":data})))
    + "</p>\n					</div>\n					<p class=\"tags-container\">\n						<span class=\"tags\">\n";
  stack1 = helpers.each.call(depth0, ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.tags : stack1), {"name":"each","hash":{},"fn":this.program(44, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "						</span>\n					</p>\n					<p class=\"small-title\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Details", "words", {"name":"translate","hash":{},"data":data})))
    + "</p>\n					<div class=\"more-info-table\">\n						<table>\n							<tr>\n								<td class=\"\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Price", "item", {"name":"translate","hash":{},"data":data})))
    + "</td>\n								<td class=\"align-right\">"
    + escapeExpression(((helpers.parsePrice || (depth0 && depth0.parsePrice) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.price : stack1), ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.currency : stack1), {"name":"parsePrice","hash":{},"data":data})))
    + "</td>\n							</tr>\n							<tr>\n								<td class=\"\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Size", "item", {"name":"translate","hash":{},"data":data})))
    + "</td>\n								<td class=\"align-right\">"
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.size : stack1), {"name":"sanitizeHTML","hash":{},"data":data})))
    + "</td>\n							</tr>\n							<tr>\n								<td class=\"\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Category", "item", {"name":"translate","hash":{},"data":data})))
    + "</td>\n								<td class=\"align-right\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.category : stack1)) != null ? stack1.slug : stack1), depth0))
    + "</td>\n							</tr>\n";
  stack1 = ((helpers.filterEmptyDescription || (depth0 && depth0.filterEmptyDescription) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.brand : stack1)) != null ? stack1.slug : stack1), {"name":"filterEmptyDescription","hash":{},"fn":this.program(46, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.sold : stack1), {"name":"if","hash":{},"fn":this.program(48, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "						</table>\n					</div>\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.information_boxes : stack1), {"name":"if","hash":{},"fn":this.program(50, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(require("./share_container.hbs"), '					', 'share_container', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "				</div>\n			</div>\n		</div>\n	</div>\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.related : stack1)) != null ? stack1.length : stack1), {"name":"if","hash":{},"fn":this.program(53, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"12":function(depth0,helpers,partials,data) {
  return "					<div class=\"overlay\"></div>\n";
  },"14":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "					<div class=\"sold-overlay vertical-align-outer text-center\">\n						<div class=\"vertical-align-inner\">\n							<p class=\"sold-text brown white-text\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "SOLD", "item", {"name":"translate","hash":{},"data":data})))
    + "</p>\n						</div>\n					</div>\n";
},"16":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "								<img height='100%' width='100%' class=\"profile-image circle\" src='"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.user : stack1)) != null ? stack1.user_photo : stack1), depth0))
    + "'>\n";
},"18":function(depth0,helpers,partials,data) {
  return "								<img height='100%' width='100%' class=\"profile-image circle\" src=\"/images/profilbild.png\">\n";
  },"20":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return " "
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.user : stack1)) != null ? stack1.location : stack1), {"name":"sanitizeHTML","hash":{},"data":data})))
    + ",\n										";
},"22":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "									<div class=\"user-rating\">\n										<p>"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.user : stack1)) != null ? stack1['user-rating'] : stack1), depth0))
    + "/5</p>\n									</div>\n";
},"24":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "							<a href=\"\" class=\"info-link triggers-login follow-button is-following\"><span\n									class=\"icon ion-pin\"></span><span\n									class=\"info-link-title\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Following", "words", {"name":"translate","hash":{},"data":data})))
    + "</span></a>\n";
},"26":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "							<a href=\"\" class=\"info-link triggers-login follow-button\"><span\n									class=\"icon ion-pin\"></span><span\n									class=\"info-link-title\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Follow", "words", {"name":"translate","hash":{},"data":data})))
    + "</span></a>\n";
},"28":function(depth0,helpers,partials,data) {
  return "-outline";
  },"30":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = "							<div class=\"bidding-container\">\n								<p class=\"small-title\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Bidding", "item", {"name":"translate","hash":{},"data":data})))
    + "</p>\n								<div class=\"bidding-table\">\n									<table>\n";
  stack1 = ((helpers.ifCond || (depth0 && depth0.ifCond) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.bidding : stack1)) != null ? stack1.bids_count : stack1), ">", 0, {"name":"ifCond","hash":{},"fn":this.program(31, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "										<tr>\n											<td class=\"\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Number of bids", "item", {"name":"translate","hash":{},"data":data})))
    + "</td>\n											<td class=\"align-right\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.bidding : stack1)) != null ? stack1.bids_count : stack1), depth0))
    + "</td>\n										</tr>\n										<tr>\n											<td class=\"\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Starting price", "item", {"name":"translate","hash":{},"data":data})))
    + "</td>\n											<td class=\"align-right\">"
    + escapeExpression(((helpers.parsePrice || (depth0 && depth0.parsePrice) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.bidding : stack1)) != null ? stack1.price : stack1), ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.currency : stack1), {"name":"parsePrice","hash":{},"data":data})))
    + "</td>\n										</tr>\n										<tr>\n											<td class=\"\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Ending", "item", {"name":"translate","hash":{},"data":data})))
    + "</td>\n											<td class=\"align-right\">"
    + escapeExpression(((helpers.prettifyDateTime || (depth0 && depth0.prettifyDateTime) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.bidding : stack1)) != null ? stack1.expires_at : stack1), {"name":"prettifyDateTime","hash":{},"data":data})))
    + "</td>\n										</tr>\n									</table>\n								</div>\n							</div>\n";
},"31":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "										<tr>\n											<td class=\"\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Leading bid", "item", {"name":"translate","hash":{},"data":data})))
    + "</td>\n											<td class=\"align-right\">"
    + escapeExpression(((helpers.parsePrice || (depth0 && depth0.parsePrice) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.bidding : stack1)) != null ? stack1.top_bid_amount : stack1), ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.currency : stack1), {"name":"parsePrice","hash":{},"data":data})))
    + "</td>\n										</tr>\n";
},"33":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "							<p class=\"ad-price\">"
    + escapeExpression(((helpers.parsePrice || (depth0 && depth0.parsePrice) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.price : stack1), ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.currency : stack1), {"name":"parsePrice","hash":{},"data":data})))
    + "</p>\n";
},"35":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "							<div class=\"flex\">\n							<a id=\"see-bids-button\" class=\"btn-plick btn-flat show-get-the-app place-bid-button\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "See bids", "item", {"name":"translate","hash":{},"data":data})))
    + "</a>\n";
},"37":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.web_checkout_url : stack1), {"name":"if","hash":{},"fn":this.program(38, data),"inverse":this.program(40, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"38":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing;
  return "								<a href="
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.web_checkout_url : stack1), depth0))
    + " class=\"buy-button btn-plick btn-flat\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Buy Now", "item", {"name":"translate","hash":{},"data":data})))
    + "</a>\n								<div class=\"payment-information\">\n									<span class=\"payment-options\">\n										<img src=\"https://cdn.plick.es/misc/images/swish-logo-secondary.svg\" alt=\"Swish\" class=\"swish-logo\">\n									</span>\n								</div>\n";
},"40":function(depth0,helpers,partials,data) {
  return "								<a class=\"btn-plick btn-flat triggers-login contact-button hide-for-logged-in\">Kontakta</a>\n								<a class=\"contact-button btn-plick btn-flat requires-login show-for-logged-in\"><i class=\"ion-chatbubbles\"></i>Kontakta</a>\n";
  },"42":function(depth0,helpers,partials,data) {
  return "							</div>\n";
  },"44":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "									<a href=\""
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, (depth0 != null ? depth0.path : depth0), {"name":"sanitizeHTML","hash":{},"data":data})))
    + "\" class=\"tag\">"
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, (depth0 != null ? depth0.name : depth0), {"name":"sanitizeHTML","hash":{},"data":data})))
    + "</a>\n";
},"46":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "							<tr>\n								<td class=\"\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Brand", "item", {"name":"translate","hash":{},"data":data})))
    + "</td>\n								<td class=\"align-right\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.brand : stack1)) != null ? stack1.slug : stack1), depth0))
    + "</td>\n							</tr>\n";
},"48":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "							<tr>\n								<td class=\"\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Status", "item", {"name":"translate","hash":{},"data":data})))
    + "</td>\n								<td class=\"align-right\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Sold", "item", {"name":"translate","hash":{},"data":data})))
    + "</td>\n							</tr>\n";
},"50":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "					<p class=\"small-title\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Shipping options", "words", {"name":"translate","hash":{},"data":data})))
    + "</p>\n					<div class=\"info-boxes-container\">\n";
  stack1 = helpers.each.call(depth0, ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.information_boxes : stack1), {"name":"each","hash":{},"fn":this.program(51, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "					</div>\n";
},"51":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "							<div class=\"info-boxes\">\n								<div class=\"info-boxes-icon\">\n									<p>✅</p>\n								</div>\n								<div class=\"info-boxes-content\">\n									<p class=\"info-boxes-title\">"
    + escapeExpression(lambda((depth0 != null ? depth0.title : depth0), depth0))
    + "</p>\n									<p class=\"info-boxes-description\">"
    + escapeExpression(lambda((depth0 != null ? depth0.description : depth0), depth0))
    + "</p>\n								</div>\n							</div>\n";
},"53":function(depth0,helpers,partials,data) {
  var stack1, buffer = "		<div class=\"additional-ads related text-center\">\n			<h2 class=\"more-ads\">\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.category : stack1), {"name":"if","hash":{},"fn":this.program(54, data),"inverse":this.program(56, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "			</h2>\n\n			<div class=\"ads\">\n";
  stack1 = helpers.each.call(depth0, ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.related : stack1), {"name":"each","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "			</div>\n		</div>\n";
},"54":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "				"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "More", "item", {"name":"translate","hash":{},"data":data})))
    + " "
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.category : stack1)) != null ? stack1.name : stack1), "item", {"name":"translate","hash":{},"data":data})))
    + "\n";
},"56":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "				"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Related Items", "item", {"name":"translate","hash":{},"data":data})))
    + "\n";
},"58":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"additional-ads similar text-center\">\n	<h2 class=\"more-ads\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Other People Also Looked At", "item", {"name":"translate","hash":{},"data":data})))
    + "</h2>\n	<div class=\"ads\">\n		<div class=\"container main-wrapper\">\n			<div class=\"row\">\n";
  stack1 = helpers.each.call(depth0, ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.similar : stack1), {"name":"each","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "			</div>\n		</div>\n	</div>\n</div>\n";
},"60":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = "<div class=\"download-app bg-yellow\">\n	<div class=\"col-sm-12 text-container flex-center\">\n		<h2 class=\"big-title\">"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</h2>\n		<h3>"
    + escapeExpression(((helper = (helper = helpers.subtitle || (depth0 != null ? depth0.subtitle : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"subtitle","hash":{},"data":data}) : helper)))
    + "</h3>\n		<div class=\"download-buttons\">\n			<a href=\"https://itunes.apple.com/app/apple-store/id640623690?pt=1472900&ct=Marketplace&mt=8\"\n				onclick=\"window._tracking.trackAppstoreClick('iTunes', '"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.currency : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.hash_id : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.price : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.category : stack1)) != null ? stack1.name : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1['brand?'] : stack1)) != null ? stack1.name : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.title : stack1), depth0))
    + "')\"\n				target=\"_blank\" class=\"app-store-link\">\n				<img class=\"text-center app-store-image itunes\" src=\"/images/appstore.svg\" loading=\"lazy\">\n			</a>\n			<a href=\"https://play.google.com/store/apps/details?id=swace.se.plick\" target=\"_blank\"\n				onclick=\"window._tracking.trackAppstoreClick('Google Play', '"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.currency : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.hash_id : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.price : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.category : stack1)) != null ? stack1.name : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1['brand?'] : stack1)) != null ? stack1.name : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.title : stack1), depth0))
    + "')\"\n				class=\"app-store-link\">\n				<img alt=\"Android app on Google Play\" class=\"google-play-image\" src=\"/images/googleplay.png\"\n					target=\"_blank\" loading=\"lazy\"/>\n			</a>\n		</div>\n		<a class=\"register-button btn-plick btn-flat triggers-login\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Register/Log in", "menu", {"name":"translate","hash":{},"data":data})))
    + "</a>\n	</div>\n</div>\n\n<div class=\"guide\">\n	<h2 class=\"big-title\">"
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, (depth0 != null ? depth0.guide_title : depth0), {"name":"sanitizeHTML","hash":{},"data":data})))
    + "</h2>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.guide_column : depth0), {"name":"each","hash":{},"fn":this.program(61, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n\n";
},"61":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "	<div class=\"col-sm-"
    + escapeExpression(((helper = (helper = helpers.width_desktop || (depth0 != null ? depth0.width_desktop : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"width_desktop","hash":{},"data":data}) : helper)))
    + " col-xs-"
    + escapeExpression(((helper = (helper = helpers.width_phone || (depth0 != null ? depth0.width_phone : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"width_phone","hash":{},"data":data}) : helper)))
    + " column\">\n		<div class=\"guide-header\">\n			<h2>"
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, (depth0 != null ? depth0.title : depth0), {"name":"sanitizeHTML","hash":{},"data":data})))
    + "</h2>\n			<p>"
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, (depth0 != null ? depth0.description : depth0), {"name":"sanitizeHTML","hash":{},"data":data})))
    + "</p>\n		</div>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.steps : depth0), {"name":"each","hash":{},"fn":this.program(62, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "	</div>\n";
},"62":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = "		<div class=\"step\">\n			<img src=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.image : depth0)) != null ? stack1.url : stack1), depth0))
    + "\" loading=\"lazy\">\n			<p class=\"step-title\">";
  stack1 = ((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, (depth0 != null ? depth0.title : depth0), {"name":"sanitizeHTML","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</p>\n			<p class=\"step-description\">"
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, (depth0 != null ? depth0.description : depth0), {"name":"sanitizeHTML","hash":{},"data":data})))
    + "</p>\n		</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = helpers.unless.call(depth0, ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.removed : stack1), {"name":"unless","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.shouldReferToRelated || (depth0 && depth0.shouldReferToRelated) || helperMissing).call(depth0, (depth0 != null ? depth0.ad : depth0), {"name":"shouldReferToRelated","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers.unless.call(depth0, ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.removed : stack1), {"name":"unless","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.similar : stack1)) != null ? stack1.length : stack1), {"name":"if","hash":{},"fn":this.program(58, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.acf_options || (depth0 && depth0.acf_options) || helperMissing).call(depth0, depth0, {"name":"acf_options","hash":{},"fn":this.program(60, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});