var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  return "row-layout";
  },"3":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <h2 class=\"big-title\">"
    + escapeExpression(((helper = (helper = helpers.guide_title || (depth0 != null ? depth0.guide_title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"guide_title","hash":{},"data":data}) : helper)))
    + "</h2>\n";
},"5":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    <div class=\"col-sm-"
    + escapeExpression(((helper = (helper = helpers.width_desktop || (depth0 != null ? depth0.width_desktop : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"width_desktop","hash":{},"data":data}) : helper)))
    + " col-xs-"
    + escapeExpression(((helper = (helper = helpers.width_phone || (depth0 != null ? depth0.width_phone : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"width_phone","hash":{},"data":data}) : helper)))
    + " column\">\n";
  stack1 = ((helpers.filterEmptyDescription || (depth0 && depth0.filterEmptyDescription) || helperMissing).call(depth0, (depth0 != null ? depth0.title : depth0), {"name":"filterEmptyDescription","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.steps : depth0), {"name":"each","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </div>\n";
},"6":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <div class=\"guide-header\">\n                <h2>"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</h2>\n                <p>"
    + escapeExpression(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"description","hash":{},"data":data}) : helper)))
    + "</p>\n            </div>\n";
},"8":function(depth0,helpers,partials,data) {
  var stack1, helper, lambda=this.lambda, escapeExpression=this.escapeExpression, functionType="function", helperMissing=helpers.helperMissing, buffer = "        <div class=\"step\">\n            <img src=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.image : depth0)) != null ? stack1.url : stack1), depth0))
    + "\" loading=\"lazy\">\n            <p class=\"step-title\">";
  stack1 = ((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</p>\n            <p class=\"step-description\">"
    + escapeExpression(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"description","hash":{},"data":data}) : helper)))
    + "</p>\n        </div>\n";
},"10":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "    <div class=\"download-buttons\">\n        <a href=\"https://itunes.apple.com/app/apple-store/id640623690?pt=1472900&ct=Marketplace&mt=8\"\n            onclick=\"window._tracking.trackAppstoreClick('iTunes', '"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.currency : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.hash_id : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.price : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.category : stack1)) != null ? stack1.name : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1['brand?'] : stack1)) != null ? stack1.name : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.title : stack1), depth0))
    + "')\"\n            target=\"_blank\" class=\"app-store-link\">\n            <img class=\"text-center app-store-image itunes\" src=\"/images/appstore.svg\" loading=\"lazy\">\n        </a>\n        <a href=\"https://play.google.com/store/apps/details?id=swace.se.plick\" target=\"_blank\"\n            onclick=\"window._tracking.trackAppstoreClick('Google Play', '"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.currency : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.hash_id : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.price : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.category : stack1)) != null ? stack1.name : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1['brand?'] : stack1)) != null ? stack1.name : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.title : stack1), depth0))
    + "')\"\n            class=\"app-store-link\">\n            <img alt=\"Android app on Google Play\" class=\"google-play-image\" src=\"/images/googleplay.png\"\n                target=\"_blank\" loading=\"lazy\"/>\n        </a>\n    </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "<div class=\"guide ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.convert_to_row : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n";
  stack1 = ((helpers.filterEmptyDescription || (depth0 && depth0.filterEmptyDescription) || helperMissing).call(depth0, (depth0 != null ? depth0.guide_title : depth0), {"name":"filterEmptyDescription","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.guide_column : depth0), {"name":"each","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.download_app_buttons : depth0), {"name":"if","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});