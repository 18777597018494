var Backbone = require('backbone');
var $ = require("jquery");
var helpers = require('../../config/helpers');
var Events = require("../events");


var template = require("../templates/login.hbs");

module.exports = Backbone.View.extend({
    tagName: "div",
    className: "login-view",
    events: {
        'submit #login-form': "login",
        'click .login-with-facebook': "fbLoginClick",
        'submit #registration-form': "register",
        'click .toggle-auth-view': "toggleAuthView"
    },
    attributes: function() {
        return { id: "login-view" };
    },
    authFailed: function() {
        this.messagePrompt("Dina uppgifter stämmer inte :(")
    },
    toggleAuthView: function(e) {
        e.preventDefault();
        this.$el.find('.login-wrapper').toggleClass('register-view');
    },
    fbLoginClick: function(e) {
        var self = this;
        var prompt = true;
        e.preventDefault();
        if (window.FB) {
            window.FB.getLoginStatus(fbLoginStateUpdate);
        }
        var buttonType = "facebook";
        if ($(e.target).parents('.signup-part').length) buttonType += "-signup";
        if ($(e.target).parents('.login-part').length) buttonType += "-login";

        function fbLoginStateUpdate(response) {
            if (response.status === "connected") {
                console.log("Connected to facebook, sending auth token to Plick");
                $.ajax({
                    url: '/auth/facebook',
                    type: 'POST',
                    data: { token: response.authResponse.accessToken },
                    success: function(resp) {
                        if (resp.ok) {
                            self.loggedIn(resp.user, resp.token, buttonType);
                        }
                    }
                }).error(function(error, string, errorstring) {
                    if (error.status === 401) {
                        self.authFailed();
                    }
                });
            } else if (prompt) {
                prompt = false;
                window.FB.login(fbLoginStateUpdate);
            }
        }
    },
    viewClick: function(e) {
        const $target = $(e.target);
        if ($target.is('.login-view') || $target.is('.login-wrapper')) {
            $(document.body).removeClass('showing-login');
        }
    },
    messagePrompt: function(message) {
        var msg = this.msg;
        if (msg && message) {
            msg.text(message);
            helpers.transform(msg, 'scale(1.1)');
            setTimeout(function() {
                helpers.transform(msg, 'scale(1.0)');
                setTimeout(function() {
                    msg.text("");
                }, 4000);
            }, 2000);
        }
    },
    register: function(e) {
        e.preventDefault();
        var self = this;
        var name = this.$el.find('input#reg-name').val();
        var email = this.$el.find('input#reg-email').val();
        var password = this.$el.find('input#reg-password').val();
        var repeat = this.$el.find('input#repeat-reg-password').val();
        if (!name) return this.messagePrompt('Du behöver ett användarnamn :)');
        if (helpers.validateEmail(email)) {
            if (password === repeat) {
                $.ajax({
                    url: '/auth/register',
                    type: 'POST',
                    data: { email: email, password: password, name: name },
                    success: function(resp) {
                        if (resp.ok) {
                            window._tracking.registration("plick");
                            self.loggedIn(resp.user, resp.token, "plick");
                        }
                    }
                }).error(function(error, string, errorstring) {
                    if (error.status === 400) {
                        self.messagePrompt(error.responseJSON.message);
                    }
                });
            } else {
                self.messagePrompt('Lösenorden är olika :(');
            }
        } else {
            self.messagePrompt('Din email verkar felstavad');
        }
    },
    login: function(e) {
        e.preventDefault();
        var self = this;
        var email = this.$el.find('input#email').val();
        var password = this.$el.find('input#password').val();
        if (helpers.validateEmail(email)) {
            $.ajax({
                url: '/auth/login',
                type: 'POST',
                data: { email: email, password: password },
                success: function(resp) {
                    if (resp.user) {
                        self.loggedIn(resp.user, resp.token, "plick");
                    } else if (resp.message) {
                        self.messagePrompt(resp.message);
                    } else {
                        self.messagePrompt("Någonting gick fel :(");
                    }
                }
            }).error(function(error, string, errorstring) {
                if (error.status === 401) {
                    self.messagePrompt("Fel användarnamn eller lösenord :(");
                } else {
                    self.messagePrompt("Någonting gick fel :(");
                }
            });
        } else {
            this.messagePrompt('Din email verkar felstavad');
        }
    },
    show: function() {
        var self = this;
        setTimeout(function() { $(document.body).addClass('showing-login'); }, 300);
    },
    finish: function() {
        var self = this;
        self.$el.find('#login-form').addClass('disappear');
        setTimeout(function() {
            $(document.body).removeClass('showing-login');
            setTimeout(function() {
                self.remove();
            }, 400);
        }, 400);
    },
    initialize: function() {
        // bind to window
        //this.listenTo(this.model, "change", this.render);
        // this.model.fetchAds();
    },
    loggedIn: function(user, token, loginType) {
        window._SESSION = { current_user: user, token: token };
        window.current_user = user;
        Events.trigger('router:loggedIn', loginType);
        if (Backbone.history.getFragment() === 'login') Backbone.history.navigate('/', true);
    },
    render: function() {
        var self = this;
        this.$el.html(template());
        this.msg = this.$el.find('.msg-box p');
        this.$el.click(self.viewClick);
        return this;
    }
});