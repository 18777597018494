var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<div class=\"login-bg\"></div>\n<div class=\"login-wrapper\">\n	<div class=\"flipper\">\n	    <form id=\"login-form\" class=\"login-form drop-shadow front\">\n	    	<button type=\"submit\" class=\"hidden\"></button>\n	    	<div class=\"login-form-overlay white-text resist-sans\">\n	    		<div class=\"signup-part\">\n			    	<h2 class=\"login-title\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Create Account", "login", {"name":"translate","hash":{},"data":data})))
    + "</h2>\n			    	<button type=\"button\" class=\"login-with-facebook\"><img class=\"fb-logo\" src=\"/images/fb_logo.png\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Register with Facebook", "login", {"name":"translate","hash":{},"data":data})))
    + "</button>\n			    	<p class=\"black-text\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "or", "words", {"name":"translate","hash":{},"data":data})))
    + "</p>\n			        <button type=\"button\" id=\"goto-register-account\" class=\"plick-btn green toggle-auth-view\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Register Without Facebook", "login", "login", {"name":"translate","hash":{},"data":data})))
    + "</button>\n		        <p class=\"smaller black-text\"><br>"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "By registering an account, I accept", "login", {"name":"translate","hash":{},"data":data})))
    + " <a class=\"text-underline\" href=\"/terms\" data-navigate=\"true\" target=\"_blank\"><br>"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Plick's Terms & Condition", "login", {"name":"translate","hash":{},"data":data})))
    + "</a></p>\n	    		</div>\n	    		<div class=\"part-separator\">\n	    			<div class=\"separating-line\"></div>\n	    		</div>\n	    		<div class=\"login-part\">\n			    	<h2 class=\"login-title\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Sign In", "login", {"name":"translate","hash":{},"data":data})))
    + "</h2>\n			    	<button type=\"button\" class=\"login-with-facebook\"><img class=\"fb-logo\" src=\"/images/fb_logo.png\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Continue with Facebook", "login", {"name":"translate","hash":{},"data":data})))
    + "</button>\n			    	<p class=\"black-text\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "or", "words", {"name":"translate","hash":{},"data":data})))
    + "</p>\n			        <input type=\"text\" id=\"email\" name=\"email\" placeholder=\"Email\">\n			        <input type=\"password\" id=\"password\" name=\"password\" placeholder=\""
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Password", "login", {"name":"translate","hash":{},"data":data})))
    + "\">\n			        <button type=\"submit\" class=\"plick-btn\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Sign In", "login", {"name":"translate","hash":{},"data":data})))
    + "</button>\n		        	<p class=\"black-text\"><br><a class=\"text-underline\" href=\"/password/reset\" data-navigate=\"true\" target=\"_blank\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Forgotten password", "login", {"name":"translate","hash":{},"data":data})))
    + "</a></p>\n					<div class=\"msg-box\">\n						<p></p>\n					</div>\n				</div>\n	    	</div>\n			<div class=\"plick-badge-wrapper\">\n				<img class=\"plick-badge\" src=\"/images/plick-badge.png\">\n			</div>\n	    </form>\n	    <form id=\"registration-form\" class=\"registration-form drop-shadow back\">\n	    	<div class=\"login-form-overlay resist-sans\">\n		    	<div class=\"login-part\">\n		    		<button class=\"back-to-login toggle-auth-view\" type=\"button\"><span class=\"ion-reply\"></span></button>\n			    	<h2 class=\"login-title\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Join Plick!", "login", {"name":"translate","hash":{},"data":data})))
    + "</h2>\n			        <input type=\"text\" id=\"reg-name\" name=\"name\" placeholder=\""
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Username", "login", {"name":"translate","hash":{},"data":data})))
    + "\">\n			        <input type=\"text\" id=\"reg-email\" name=\"email\" placeholder=\""
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Email", "login", {"name":"translate","hash":{},"data":data})))
    + "\">\n			        <input type=\"password\" id=\"reg-password\" name=\"password\" placeholder=\""
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Password", "login", {"name":"translate","hash":{},"data":data})))
    + "\">\n			        <input type=\"password\" id=\"repeat-reg-password\" name=\"repeat-password\" placeholder=\""
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Repeat Password", "login", {"name":"translate","hash":{},"data":data})))
    + "\">\n			        <p class=\"smaller\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "By registering an account, I accept", "login", {"name":"translate","hash":{},"data":data})))
    + " <a class=\"text-underline\" href=\"/terms\" data-navigate=\"true\" target=\"_blank\"><br>"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Plick's Terms & Condition", "login", {"name":"translate","hash":{},"data":data})))
    + "</a></p>\n			        <button type=\"submit\" class=\"plick-btn\">"
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, "Registrera", "login", {"name":"translate","hash":{},"data":data})))
    + "</button>\n					<div class=\"msg-box\">\n						<p></p>\n					</div>\n				</div>\n			</div>\n	    </form>\n    </div>\n</div>";
},"useData":true});