var Backbone = require('backbone');
var Events = require("../events");
var $ = require("jquery");
var ConversationView = require('../views/conversation');
var Message = require('../models/message');
var MessageCollection = require('../collections/messages');

module.exports =  Backbone.Model.extend({
    idAttribute: "hash_id",
    initialize: function() {
        this.messages = new MessageCollection();
        this.initItem();
    },
    url: function() {
        var url = "/api/conversations/" + this.get('hash_id');
        return url;
    },
    parse: function(resp, xhr) {
        if (resp.conversation) {
            this.parseMessages(resp.conversation.messages);
        }
        return resp.conversation || resp;
    },
    parseMessages: function(messages) {
        for (let i in messages) {
            if (!this.messages.get(messages[i].id)) {
                var model = this.parseMessage(messages[i]);
                this.messages.add(model);
            }
        }
        this.messages.trigger('change');
    },
    getAd: function() {
        return this.ad;
    },
    notMe: function() {
        var User = require('./user');
        if (this.get('seller').hash_id === window.current_user.hash_id) return new User(this.get('buyer'));
        if (this.get('buyer').hash_id === window.current_user.hash_id) return new User(this.get('seller'));
        return new User(this.get('users')[0]);
    },
    addMessage: function(message) {
        if (!this.messages.get(message.id)) {
            var model = this.parseMessage(message);
            this.messages.add(model);
            this.messages.trigger('change');
        }
    },
    initItem: function() {
        var Ad = require('./ad');
        if (this.attributes.ad) {
            this.ad = window._cache.items[this.attributes.ad.hash_id] || new Ad(this.attributes.ad);
        }
    },
    touch: function() {
        if (this.attributes.unread_count) {
            window._chat_sockets.touchConversation(this.id);
            this.attributes.unread_count = 0;
            this.trigger('change');
            console.log("Conversation touched!");
        }
    },
    created: function(conversation) {
        this.attributes = conversation;
        this.addMessage(conversation.latest_message);
        this.initItem();
        window._cache.conversations[this.id] = this;
        console.log("Conversation " + this.id + " saved in cache");
        Events.trigger('conversation:new:created', window._cache.conversations[this.id]);
    },
    update_attributes: function(c) {
        this.attributes.updated_at = c.updated_at;
        this.attributes.unread_count = c.unread_count;
        this.attributes.latest_message = c.latest_message;
        this.attributes.purchased = c.purchased;
        if (c.pushedMessage) this.addMessage(c.pushedMessage);
        this.addMessage(c.latest_message);
        if (!this.attributes.messages)  {
            this.attributes.messages = c.messages;
            this.parseMessages();
        }
        this.trigger('change');
    },
    renderAndFetch: function(next) {
        var self = this;
        var id = this.get("hash_id");
        if (!window._cache.views.conversations[id]) {
            window._cache.views.conversations[id] = new ConversationView({ model: this });
        }
        $(document.body).find('.single-conversation').remove();
        $(document.body).append(window._cache.views.conversations[id].render().el);
        window._cache.views.conversations[id].renderMessages().scrollToBottom(true).delegateEvents();
        setTimeout(function() {
            window._cache.views.conversations[id].focus();
        }, 500);
        // Events.trigger("router:navigate", "conversations/" + id, {trigger:false});
        this.fetch({
            success: function(ads) {
                self.trigger('change');
            }
        });
    },
    parseMessage: function(message) {
        var model = new Message(message);
        if (message.user.hash_id === this.attributes.seller.hash_id) {
            model.attributes.user = this.attributes.seller;
        } else {
            model.attributes.user = this.attributes.buyer;
        }
        return model;
    }
});