var Backbone = require('backbone');
var $ = require("jquery");
var Events = require("../events");
var helpers = require("../../config/helpers");
var Handlebars = require('hbsfy/runtime');
Backbone.$ = $;

var template = require("../templates/message.hbs");

module.exports = Backbone.View.extend({
  tagName: "div",
  className: "message",
  events: {
    "click .message-image" : "imageClick",
  },
  attributes : function () {
    var id = this.model.id;
    return { id : id };
  },
  initialize: function () {
    this.initTemplate();
    if (this.model.attributes.user.hash_id === window.current_user.hash_id) {
      this.outbound = true;
      this.$el.addClass('outbound');
    }
  },
  imageClick: function(e) {
    e.preventDefault();
    var $target = $(e.currentTarget);
    var $overlay = $('<div class="dark-image-overlay new-vertical-center"><img class="overlay-image new-vertical-center-inner" src="' + $target.attr('src') + '"/></div>');
    $(document.body).append($overlay);
    $overlay.click(function(event) {
      $overlay.remove();
    });
  },
  initTemplate: function () {
    var hbsConfig = require('../../config/handlebars');
    hbsConfig.generic(Handlebars);
  },
  userView: function (e) {
    e.preventDefault();
    var user = new User(this.model.attributes.user);
    user.renderAndFetch();
  },
  detailedView: function (e) {
    e.preventDefault();
    this.model.renderAndFetch();
    // var id = this.model.get("hash_id");
    // Events.trigger("router:navigate", "items/" + id);
  },
  renderTimestamp: function() {
    var timeString = helpers.timeAgo(this.model.attributes.created_at);
    this.$el.find('.message-time-ago').text(timeString);
  },
  render: function() {
    var self = this;
    if (!this.$el.children().length) this.$el.html(template({message : this.model.attributes}))
    if (this.outbound) this.$el.addClass('outbound');
    return this;
  },
});