var Backbone = require('backbone');
var Ad = require('../models/ad');
module.exports = Backbone.Collection.extend({
    model: Ad,
    url: function () {
      var url = "/api/conversations/selling";
      return url;
    },
    page: 1,
    category: 0,
    parse: function(resp, xhr) {
        return resp.ads;
    },
    comparator: function(model) {
        if (model.conversations.length) return -1 * new Date(model.conversations.models[0].get('latest_message').created_at);
        return -1;
    },
    getUnread: function() {
        var unread_count = 0;
        this.each(function(ad) {
            unread_count += ad.getUnread();
        })
        return unread_count;
    }
});