var Backbone = require('backbone');
var $ = require("jquery");
var Events = require("../events");
Backbone.$ = $;
var MessageView = require("./message");
var Message = require('../models/message');

var template = require("../templates/conversation.hbs");

module.exports = Backbone.View.extend({
    tagName: "div",
    className: "single-conversation",
    events: {
        "click        .send-button": "sendMessage",
        "scroll       .messages": "messageScroll",
        'click        .single-conversation-header': "toggleSingleConversation",
        'click        .ion-android-close': "closeConversation",
        'focus        .chat-input': "touchConversation",
        // 'mouseover    .single-conversation'       : "touchConversation", //Funkar ej och behövs ej
        "keydown      .chat-input": "inputKeyPress"
    },
    initialize: function() {
        // this.render();
        var self = this;
        this.listenTo(this.model, "change", this.render);
        this.listenTo(this.model.messages, "change", this.renderMessages);
        if (this.model.messages && !this.model.messages.length) {
            this.model.messages.add(this.model.attributes.latest_message);
        }
        this.rendered = {};
        Events.on("update:timestamps", this.updateTimestamps.bind(this));
    },
    inputKeyPress: function(e) {
        if (e.keyCode == 13 && !e.shiftKey) {
            this.$el.find('.send-button').click();
            e.preventDefault();
        }
    },
    focus: function() {
        this.$input.focus();
        this.$el.removeClass('minified');
        return this;
    },
    touchConversation: function(e) {
        this.model.touch();
    },
    sendMessage: function(e) {
        e.preventDefault();
        var self = this;
        if (self.model.id) {
            var message = self.$input.text();
            self.$input.empty().prop('contenteditable', false);
            $.ajax({
                url: '/api/conversations/' + self.model.id + '/messages',
                type: 'POST',
                contentType: 'application/json',
                data: JSON.stringify({ message: { body: message, message_type: "message" } }),
                success: function(resp) {
                    if (resp.message) {
                        self.$input.prop('contenteditable', true);
                        self.$input.focus();
                        self.model.messages.add(resp.message);
                        self.model.attributes.updated_at = resp.message.created_at;
                        self.model.attributes.latest_message = resp.message;
                        self.model.messages.trigger('change');
                        self.model.trigger('change');
                        window._tracking.conversation("reply");
                    }
                }
            }).error(function(error, string, errorstring) {
                if (error.status === 401) {
                    self.authFailed();
                }
            });
        } else {
            self.createNewConversation();
        }
    },
    createNewConversation: function() {
        var self = this;
        var message = self.$input.text();
        self.$input.empty().prop('contenteditable', false);
        $.ajax({
            url: '/api/items/' + self.model.ad.id + '/conversations',
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify({ message: { body: message, message_type: "message" } }),
            success: function(resp) {
                if (resp.conversation) {
                    self.$input.prop('contenteditable', true);
                    self.$input.focus();
                    self.model.created(resp.conversation);
                    self.pushNewMessage(new Message(self.model.messages.first()));
                    window._tracking.conversation("start");
                }
            }
        }).error(function(error, string, errorstring) {
            if (error.status === 401) {
                self.authFailed();
            } else if (error.status === 403) {
                var text = "För att kunna kontakta med våra användare från webben måste du först verifiera ditt konto genom att logga in i appen 💛";
                $('.get-the-app').find('.get-the-app-content').children('p').text(text);
                $('.get-the-app').addClass('show');
            }
        });
    },
    detailedView: function(e) {
        e.preventDefault();
    },
    getShortcuts: function() {
        this.container = this.$el.find('.messages');
        this.messageBox = this.$el.find('textarea.chat-input');
        this.chatForm = this.$el.find('.chat-form');
    },
    pushNewMessage: function(message) {
        var self = this;
        var scroll = (this.container.get(0).scrollHeight - this.container.height() < this.container.scrollTop() + 40);
        if (!self.rendered[message.id]) {
            var view = new MessageView({ model: message });
            self.container.append(view.render().$el);
            self.rendered[message.id] = view;
            if (message.attributes.user.hash_id !== window.current_user.hash_id) {
                if (self.$input.is(':focus')) {
                    self.model.touch();
                }
            }
        }
        if (scroll) self.scrollToBottom();
    },
    scrollToBottom: function(instant) {
        if (instant) this.container.scrollTop(this.container.get(0).scrollHeight - this.container.height());
        else this.container.animate({ scrollTop: this.container.get(0).scrollHeight - this.container.height() }, 200);
        return this;
    },
    renderMessages: function() {
        var self = this;
        if (this.model.messages) {
            if (Object.keys(this.rendered).length && this.model.messages.length === Object.keys(this.rendered).length + 1) {
                self.pushNewMessage(this.model.messages.last());
            } else {
                this.model.messages.each(function(message) {
                    if (!self.rendered[message.id]) {
                        var view = new MessageView({ model: message });
                        self.container.append(view.render().$el);
                        self.rendered[message.id] = view;
                        // view.$el.find('img').on('load', function(e) {
                        //   self.container.scrollTop(self.container.scrollTop() + $(e.currentTarget).height())
                        // });
                    }
                });
                self.orderMessages();
                self.scrollToBottom(true);
            }
        }
        return this;
    },
    updateTimestamps: function() {
        for (var id in this.rendered) {
            var view = this.rendered[id];
            view.renderTimestamp();
        }
    },
    orderMessages: function() {
        var self = this;
        this.model.messages.each(function(message) {
            self.container.append(self.rendered[message.id].el)
        });
    },
    render: function() {
        var self = this;
        if (!this.$el.children().length) this.$el.html(template());
        self.getShortcuts();
        this.$input = this.$el.find('.chat-input');
        this.$el.find('.name').text(this.model.notMe().get('name'));
        var ad = this.model.ad;
        this.$el.find('.item-link').attr('href', '/' + ad.attributes.category.slug + '/' + ad.attributes.slug);
        this.$el.find('.item-photo').attr('src', ad.attributes.ad_photos[0].thumbnail)
        return this;
    },
    toggleSingleConversation: function(e) {
        $(e.currentTarget).closest('.single-conversation').toggleClass('minified');
    },
    closeConversation: function(e) {
        this.$el.remove();
    }
});