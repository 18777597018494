var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "        <video id=\"video-file\" class=\"video\" controls ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.autoplay : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + " loop playsinline></video>\n        <script>\n             if(window.innerWidth < 767) {\n                 document.getElementById('video-file').src = \""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.video_file_mobile : depth0)) != null ? stack1.url : stack1), depth0))
    + "\"\n             }else{\n                 document.getElementById('video-file').src = \""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.video_file : depth0)) != null ? stack1.url : stack1), depth0))
    + "\"\n             }\n        </script>\n";
},"2":function(depth0,helpers,partials,data) {
  return "autoplay muted ";
  },"4":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "        <iframe id=\"video-link\" class=\"\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>\n        <script>\n             if(window.innerWidth < 767) {\n                 document.getElementById('video-link').src = \""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.video_link_mobile : depth0)) != null ? stack1.url : stack1), depth0))
    + "\"\n             }else{\n                 document.getElementById('video-link').src = \""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.video_link : depth0)) != null ? stack1.url : stack1), depth0))
    + "\"\n             }\n        </script>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "<div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.use_video_file : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.use_video_file : depth0), {"name":"unless","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>";
},"useData":true});