var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, lambda=this.lambda, escapeExpression=this.escapeExpression, functionType="function", helperMissing=helpers.helperMissing;
  return "<div class=\"landing-block-content has-bg-image no-hover app-promote\" style=\"background-image: url("
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.image : depth0)) != null ? stack1.sizes : stack1)) != null ? stack1.large : stack1), depth0))
    + ")\">\n    <div class=\"link-wrapper\">\n    <h2 class=\"text-background\">"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</h2><br />\n    <a href=\"https://itunes.apple.com/app/apple-store/id640623690?pt=1472900&ct=Marketplace&mt=8\" onclick=\"window._tracking.trackAppstoreClick('iTunes', '"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.currency : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.hash_id : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.price : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.category : stack1)) != null ? stack1.name : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1['brand?'] : stack1)) != null ? stack1.name : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.title : stack1), depth0))
    + "')\" target=\"_blank\" class=\"app-store-link\">\n        <img class=\"text-center app-store-image itunes\" src=\"/images/appstore.svg\" loading=\"lazy\">\n    </a><!--\n    --><a href=\"https://play.google.com/store/apps/details?id=swace.se.plick\" target=\"_blank\" onclick=\"window._tracking.trackAppstoreClick('Google Play', '"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.currency : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.hash_id : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.price : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.category : stack1)) != null ? stack1.name : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1['brand?'] : stack1)) != null ? stack1.name : stack1), depth0))
    + "', '"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.ad : depth0)) != null ? stack1.title : stack1), depth0))
    + "')\" class=\"app-store-link\">\n        <img alt=\"Android app on Google Play\" class=\"app-store-image\" src=\"/images/googleplay.png\" target=\"_blank\" loading=\"lazy\"/>\n    </a>\n    </div>\n</div>";
},"useData":true});