var Backbone = require('backbone');
var $ = require("jquery");
var Events = require("../events");
var helpers = require("../../config/helpers");
Backbone.$ = $;

var template = require("../templates/news.hbs");

module.exports = Backbone.View.extend({
    tagName: "div",
    className: "single-news",
    events: {
        // "click .conversation-link": "detailedView",
        // "click .user-link"   : "userView",
        // "click .followee-link": "followeeView",
        // "click .ad-link"   : "adView",
    },
    initialize: function() {
        var self = this;
        // console.log("Listening to event:", "conversation:update:" + this.model.id);
        this.listenTo(this.model, "change", this.render);
    },
    userView: function(e) {
        e.preventDefault();
        $('body').attr('show', '');
        this.model.user.renderAndFetch();
    },
    followeeView: function(e) {
        e.preventDefault();
        this.model.following.renderAndFetch();
    },
    adView: function(e) {
        e.preventDefault();
        this.model.ad.renderAndFetch();
    },
    detailedView: function(e) {
        e.preventDefault();
        this.model.renderAndFetch();
    },
    setUnread: function()  {
        this.$el.attr('unread', this.model.get('unread'));
    },
    render: function() {
        var options = {
            news: this.model.attributes,
            news_message: this.model.getNewsMessage()
        }

        this.$el.html(template(options));
        this.setUnread();
        return this;
    }
});