var Backbone = require('backbone');
var User = require("./user");
var Ad = require("./ad");
var helpers = require("../../config/helpers");

module.exports = Backbone.Model.extend({
	idAttribute: "id",
	initialize: function() {
		if (this.attributes.type === "ad") this.attributes.user = this.attributes.ad.user;
		this.user = window._cache.users[this.attributes.user.hash_id] || new User(this.attributes.user);
		if (this.attributes.type === "like" || this.attributes.type === "ad") {			
			this.ad = window._cache.items[this.attributes.ad.hash_id] || new Ad(this.attributes.ad);
		} else if (this.attributes.type === "follow") {
			this.following = window._cache.users[this.attributes.following.hash_id] || new User(this.attributes.following);
		}
	},
	getNewsMessage: function() {
		var self = this;
		var userName = self.user.get('name');
		if (self.user.id === window._SESSION.current_user.hash_id) userName = "You";
		var message = '<span class="pink-text bold">' + userName + '</span>';
		switch (self.attributes.type) {
		  	case "like":
		  		message += " liked " + '<span class="pink-text bold">'
		  		var targetName = self.ad.user.get('name');
		  		if (self.user.id === window._SESSION.current_user.hash_id && self.user.id === self.ad.user.id) message += "Your own</span>";
		  		else if (self.user.id  === self.ad.user.id) message += "Their own</span>";
			    else  message += targetName + "</span>'s";
			    message += " item";
			    break;
		  	case "recommendation":
			    message += ' Recommended an <span>Item for</span> <span class="pink-text bold">You</span>';
			    break;
		  	case "ad":
		  		message += " posted a new item";
		  		break;
		  	case "follow":
			  	if (self.user.id === window._SESSION.current_user.hash_id) message += " are ";
			  	else message += " is ";
			    message += "following " + '<span class="pink-text bold">';
			    var followed_name = self.following.get('name');
			    if (self.following.hash_id === window._SESSION.current_user.hash_id) followed_name  = "You"
			    message += followed_name + '</span>';
				break;
		  	default:
		    message += " did something!";
		}
		// message = message.replace(window.current_user.name + "'s", "ditt")
		return message;
	},
});