var Backbone = require('backbone');
var Ad = require('../models/ad');
var $ = require("jquery");
module.exports = Backbone.Collection.extend({
    model: Ad,
    initialize: function(models, options) {
        if (options) this.user = options.user;
        if (this.user) {
            this.comparator = function(a, b) {
                if (!this.user) return 0;
                if (a.attributes.sold && !b.attributes.sold) return 1;
                if (!a.attributes.sold && b.attributes.sold) return -1;
                return new Date(a.attributes.created_at) - new Date(a.attributes.created_at);
            }
        }
        this.params = this.params ||  {};
    },
    loadNextPage: function(next) {
        if (!this.done) {
            var self = this;
            this.page = parseInt(this.page);
            this.page += 1;
            this.fetch({
                success: function(ads) {
                    if (ads.length === 0) self.done = true;
                    next(ads);
                }
            });
        }
    },
    initParams: function(url) {
        var self = this;
        this.params = {};
        if (url) this.params = $.deparam(url);
        this.page = this.params.page && parseInt(this.params.page) || 1
        delete this.params.page;
        if (this.params.brand_ids && this.params.brand_ids.length && this.params.brand_ids[0]) {
            window._cache.getBrandsById(function(brandsById) {
                self.brand = brandsById[self.params.brand_ids[0]];
            });
        } else {
            delete this.params.brand_ids;
            delete self.brand;
        }
        if (this.params.category_ids && this.params.category_ids.length) {
            this.category = this.params.category_ids[0];
        }
    },
    addTag: function(tag) {
        this.params.tag = tag;
    },
    addBrand: function(brand_id) {
        this.params.brand_ids = this.params.brand_ids || []
        this.params.brand_ids.push(brand_id);
    },
    addCategory: function(category_id) {
        this.params.category_ids = this.params.category_ids || []
        this.params.category_ids.push(category_id);
    },
    addSubcategory: function(subcategory_id) {
        this.params.subcategory_id = subcategory_id;
    },
    url: function() {
        var url;
        if (this.user) {
            url = "/api/users/" + this.user.get('hash_id') + "/items";
        } else if (this.feed) {
            url = "/api/items/" + this.feed;
        } else {
            url = "/api/items";
        }
        url += '?page=' + this.page + '&' + this.queryString();
        return url;
    },
    queryString: function(without_slug) {
        if (without_slug) {
            var tmp = JSON.parse(JSON.stringify(this.params))
            if (tmp.category_ids && tmp.category_ids.length > 0) tmp.category_ids.shift();
            if (tmp.category_ids && tmp.category_ids.length === 0) delete tmp.category_ids;
            if (tmp.brand_ids && tmp.brand_ids.length > 0) tmp.brand_ids.shift();
            if (tmp.brand_ids && tmp.brand_ids.length === 0) delete tmp.brand_ids;
            return Backbone.$.param(tmp);
        }
        return Backbone.$.param(this.params);
    },
    page: 1,
    category: 0,
    parse: function(resp, xhr) {
        //if (xhr.previousModels) return xhr.previousModels.concat(resp.ads);
        // console.log(resp);
        return resp.ads;
    },
});