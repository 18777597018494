module.exports = {
	SEO_TITLE : "Second Hand på Plick",
	BASE_URL : "https://plick.se",
	MAIL_SHARE_SUBJECT: "Kolla in den här {{TYPE}} på Plick!",
	MAIL_SHARE_BODY: "Hej kompis!%0D%0AKolla in den här {{TYPE}}:%0D%0A{{URL}}",
	SMS_SHARE_BODY: "Kolla in den här {{TYPE}} på Plick!%0D%0A{{URL}}",
	BASE_URL: (country) => {
		var map = {
            "plick.dk": { locale: "DA", country: "DK" },
            "plick.se": { locale: "SV", country: "SE" },
            "plick.fi": { locale: "FI", country: "FI" },
            "localhost": { locale: "SV", country: "SE" }
        }
		return Object.keys(map).find(key => map[key].country === country)
	}
}