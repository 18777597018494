var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "			<h2 class=\"white-text bold resist-sans\">"
    + escapeExpression(((helper = (helper = helpers.tagline || (depth0 != null ? depth0.tagline : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"tagline","hash":{},"data":data}) : helper)))
    + "</h2>\n";
},"3":function(depth0,helpers,partials,data) {
  return "			<h2 class=\"white-text bold resist-sans\">Hur vill du se ut idag?</h2>\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "<div class=\"text-center search-bar\">\n	<div class=\"start-content\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.tagline : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "		<div class=\"search-input-container\">\n			<input id=\"search-input\" name=\"query\" placeholder=\"Sök här\" type=\"text\" autocomplete=\"off\">\n			<div class=\"search-autocomplete-container\">\n			</div>\n		</div>\n	</div>\n</div>";
},"useData":true});