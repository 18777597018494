var helpers = require('../config/helpers.js');
module.exports = function structuredData(context) {
    function baseUrl() {
        if (typeof window !== "undefined") {
            return window.location.origin;
        } else {
            var translations = require('../config/languages/' + context.market.locale + '.js');
            var translate = require('../config/translations.js').new_translate(translations);

            return translate("https://plick.se", "variables");
        }
    }

    var script = '';
    if (context.ad && !context.ad.removed) {
        var brand = "PLICK";
        var stock = "http://schema.org/InStock";
        if (context.ad.sold) stock = "http://schema.org/SoldOut";
        if (context.ad.brand) brand = context.ad.brand.name;
        var sd = {
            "@context": "http://schema.org/",
            "@type": "Product",
            "name": helpers.sanitize(context.ad.title?.trim() || context.ad.description.split('.')[0]),
            "image": context.ad.ad_photos[0] && context.ad.ad_photos[0].photo,
            "description": helpers.sanitize(context.ad.description),
            "mpn": context.ad.hash_id,
            "itemCondition": "http://schema.org/UsedCondition",
            "brand": {
                "@type": "Brand",
                "name": brand
            },
            "offers": {
                "@type": "Offer",
                "priceCurrency": context.ad.currency,
                "price": context.ad.price / 100,
                "priceValidUntil": "2020-11-05",
                "itemCondition": "http://schema.org/UsedCondition",
                "availability": stock,
                "seller": {
                    "@type": "Person",
                    "name": helpers.sanitize(context.ad.user.name)
                },
                "url": helpers.sanitize(baseUrl() + context.ad.category.url + '/' + context.ad.slug),
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": context.ad.user.rating || 4,
                "reviewCount": context.ad.user.rating_count ||  1
            },
            "sku": context.ad.hash_id
        }
        script += '<script id="structured-data" type="application/ld+json">';
        script += JSON.stringify(sd);
        script += '</script>';
    } else if (context.ads) {
        var sd = {
            "@context": "http://schema.org",
            "@type": "WebSite",
            "url": baseUrl(),
            "potentialAction": [{
                "@type": "SearchAction",
                "target": baseUrl() + "/query={search_term_string}",
                "query-input": "required name=search_term_string"
            }]
        }
        script += '<script id="structured-data" type="application/ld+json">';
        script += JSON.stringify(sd);
        script += '</script>';
    } else {
        let foundQuestions = false

        if (Array.isArray(context.blocks) && context.blocks.length > 0) {
            var questions = [];

            function addQuestionFromBlock(block) {
                if (block.acf_fc_layout == 'row') {
                    if (Array.isArray(block.blocks)) {
                        block.blocks.forEach(addQuestionFromBlock);
                    }
                } else if (block.acf_fc_layout == 'faq') {
                    questions.push({
                        '@type': 'Question',
                        'name': block.title,
                        'acceptedAnswer': {
                            '@type': 'Answer',
                            'text': block.content
                        }
                    });
                }
            };

            context.blocks.forEach(addQuestionFromBlock);

            if (questions.length > 0) {
                foundQuestions = true;
                const sd = {
                    "@context": "http://schema.org",
                    "@type": "FAQPage",
                    "mainEntity": questions
                };
                script += '<script id="structured-data" type="application/ld+json">'
                script += JSON.stringify(sd);
                script += '</script>';
            }
        }

        if (!foundQuestions) {
            if (context.path == "/") {
                script = '<script id="structured-data" type="application/ld+json">{"@context":"https://schema.org","@type":"HowTo","name":"Hur funkar Plick?","image":{"@type":"ImageObject","url":"https://s3-eu-central-1.amazonaws.com/plick-stocks/wp-content/uploads/2021/08/19152332/app_preview_web.png","height":"1000","width":"1109"},"tool":{"@type":"HowToTool","name":"Plick Appen"},"step":[{"@type":"HowToSection","name":"Hur köper jag på plick?","text":"Köp din nya outfit på Plick. Tänk på de där skorna eller den där klänningen du alltid drömt om men inte riktigt haft råd med. Börja med att skapa en profil. En sökning bort kanske du hittar drömplagget till en tiondel av priset. Vågar du chansa på att inte skapa ett konto?","itemListElement":[{"@type":"HowToStep","name":"1. Sök","text":"Öppna dörren till 100- tusentals second handplagg"},{"@type":"HowToStep","name":"2. Snacka","text":"Kom överrens med säljaren och plagget kan bli ditt på några sekunder!"},{"@type":"HowToStep","name":"3. Köp!","text":"Köp second hand med Plick"}]},{"@type":"HowToSection","name":"Hur säljer jag med Plick","text":"Sälj dina bortglömda favoriter i appen. Har du tänkt på alla gamla favoritplagg som hamnar längre och längre in i garderoben? Med Plick får du en chans att ge dem nytt liv och dessutom tjänar du en slant på det. Din garderob kan vara en guldgruva för någon annan!","itemListElement":[{"@type":"HowToStep","name":"1. Fota","text":"Fota plagget på dig själv eller häng det på en galge."},{"@type":"HowToStep","name":"2. Tagga","text":"Beskriv ditt plagg och gör det tillgängligt för andra fashionistas"},{"@type":"HowToStep","name":"3. Sälj","text":"Sälj second hand med Plick"}]}]}</script>';
            }
        }
    }

    return script;
}