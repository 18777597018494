var Backbone = require('backbone');
var $ = require("jquery");
var Events = require("../events");

Backbone.$ = $;

module.exports = Backbone.View.extend({
	events: {
  },
  tagName: "button",
  className: "load  white-text",
  initialize: function () {
    var self = this;
    Events.on("loader:start", function(){
      self.startLoading();
    });
    Events.on("loader:stop", function(){
      self.stopLoading();
    });
    this.render();
    this.$el.html("<div class='bounce1'></div><div class='bounce2'></div><div class='bounce3'></div>")
  },
  render: function() {
    if(this.hidden) this.$el.hide();
    return this;
  },
  triggerLoadMore: function(e) {
    e.preventDefault();
    Events.trigger("ads:loadmore");
  },
  startLoading: function() {
    this.$el.css('display','block');
  },
  stopLoading: function() {
    this.$el.css('display','none');

  }
});