var Backbone = require('backbone');
var $ = require("jquery");
var SellingCollection = require('../collections/selling_conversations');
var MiniConversationView = require('../views/conversation_brief');
var template = require("../templates/chat.hbs");
var ConversationListView = require('../views/conversation_list');
var ConversationView = require('../views/conversation');
var Conversation = require('../models/conversation');
var AdConversationListView = require('../views/ad_conversation_list');
var Events = require("../events");

module.exports = Backbone.View.extend({
	tagName: "div",
	className: "conversation-container",
	events : {
		'click .chat-header' : "headerClick",
		'click .conversations-tab-item': "tabClick",
		'click .selling-list-hide': "derenderSellingConversationsList",
		'click .selling-conversations' : "sellingConversationClick",
	},
	headerClick : function() {
		this.$el.toggleClass('open');
	},
	authFailed : function() {
	},
	tabClick: function(e) {
		e.preventDefault();
		$('.conversations-tab-item').removeClass('active');
		$(e.currentTarget).addClass('active');
		this.$el.find('.conversation-list').attr('data-active', $(e.currentTarget).attr('data-toggles'));
		if ($(e.currentTarget).attr('data-toggles') === "selling") this.$el.find('.selling-list-hide').click();
	},
	sellingConversationClick: function(e) {
		var self = this;
		var target = $(e.target);
		if (!target.is('.single-item-conversation-list')) {
			target = target.parents('.single-item-conversation-list');
		}
		if (target.length) {
			var item = self.selling_conversations.get(target.data('item-id'));
			if (!window._cache.views.item_conversations[item.id]) {
				window._cache.views.item_conversations[item.id] = new ConversationListView({conversations: item.conversations});
			}
			self.renderSellingConversationsList(window._cache.views.item_conversations[item.id]);
		}
	},
	initialize: function() {
		this.initConversations();
		var self = this;
	    Events.on("conversation:new", function(ad) {
	    	self.createNewConversation(ad);
	    });
	    Events.on("conversation:new:created", function(conversation) {
	    	self.buying_conversations_view.conversations.add(conversation);
	    	self.buying_conversations_view.conversations.trigger('change');
	    });
	    this.$chatToggle = $('header .menu-item.toggle-chat');
	    this.$notificationTitle = $('<title id="notification_title"></title>')
	    setInterval(function() {
	    	self.toggleNotificationTitle();
	    }, 1500);
	    if (window.outerWidth < 800) {
	    	// Check if we should allow scrolling up or down
			$(this.el).on("touchstart", ".scroll-y", function (e) {
			  // If the element is scrollable (content overflows), then...
			  if (this.scrollHeight !== this.clientHeight) {
			    // If we're at the top, scroll down one pixel to allow scrolling up
			    if (this.scrollTop === 0) {
			      this.scrollTop = 1;
			    }
			    // If we're at the bottom, scroll up one pixel to allow scrolling down
			    if (this.scrollTop === this.scrollHeight - this.clientHeight) {
			      this.scrollTop = this.scrollHeight - this.clientHeight - 1;
			    }
			  }
			  // Check if we can scroll
			  this.allowUp = this.scrollTop > 0;
			  this.allowDown = this.scrollTop < (this.scrollHeight - this.clientHeight);
			  this.lastY = e.originalEvent.pageY;
			});

			$(this.el).on('touchmove', ".scroll-y", function(e) {
			  var event = e.originalEvent;
			  var up = event.pageY > this.lastY;
			  var down = !up;
			  this.lastY = event.pageY;

			  if ((up && this.allowUp) || (down && this.allowDown)) {
			    event.stopPropagation();
			  } else {
			    event.preventDefault();
			  }
			});
	    }
	},
	toggleNotificationTitle: function() {
		var unread = this.selling_conversations.getUnread() + this.buying_conversations_view.getUnread();
		if (unread && !$.contains(document.head, this.$notificationTitle[0])) {
			if (unread === 1) this.$notificationTitle.text( unread + " oläst meddelande!");
			else this.$notificationTitle.text( unread + " olästa meddelanden!");
			$(document.head).prepend(this.$notificationTitle);
		} else {
			this.$notificationTitle.remove();
		}
	},
	createNewConversation: function(ad) {
		var c = new ConversationView({model: new Conversation({ad: ad.attributes, seller: ad.attributes.user, buyer: window._SESSION.current_user})});
	    $(document.body).find('.single-conversation').remove();
    	$(document.body).append(c.render().el);
	},
	initConversations: function() {
		var self = this;
		this.selling_conversations = new SellingCollection();
		this.selling_conversations.fetch({
	        reset: true
	    });
		self.sellingConversationViewMap = {};
	    this.listenTo(this.selling_conversations, "reset", function() {
	    	console.log("Resetting Selling Conversations")
	    	self.selling_conversations.each(function(ad) {
	    		window._cache.items[ad.id] = ad;
	    	});
	    	self.selling_conversations.trigger('change');
	    });
		self.listenTo(this.selling_conversations, "change", self.render);
	    this.buying_conversations_view = new ConversationListView();
	    this.listenTo(this.buying_conversations_view.conversations, "reset", this.countUnread);
	    this.listenTo(this.buying_conversations_view.conversations, "change", this.countUnread);
	},
	countUnread: function() {
		var tabs = this.$el.find('.conversations-tab-item');
		tabs.filter('[data-toggles="selling"]').attr('unread', this.selling_conversations.getUnread());
		tabs.filter('[data-toggles="buying"]').attr('unread', this.buying_conversations_view.getUnread());
		this.$chatToggle.attr('unread', this.selling_conversations.getUnread() + this.buying_conversations_view.getUnread());
		var favicon_url = "/images/plick_favicon.png";
		if (this.selling_conversations.getUnread() + this.buying_conversations_view.getUnread()) favicon_url = "/images/plick_favicon_badged.png";
		$('#favicon').attr('href', favicon_url);
	},
	renderSellingConversationsList: function(cListView) {
		var self = this;
		self.$sellingListView.html(cListView.render().el);
		self.$sellingListView.addClass('active');
		cListView.delegateEvents();
		self.$el.find('.selling-list-header').addClass('active')
	},
	derenderSellingConversationsList: function() {
		var self = this;
		self.$sellingListView.removeClass('active')
		self.$el.find('.selling-list-header').removeClass('active')
	},
	renderSellingConversations: function() {
		var self = this;
		this.selling_conversations.each(function(el) {
			var view = self.sellingConversationViewMap[el.id];
			if (!view) {
				view = new AdConversationListView( {model: el} );
				self.sellingConversationViewMap[el.id] = view;
			}
			self.selling.append(view.render().el);
		});
	},
	updateSellingView: function(notification) {
		console.log(notification);
		console.log(self.sellingConversationViewMap);
	},
	renderBuyingConversationsList: function() {
		console.log("Attempting render of buying conversations")
		var self = this;
		self.$buyingListView.html(this.buying_conversations_view.render().el);
		this.buying_conversations_view.delegateEvents();
	},
	renderBuyingConversations: function() {
	},
    renderConversation: function( conversation ) {
	    var cView = new MiniConversationView({ model: conversation });
	    this.conversationViews.push(cView);
	    this.container.prepend( cView.render().el );
	    this.container.scrollTop(this.container.prop("scrollHeight"));
    },
    renderConversations: function(newConversations) {
    	var self = this;
    	this.container.empty();
    	this.conversations.each(function (conversation) {
    		self.renderConversation(conversation);
    	});
    },
	render: function() {
		var self = this;
		if (!this.$el.children().length) this.$el.html(template());
		this.$sellingListView = this.$el.find('.selling-list-view');
		this.$buyingListView = this.$el.find('.buying-list-view')
		this.selling = this.$el.find('.selling-conversations');
		this.buying = this.$el.find('.buying-conversations');
		this.renderSellingConversations();
		this.renderBuyingConversationsList();
		// if (this.buying.children().length < this.buying_conversations.length) this.renderBuyingConversations();
    	// this.container.scrollTop(this.container.prop("scrollHeight"));
    	this.countUnread();
		return this;
	},
});