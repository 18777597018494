var _ = require("underscore");
var $ = require("jquery");

var CountdownModel = Backbone.Model.extend({
    update: function() {

        this.set({
            days: this.getDays(),
            hours: this.getHours(),
            minutes: this.getMinutes(),
            seconds: this.getSeconds(),
            time: this.getTime()
        });

        return this;
    },
    getDays: function() {
        var days = Math.floor(this.getTime() / (60 * 60 * 24));
        
        return (days > 0) ? days : 0;
    },
    getHours: function() {
        var hours = Math.floor((this.getTime() / (60 * 60)) % 24);
        
        return (hours > 0) ? hours : 0;
    },
    getMinutes: function() {
        var minutes = Math.floor((this.getTime() / 60) % 60);

        return (minutes > 0) ? minutes : 0;
    },
    getSeconds: function() {
        var seconds = Math.floor(this.getTime() % 60);

        return (seconds > 0) ? seconds : 0;
    },
    getTime: function() {
        var timestamp = Date.parse(this.get('timestamp'));
        return (((timestamp) - Date.parse(new Date())) / 1000 | 0);
    }
});

module.exports = Backbone.View.extend({

    el: '.collection-count-down',
    template: _.template('\
        <div class="countdown">\
            <div class="countdown__timer">\
                <div class="countdown__unit countdown__unit--days">\
                    <h3><%= days %></h3>\
                    <span>Days</span>\
                </div>\
                <div class="countdown__unit countdown__unit--hours">\
                    <h3><%= hours %></h3>\
                    <span>Hours</span>\
                </div>\
                <div class="countdown__unit countdown__unit--minutes">\
                    <h3><%= minutes %></h3>\
                    <span>Minutes</span>\
                </div>\
                <div class="countdown__unit countdown__unit--seconds">\
                    <h3><%= seconds %></h3>\
                    <span>Seconds</span>\
                </div>\
            </div>\
        </div>'),
    initialize: function() {
        this.model = new CountdownModel({ timestamp: new Date(this.$el.data('timestamp').replace(' ','T'))});
        this.start();
    },
    render: function() {
        this.model = new CountdownModel({ timestamp: new Date(this.$el.data('timestamp').replace(' ','T'))});
        this.$el.html(this.template(this.model.update().toJSON()));
        return this
    },
    start: function() {
        this.interval = setInterval(function() {
            this.render();

            if (this.model.getTime() <= 0) this.stop();
        }.bind(this), 1000);

        return this;
    },
    stop: function() {
        clearInterval(this.interval);
    }
});