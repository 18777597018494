var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  return "    <meta class=\"meta-data\" name=\"robots\" content=\"noindex\">\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "\n	<title class=\"meta-data\">"
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, (depth0 != null ? depth0.title : depth0), {"name":"sanitizeHTML","hash":{},"data":data})))
    + "</title>\n	<meta class=\"meta-data\" name=\"description\" content=\""
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, (depth0 != null ? depth0.custom_description : depth0), {"name":"sanitizeHTML","hash":{},"data":data})))
    + "\"/>\n  <!-- Open Graph -->\n	<meta class=\"meta-data\" property=\"og:title\" content=\""
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, (depth0 != null ? depth0.social_title : depth0), {"name":"sanitizeHTML","hash":{},"data":data})))
    + "\" />\n	<meta class=\"meta-data\" property=\"og:description\" content=\""
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, (depth0 != null ? depth0.social_description : depth0), {"name":"sanitizeHTML","hash":{},"data":data})))
    + "\"/>\n	<meta class=\"meta-data\" property=\"og:image\" content=\""
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, (depth0 != null ? depth0.og_image : depth0), {"name":"sanitizeHTML","hash":{},"data":data})))
    + "\">\n	<meta class=\"meta-data\" property=\"og:image:url\" content=\""
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, (depth0 != null ? depth0.og_image : depth0), {"name":"sanitizeHTML","hash":{},"data":data})))
    + "\">\n	<meta class=\"meta-data\" property=\"og:image:secure_url\" content=\""
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, (depth0 != null ? depth0.og_image : depth0), {"name":"sanitizeHTML","hash":{},"data":data})))
    + "\">\n	<meta class=\"meta-data\" property=\"og:image:width\" content=\"1200\"/>\n	<meta class=\"meta-data\" property=\"og:image:height\" content=\"630\"/>\n\n	<meta class=\"meta-data\" name=\"twitter:title\" content=\""
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, (depth0 != null ? depth0.social_title : depth0), {"name":"sanitizeHTML","hash":{},"data":data})))
    + "\" />\n	<meta class=\"meta-data\" name=\"twitter:image\" content=\""
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, (depth0 != null ? depth0.og_image : depth0), {"name":"sanitizeHTML","hash":{},"data":data})))
    + "\" />\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.no_index : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});