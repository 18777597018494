var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, lambda=this.lambda, escapeExpression=this.escapeExpression, functionType="function", helperMissing=helpers.helperMissing, buffer = "        <div class=\"col-xs-"
    + escapeExpression(lambda((depths[1] != null ? depths[1].children_width_mobile : depths[1]), depth0))
    + " col-sm-"
    + escapeExpression(lambda((depths[1] != null ? depths[1].children_width_desktop : depths[1]), depth0))
    + "\">\n            <a href='/"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.category : depth0)) != null ? stack1.slug : stack1), depth0))
    + "/"
    + escapeExpression(((helper = (helper = helpers.slug || (depth0 != null ? depth0.slug : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"slug","hash":{},"data":data}) : helper)))
    + "' id=\"ad-brief-"
    + escapeExpression(lambda((depth0 != null ? depth0.hash_id : depth0), depth0))
    + "\" data-id=\""
    + escapeExpression(lambda((depth0 != null ? depth0.hash_id : depth0), depth0))
    + "\"\n                class=\"landing-ad\">\n                <div class=\"square-image-wrapper\">\n                    <img class=\"square-image\" src=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ad_photos : depth0)) != null ? stack1['0'] : stack1)) != null ? stack1.thumbnail : stack1), depth0))
    + "\" srcset=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.ad_photos : depth0)) != null ? stack1['0'] : stack1)) != null ? stack1.thumbnail : stack1), depth0))
    + " 2x\"\n                        alt=\""
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, (depth0 != null ? depth0.description : depth0), {"name":"sanitizeHTML","hash":{},"data":data})))
    + "\" loading=\"lazy\"/>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.show_meta : depth0), {"name":"if","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                </div>\n            </a>\n        </div>\n";
},"2":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                    <div class=\"info-box\">\n                        <div class=\"pull-left\">\n                            <p class=\"price\">"
    + escapeExpression(((helpers.parsePrice || (depth0 && depth0.parsePrice) || helperMissing).call(depth0, (depth0 != null ? depth0.price : depth0), {"name":"parsePrice","hash":{},"data":data})))
    + escapeExpression(((helpers.translate || (depth0 && depth0.translate) || helperMissing).call(depth0, (depth0 != null ? depth0.currency : depth0), "sweden", {"name":"translate","hash":{},"data":data})))
    + "</p>\n                        </div>\n                        <div class=\"pull-right\">\n                            <p class=\"size\"><b>"
    + escapeExpression(((helpers.shortSize || (depth0 && depth0.shortSize) || helperMissing).call(depth0, (depth0 != null ? depth0.size : depth0), {"name":"shortSize","hash":{},"data":data})))
    + "</b></p>\n                        </div>\n                    </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"landing-block-header\">\n    <h2>"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</h2>\n    <a class=\"pull-right\" href=\""
    + escapeExpression(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"url","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(((helper = (helper = helpers.show_more_title || (depth0 != null ? depth0.show_more_title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"show_more_title","hash":{},"data":data}) : helper)))
    + "<i class=\"icon ion-ios-arrow-thin-right\"></i></a>\n</div>\n<div class=\"landing-block-content\">\n    <div class=\"row tight\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.ads : depth0), {"name":"each","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </div>\n</div>";
},"useData":true,"useDepths":true});