var Backbone = require('backbone');
var Message = require('../models/message');
module.exports = Backbone.Collection.extend({
    model: Message,
    // url: function () {
    //   var url = "/api/conversations";
    //   return url;
    // },
    // parse: function(resp, xhr) {
    //     return resp.conversations;
    // }
    comparator: function(model) {
        return model.id;
    }
});