var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  return "thin-transition";
  },"3":function(depth0,helpers,partials,data) {
  return "anchor";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, functionType="function", escapeExpression=this.escapeExpression, buffer = "<div class=\"transition-block ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.thin_transition : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = ((helpers.filterEmptyDescription || (depth0 && depth0.filterEmptyDescription) || helperMissing).call(depth0, (depth0 != null ? depth0.anchor_id : depth0), {"name":"filterEmptyDescription","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" id=\""
    + escapeExpression(((helper = (helper = helpers.anchor_id || (depth0 != null ? depth0.anchor_id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"anchor_id","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"transition bg-"
    + escapeExpression(((helper = (helper = helpers.background_top || (depth0 != null ? depth0.background_top : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"background_top","hash":{},"data":data}) : helper)))
    + "\"></div>\n    <div class=\"transition bg-"
    + escapeExpression(((helper = (helper = helpers.background_bottom || (depth0 != null ? depth0.background_bottom : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"background_bottom","hash":{},"data":data}) : helper)))
    + "\"></div>\n    <div class=\"transition-content\">";
  stack1 = ((helper = (helper = helpers.transition_content || (depth0 != null ? depth0.transition_content : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"transition_content","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n</div>";
},"useData":true});