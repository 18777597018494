var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "        <div class=\"col-xs-3\">\n            <img src=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.thumbnail_resources : depth0)) != null ? stack1['0'] : stack1)) != null ? stack1.src : stack1), depth0))
    + "\" srcset=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.thumbnail_resources : depth0)) != null ? stack1['1'] : stack1)) != null ? stack1.src : stack1), depth0))
    + " 2x\" />\n        </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"landing-block-header\">\n    <h2>"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</h2>\n</div>\n<div class=\"landing-block-content instagram\">\n    <div class=\"row tight\">\n    <div class=\"image-wrapper\">\n        <a href=\""
    + escapeExpression(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"url","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.instagram_feed : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </a>\n    </div>\n    </div>\n</div>";
},"useData":true});