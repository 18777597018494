var Backbone = require("backbone");
var $ = Backbone.$;
var Events = require("./events");
var AdCollection = require("./collections/ads");
var AdCollectionView = require("./views/ads");
var Landing = require("./views/landing");
var AdDetailedView = require("./views/ad_detailed");
var ChatView = require("./views/chat");
var SocialView = require("./views/social");
var Searchbar = require('./views/search-bar');
var LoginView = require("./views/login");
var helpers = require('../config/helpers.js');
var Ad = require("./models/ad");
var User = require("./models/user");
var UserView = require("./views/user");
var CollectionView = require('./views/collection');
var missingPageTemplate = require("./templates/missing_page.hbs");
var metaData = require("./templates/meta_data.hbs");
const structuredData = require("../helpers/structured_data");

var translate = require('../config/translations.js').new_translate(__translations);
var routemap = {
    "login": "showLogin",
}
var routes = {}

function _removeSpecialCharacters(string) {
    return string.replace(/å/g, 'a')
        .replace(/Å/g, 'A')
        .replace(/ä/g, 'a')
        .replace(/Ä/g, 'A')
        .replace(/ö/g, 'o')
        .replace(/Ö/g, 'O');
}

routes[""] = "dynamicContent";
for (var key in routemap) {
    var parts = key.split('/');
    for (var i in parts) {
        parts[i] = translate(parts[i], "slugs");
    }
    routes[parts.join('/')] = routemap[key];
    routes[_removeSpecialCharacters(parts.join('/'))] = routemap[key];
    for (var i in parts) {
        parts[i] = encodeURIComponent(parts[i])
        parts[i] = parts[i].replace('%3A', ':');
    }
    routes[_removeSpecialCharacters(parts.join('/'))] = routemap[key];
}

routes["*unknown"] = "dynamicContent";
console.log(routes);
module.exports = Backbone.Router.extend({
    initialize: function()  {
        var self = this;
        this.isLoading = false;
        this.routeClasses = [];
        Events.on("router:navigate", this.navigate);
        this.on("route", function(route, params) {
            window._tracking.view(null, route);
        });
        Events.on("router:login", this.login.bind(this));
        Events.on("router:loggedIn", this.loggedIn.bind(this));
        Events.on("router:refresh", function() {
            self.dynamicContent(false);
        });

        this.content = $('body .content-wrapper');
        $(document).on('click', 'a', function(event) {
            if (this.host !== window.location.host) return true;
            // if (!window._SESSION && document.cookie.replace(/(?:(?:^|.*;\s*)loginPromtShown\s*\=\s*([^;]*).*$)|^.*$/, "$1") !== "true") {
            //     event.preventDefault();
            //     Events.trigger('router:login');
            //     document.cookie = "loginPromtShown=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
            // }
            if($(this).hasClass('anchor-button') || event.currentTarget.hash == "#shop-collection"){
                return;
            }
            if (this.host === window.location.host && !$(this).attr('data-navigate')) {
                if ($('body').hasClass('app-view') && this.pathname.indexOf('xy310') > 0){
                    return this.href = 'plick://profile/206055';
                }
                if (this.pathname == '/password/reset') {
                    return;
                }
                var url = event.currentTarget.pathname;
                if (event.currentTarget.search) url += event.currentTarget.search;
                self.navigate(url, { trigger: true });
                window.scrollTo(0, 0);
                event.preventDefault();
            }
        });
        $(document).on('click', '.triggers-login', function(e) {
            if (!window._SESSION) {
                e.preventDefault();
                Events.trigger('router:login');
            }
        });
        $('.sign-in-button').click(function(e) {
            e.preventDefault();
            if (window._SESSION) {
                window.location.href = '/auth/logout';
            } else {
                Events.trigger('router:login');
            }
        });
        $('.inbox-button').click(function(e) {
            e.preventDefault();
            $('.conversation-container').toggleClass('open');
        });
        this.bindMobileScroll();
        this.getSession();
        this.findHeader();
    },
    routes: routes,
    setRouteClassHelper: function(currentClass) {
        $.each($('body').attr('class').split(' '), function(k, val) {
            if (val.substring(0, 4) === "show") {
                $('body').removeClass(val);
            }
        });
        /*$.each(this.routeClasses,function(k,val) {
          $('body').removeClass(val);
        });
        this.routeClasses = [];
        this.routeClasses.push(currentClass);*/
        $('body').addClass(currentClass);
    },
    loggedIn: function(loginType = "refresh") {
        window._cache.users[window.current_user.hash_id] = new User(window.current_user);
        if (this.loginView) {
            var self = this;
            setTimeout(function() {
                self.loginView.finish();
            }, 500);
        }
        $('body').addClass('signed-in')
        this.showChat();
        this.showSocial();
        window._cache.users = {};
        window._cache.items = {};
        window._cache.page_data = {};
        window._chat_sockets.auth(function() {
            window._chat_sockets.listenForChatMessages();
        });
        window._tracking.login(loginType, window.current_user);
        this.dynamicContent();
    },
    login: function() {
        if (!this.loginView) {
            this.loginView = new LoginView();
        }
        $('body').append(this.loginView.render().el);
        this.loginView.show();
    },
    showLogin: function() {
        this.setRouteClassHelper('show-login');
        var login = new LoginView();
        $("#content").html(login.render().el);
    },
    dynamicContent: async function(cache = true) {
        if (this.currentAd) this.currentAd.remove();
        const page = await window._cache.getPage(window.location.pathname, cache);
        this.show404(page);
        this.renderBlocks(page);
        this.showAd(page);
        this.showUser(page);
        this.showFeed(page);
        this.setMetaData(page.meta);
        this.setStructuredData(page);
        $('#canonical').attr('href', helpers.getPreferredURL(page));
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        return true;
    },
    deRenderBlocks: function() {
        if (this.blocksView) {
            this.blocksView.remove();
            delete this.blocksView;
        }
    },
    queryContainsFilterParams() {
        if (location.pathname == "/" && location.href.endsWith('?')) return true;
        if (!window.location.search) return false;
        console.log("Should render a feeed!");
        const params = $.deparam(window.location.search.slice(1));
        return helpers.paramsContainsFilterParams(params);
    },
    renderBlocks: function(page) {
        if (!this.queryContainsFilterParams() && !page.filter_on_base) $('body').addClass('hide-filter');
        else $('body').removeClass('hide-filter');
        if (!page.blocks || !page.blocks.length) return this.deRenderBlocks();
        if (this.queryContainsFilterParams()) return this.deRenderBlocks();
        var pageViews = window._cache.views.pages;
        pageViews[page.path] = pageViews[page.path] || new Landing({ pageData: page });
        this.blocksView = pageViews[page.path].convert().render();
        $("#blocks").html(this.blocksView.el);
        const timers = this.blocksView.$el.find('.collection-count-down');
        this.timer_views = this.timer_views || [];
        if (timers.length) {
            timers.each((i, el) => {
                this.timer_views[i] = this.timer_views[i] || new CollectionView();
                this.timer_views[i].setElement(el);
            });
        }
    },
    showFeed: function(page) {
        const filter = page.options || {};
        if (page && page.page_type === 'user') {
            return $("#feed").html("");
        }
        if (page && page.page_type !== 'dynamic') {
            Events.trigger("header:landing");
            return $("#feed").html("");
        }
        if (!this.queryContainsFilterParams() && (this.blocksView && this.blocksView.hasFooter())) {
            Events.trigger("header:landing");
            return $("#feed").html("");
        }
        Events.trigger("header:feed");
        console.log("Will render feed for", page.path, "with filter", filter)
        var href = window.location.href;
        var cols = window._cache.views.collections;
        cols[href] = cols[href] || this.initAdCollectionView(filter);
        this.header.adCollectionView = cols[href];
        cols[href].header = this.header;
        $("#feed").html(cols[href].render().el);
        this.header.updateForm();
        this.header.renderFilterSummary();
        this.header.toggleSizeGroups();
    },
    initAdCollectionView: function(options) {
        var collection = new AdCollection();
        collection.initParams(window.location.search.substring(1));
        if (options.brand_id) collection.addBrand(options.brand_id);
        if (options.feed) collection.feed = options.feed;
        if (options.category_id) collection.addCategory(options.category_id + "");
        if (options.subcategory_id) collection.addSubcategory(options.subcategory_id);
        if (options.tag) collection.addTag(options.tag);
        if (options.city_id) collection.params.city_id = options.city_id;
        if (options.location) collection.params.location = options.location;
        if (options.query) collection.params.query = options.query;
        var adCollectionView = new AdCollectionView({
            collection: collection,
            header: self.header,
        });
        return adCollectionView;
    },
    initUserAds: function(user) {
        if (!this.isLoading) {
            this.isLoading = true;
            var self = this;

            this.userAdCollection = new AdCollection();
            this.userAdCollection.user = user;
            var view = new AdCollectionView({
                collection: this.userAdCollection,
                header: this.header
            });
            this.userAdCollection.fetch({
                reset: true,
                success: function(ads) {
                    $("#user-ads").html(view.el);
                }
            });
        }
    },
    showChat: function() {
        if (!this.chat) this.chat = new ChatView();
        $('body').append(this.chat.render().el);
    },
    showSocial: function() {
        if (!this.social) this.social = new SocialView();
        $('body').append(this.social.render().el);
    },
    showAd: async function(page) {
        if (!page || page.page_type !== 'ad') return $('#single-ad-container').html("");
        var view = new AdDetailedView({ model: new Ad(page.ad) })
        $('body').attr('show', '');
        $('#single-ad-container').html(view.render().el);
        this.currentAd = view;
        $('body').addClass('hide-filter');
    },
    showUser: async function(page) {
        if (!page || page.page_type !== 'user') return $("#single-user-container").html("");
        var id = (id === "me") ? window.current_user.hash_id : page.user.id;
        var user = new User(page.user);
        if (!user) return this.userView.remove();
        $('body').attr('show', '');
        this.userView = new UserView({
            model: user
        });
        $("#single-user-container").html(this.userView.render().el)
        const adCollectionView = window._cache.views.collections.get(user.getAdCollection());
        this.header.adCollectionView = adCollectionView;
        $("#single-user-container").find('#items-wrapper').html(adCollectionView.render().el);
        this.userView.delegateEvents();
    },
    show404: function(page) {
        if (!page || page.status != 404) return $('.missing-page').html('');
        $('.missing-page').html(missingPageTemplate());
    },
    bindMobileScroll: function() {
        var body = $('body');
        $(document).scroll(function(event) {
            var scrolltop = document.body.scrollTop;
            if (scrolltop < 10) {
                body.removeClass('translucent');
            } else {
                body.addClass('translucent');
            }
        });
    },
    findHeader: function() {
        var header = $('#search-bar');
        this.header = new Searchbar();
        this.header.render();
    },
    getSession: function() {
        var self = this;
        $.get('/auth/session', function(data) {
            if (data.ok) {
                window.current_user = data.user;
                window._SESSION = { current_user: data.user, token: data.token };
                self.loggedIn();
            } else {
                window._SESSION = false;
                console.log("No session found.");
            }
        });
    },
    detectAdId() {
        let slug = window.location.pathname
        let parts = slug.split('/');
        if (parts.length !== 3) return false;
        if (parts[2].split('_').length > 1) return parts[2].split('_').pop();
        if (parts[2].split('-').length > 1) return parts[2].split('_').pop();
        return false;
    },
    setMetaData(meta) {
        if (!meta) return;
        $('.meta-data').remove();
        $('head').prepend(metaData(meta));
        if ($('#debug-seo').length) {
            const entries = `
                <div>
                    <img style="width: 150px;" src="${meta.og_image}">
                </div>
                <div>
                    <p>${meta.title}</p>
                    <p>${meta.description}</p>
                    <p>${$('h1').text()}</p>
                </div>
                <div>
                    <p>OG -${meta.social_title}</p>
                    <p>OG - ${meta.social_description}</p>
                </div>
            `.trim();
            $('#debug-seo').html(entries);
        }
    },
    setStructuredData(page) {
        $('#structured-data').remove();
        $('head').append(structuredData(page));
    },
});