var sizes = require('./translations.js').sizes();
var helpers = require('./helpers.js');
module.exports = {
    generic: function(Handlebars, translations) {
        if (!translations) translations = __translations;
        var translate = require('./translations.js').new_translate(translations);
        var self = this;
        Handlebars = Handlebars || require('hbs');
        Handlebars.registerHelper('breaklines', function(text) {
            text = Handlebars.Utils.escapeExpression(text);
            text = text.replace(/(\r\n|\n|\r)/gm, '<br>');
            text = helpers.sanitize(text);
            return new Handlebars.SafeString(text);
        });
        Handlebars.registerHelper('logga', function(context) {
            console.log("LOGGING:");
            console.log(context);
            console.log("END OF LOG");
        });
        Handlebars.registerHelper('getPulseDuration', function(ad) {
            var score = ad.likes_count * 100;
            if (ad.is_liked) score = score * 2;
            var pulse = 2000 - score;
            if (pulse < 500) pulse = 500;
            var string = "animation-duration:" + pulse + "ms;";
            string += "-o-animation-duration:" + pulse + "ms;";
            string += "-moz-animation-duration:" + pulse + "ms;";
            string += "-webkit-animation-duration:" + pulse + "ms;";
            return new Handlebars.SafeString(string);
        });
        Handlebars.registerHelper('translate', function(string, context) {
            return new Handlebars.SafeString(helpers.sanitize(translate(string, context)));
        });
        Handlebars.registerHelper('generateTitle', function(context) {
            return new Handlebars.SafeString(helpers.sanitize(helpers.generateTitle(context, translate)));
        });
        Handlebars.registerHelper('ratingStars', function(rating) {
            var i = rating;
            var html = '<div class="rating-stars">';
            for (let i = 0; i < 5; i++) {
                html += '<span class="icon ion-heart ';
                html += (rating - i >= 1) ? 'filled' : 'unfilled';
                html += '"></span>';
            }
            html += '</div>';
            return new Handlebars.SafeString(html);
        });
        Handlebars.registerHelper('shouldIndex', function(context) {
            var noindex = false;
            if (context.settings.env == 'staging') noindex = true;
            if (context.meta && context.meta.no_index) noindex = true;
            if (noindex) return new Handlebars.SafeString('<meta name="robots" content="noindex" />');
            return new Handlebars.SafeString('');
        });
        Handlebars.registerHelper('defineCanonical', function(context) {
            var meta = '';
            var url = helpers.getPreferredURL(context);
            if (url) {
                meta += '<link id="canonical" rel="canonical" href="';
                meta += url;
                meta += '" />';
            }
            return new Handlebars.SafeString(meta);
        });
        Handlebars.registerHelper('linkToAndroid', function(context) {
            var meta = '';
            if (context.ads) {
                meta += '<link rel="alternate" href="android-app://swace.se.plick/http/marketplace.plick.se/explorer/';
                if (context.category) meta += context.category.id;
            } else if (context.user) {
                meta += '<link rel="alternate" href="android-app://swace.se.plick/http/marketplace.plick.se/users/';
                meta += context.user.hash_id;
            } else if (context.ad) {
                meta += '<link rel="alternate" href="android-app://swace.se.plick/http/marketplace.plick.se/items/';
                meta += context.ad.hash_id;
            }
            if (meta) meta += '" />';
            return new Handlebars.SafeString(meta);
        });
        Handlebars.registerHelper('generateDescription', function(context) {
            return new Handlebars.SafeString(helpers.sanitize(helpers.generateDescription(context)));
        });
        Handlebars.registerHelper('getImage', function(context) {
            var image = "https://cdn.plick.se/misc/images/og-default-image.jpg";
            if (context.meta && context.meta.og_image) {
                image = context.meta.og_image;
            } else {
                if (context.ads) {

                } else if (context.user && context.user.ads && context.user.ads.length) {
                    if (context.user.fb_share_image_url) image = context.user.fb_share_image_url;
                    else if (context.user.ads[0].ad_photos[0]) image = context.user.ads[0].ad_photos[0].photo;
                } else if (context.ad) {
                    image = context.ad.fb_share_image_url;
                    if (!image && context.ad.ad_photos.length) image = context.ad.ad_photos[0].photo;
                }
            }
            return new Handlebars.SafeString(image);
        });
        Handlebars.registerHelper('getAppURL', function(context) {
            var url = helpers.getPreferredURL(context);
            return new Handlebars.SafeString('plick://' + url.split('https://')[1]);
        });
        Handlebars.registerHelper('getURL', function(context) {
            var url = helpers.getPreferredURL(context);
            return new Handlebars.SafeString(url);
        });
        Handlebars.registerHelper('sanitizeHTML', function(dirty) {
            return new Handlebars.SafeString(helpers.sanitize(dirty));
        });
        Handlebars.registerHelper('replaceEmojis', function(message) {
            return new Handlebars.SafeString(helpers.replaceEmojis(helpers.sanitize(message)));
        });
        Handlebars.registerHelper('timeAgo', function(date) {
            var result = helpers.timeAgo(date);
            return new Handlebars.SafeString(result)
        });
        Handlebars.registerHelper('prettifyDateTime', function(timestamp) {
            var months = ["Jan","Feb","Mars","Apr","May","June","July","Aug","Sep","Oct","Nov","Dec"];
            var days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
            var dateTime = new Date(timestamp)
            var curr_day = translate(days[dateTime.getDay()], "weekdays");
            var curr_month = translate(months[dateTime.getMonth()], "months");
            var curr_date = dateTime.getDate();
            var curr_hour = dateTime.getHours();
            var curr_min = dateTime.getMinutes();
            var result = curr_day + " " + curr_date + " " + curr_month + " kl " + curr_hour + ":" + curr_min;
            return result;
        });
        Handlebars.registerHelper('shortSize', function(size) {
            if (size && typeof size === "string" && sizes[size.toLowerCase()]) return new Handlebars.SafeString(sizes[size.toLowerCase()]);
            return new Handlebars.SafeString(helpers.sanitize(size));
        });
        Handlebars.registerHelper('ifCond', function(v1, operator, v2, options) {
            // console.log("testing:" ,v1 , operator, v2);
            switch (operator) {
                case '==':
                    return (v1 == v2) ? options.fn(this) : options.inverse(this);
                case '===':
                    return (v1 == v2) ? options.fn(this) : options.inverse(this);
                case '!==':
                    return (v1 !== v2) ? options.fn(this) : options.inverse(this);
                case '<':
                    return (v1 < v2) ? options.fn(this) : options.inverse(this);
                case '<=':
                    return (v1 <= v2) ? options.fn(this) : options.inverse(this);
                case '>':
                    return (v1 > v2) ? options.fn(this) : options.inverse(this);
                case '>=':
                    return (v1 >= v2) ? options.fn(this) : options.inverse(this);
                case '&&':
                    return (v1 && v2) ? options.fn(this) : options.inverse(this);
                case '||':
                    return (v1 || v2) ? options.fn(this) : options.inverse(this);
                default:
                    return options.inverse(this);
            }
        });
        Handlebars.registerHelper('each_row', function(context, options) {
            var res = "";
            var rows = context.split(/\n/);
            for (var i in rows) {
                var attrs = rows[i].split(':');
                res += options.fn({ slug: attrs[0], name: attrs[1] });
            }
            return res;
        });
        Handlebars.registerHelper('messagePreviewText', function(message, max) {
            var short_message = message.body || "";
            if (message.body && message.body.length > max - message.user.name.length - 2) short_message = short_message.substring(0, max - 3 - message.user.name.length) + "...";
            var final = '<span class="username">' + message.user.name + ":</span> " + '</span class="message">' + short_message + '</span>';
            return new Handlebars.SafeString(helpers.sanitize(final));
        });
        Handlebars.registerHelper('parsePrice', function(price, currency) {
            var price = price && parseInt(price);
            price = price / 100;
            price = price + translate(" " + currency, "item");
            return new Handlebars.SafeString(price || "");
        });

        Handlebars.registerHelper('parseShippingPrice', function(ad) {
            if (!ad.shipping_price) return "";
            var originalPrice = ad.shipping_price/100;
            var discountedPrice = ad.discounted_shipping_price/100;
            var priceString = `Frakt från <span class="${discountedPrice && "strike-through"}">${originalPrice} kr</span>`;
            if (discountedPrice) priceString += ` <span class="discounted-price">${discountedPrice} kr</span>`;
            return new Handlebars.SafeString(priceString);
        });
        Handlebars.registerHelper('filterEmptyDescription', function(description, options) {
            if (description && description != "User has no description yet.") {
                return options.fn(this);
            }
        });
        return this;
    },
    user: function(Handlebars) {},
    ad: function(Handlebars, translations) {
        Handlebars = Handlebars || require('hbs');
        if (!Handlebars.handlebars || !Handlebars.handlebars.helpers.acf_options) {
            Handlebars.registerHelper('acf_options', function(context, options) {
                return options.fn(window._bootstrap.acf_options);
            });
        }
        Handlebars.registerHelper('shouldReferToRelated', function(ad, options) {
            if (ad.sold || ad.removed) return options.fn(this);
            options.inverse(this);
        });
        Handlebars.registerHelper('renderImages', function(ad) {
            var images = ad.ad_photos;
            var html = '';
            for (var i = 0; i < images.length; i++) {
                var src = ' src="' + images[i].photo + '"';
                var classes = ' class="item-image ';
                if (!i) classes += 'active';
                classes += '"';
                var data = ' data-image="' + i + '"';
                if (ad.category) var alt = ' alt="' + helpers.sanitize(ad.description) + '. ' + helpers.sanitize(ad.category.name) + '."';
                else var alt = "";
                var img = '<img' + src + classes + data + alt + '>';
                html += img;
            }
            html += '';
            return new Handlebars.SafeString(html);
        });
    },
}