var translate = require('./translations.js').translate;
var variables = require('./globals.js');
var debug = require('debug')('plick:config:helpers');

module.exports = {
    transform: function($el, string) {
        $el.css({
            "-webkit-transform": string,
            "-moz-transform": string,
            "-ms-transform": string,
            "-o-transform": string,
            "transform": string
        })
    },
    sanitize: function(dirty) {
        var bleach = require('bleach');
        var whitelist = [
            'a',
            'b',
            'i',
            'em',
            'strong',
            'span',
            'br'
        ];
        var options = {
            mode: 'white',
            list: whitelist
        };
        return bleach.sanitize(dirty, options);
    },
    paramsContainsFilterParams(params) {
        const whitelist = [
            "gender",
            "category_ids",
            "brand_ids",
            "size_ids",
            "tags",
            "color",
            "query",
            "order_by"
        ]
        return Object.keys(params).some((q) => whitelist.includes(q))
    },
    getSMSBody: function(type, url) {
        return variables.SMS_SHARE_BODY.replace("{{TYPE}}", type).replace("{{URL}}", url);
    },
    getMailBody: function(type, url) {
        return variables.MAIL_SHARE_BODY.replace("{{TYPE}}", type).replace("{{URL}}", url);
    },
    getMailSubject: function(type, url) {
        return variables.MAIL_SHARE_SUBJECT.replace("{{TYPE}}", type);
    },
    timeAgo: function(date) {
        if (date) {
            if (!(typeof date === 'Date')) date = new Date(date);

            var seconds = Math.floor((new Date() - date) / 1000);
            var interval = Math.floor(seconds / 31536000);

            if (interval > 1) {
                return interval + " år sedan";
            }
            interval = Math.floor(seconds / 2592000);
            if (interval > 1) {
                return interval + " månader sedan";
            }
            interval = Math.floor(seconds / 86400);
            if (interval > 1) {
                return interval + " dagar sedan";
            }
            interval = Math.floor(seconds / 3600);
            if (interval >= 1) {
                if (interval === 1) return "en timme sedan";
                return interval + " timmar sedan";
            }
            interval = Math.floor(seconds / 60);
            if (interval >= 1) {
                if (interval == 1) return "1 minut sedan";
                return interval + " minuter sedan";
            }
            return "Några sekunder sedan";
        } 
        else {
            return " ";
        }
    },
    getPreferredURL: function(context) {
        const country = (context.market && context.market.country) || window._bootstrap.market.country;
        var url = "https://" + variables.BASE_URL(country);
        if (context.canonical) {
            return context.canonical;
        }
        if (context.path) {
            if (context.path[0] !== '/' && url.substr(-1) !== '/') url += '/';
            return url + context.path;
        }
        if (context.ads) {
            if (context.brand) {
                url += context.brand.path;
            }
            if (context.category) {
                url += context.category.path;
            }
        } else if (context.user) {
            url += context.user.preferredURL;
        } else if (context.ad) {
            url += "/" + context.ad.category.slug;
            url += '/' + context.ad.slug;
        }
        return url;
    },
    generateDescription: function(context) {
        var desc = "Fynda begagnade kläder, skor & accessoarer från tusentals populära varumärken. Köp, sälj & upptäck second hand kläder på Plick.";
        if (context.meta && context.meta['custom_description_' + translate('EN', "lang")]) return context.meta['custom_description_' + translate('EN', "lang")];
        if (context.meta && context.meta.custom_description) return context.meta.custom_description;
        if (context.ad) return context.ad.description;
        return desc;
    },
    generateTitle: function(context, translator) {
        if (translator) translate = translator;
        if (!context) var context = {};
        var title = "";
        if (context.meta && context.meta['custom_title_' + translate('EN', "lang")]) return context.meta['custom_title_' + translate('EN', "lang")];
        if (context.meta && context.meta.custom_title) return context.meta.custom_title;
        //Backwards Compat
        if (context.title) {
            title += context.title;
        } else if (context.query) {
            title += context.query
        } else {
        }
        return title.replace(/,/g, '');
    },
    validateEmail: function(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // console.log("validate Email: " + email + " "+ re.test(email));
        return re.test(email);
    },
    replaceEmojis: function(str) {
        var ranges = [
            '\ud83c[\udf00-\udfff]', // U+1F300 to U+1F3FF
            '\ud83d[\udc00-\ude4f]', // U+1F400 to U+1F64F
            '\ud83d[\ude80-\udeff]' // U+1F680 to U+1F6FF
        ];
        if (!str) return "";
        return str.replace(new RegExp(ranges.join('|'), 'g'), '<span class="emoji" data-emoji="$&">$&</span>');
    },
    capitalizeFirstLetter: function(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    stringTruncate: function(str, length) {
        var dots = str.length > length ? '' : '';
        return str.substring(0, length) + dots;
    }
}