var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  return " is-following";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = "<div class=\"single-list-user-wrapper\">\n	<div class=\"col-xs-3 no-padding\">\n		<div class=\"image-wrapper\">\n			<a class=\"user-link\" href=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.preferredURL : stack1), depth0))
    + "\">\n				<img class=\"circle\" src=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.user_photo : stack1), depth0))
    + "\">\n			</a>\n		</div>\n	</div>\n	<div class=\"col-xs-5 conversation-details no-padding vertical-align-outer\">\n		<div class=\"vertical-align-inner\">\n			<a class=\"user-link\" href=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.preferredURL : stack1), depth0))
    + "\">\n				<p class=\"user-name dark-text\">"
    + escapeExpression(((helpers.sanitizeHTML || (depth0 && depth0.sanitizeHTML) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1), {"name":"sanitizeHTML","hash":{},"data":data})))
    + "</p>\n			</a>\n		</div>\n	</div>\n	<div class=\"col-xs-4 no-padding new-vertical-center\">\n		<button class=\"btn btn-plick btn-pink btn-follow";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.is_following : stack1), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\"></button>\n	</div>\n</div>";
},"useData":true});