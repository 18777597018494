var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "			<img class=\"logo\" src=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.brand : depth0)) != null ? stack1.logo : stack1), depth0))
    + "\"/>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<a href=\"/märkeskläder/"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.brand : depth0)) != null ? stack1.slug : stack1), depth0))
    + "\" class=\"autocomplete-result brand resist-sans\" data-id=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.brand : depth0)) != null ? stack1.id : stack1), depth0))
    + "\" data-slug=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.brand : depth0)) != null ? stack1.slug : stack1), depth0))
    + "\">\n	<span class=\"logo-wrapper\">\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.brand : depth0)) != null ? stack1.logo : stack1), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "	</span>\n	<span class=\"name new-vertical-center\"><span class=\"new-vertical-center-inner\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.brand : depth0)) != null ? stack1.name : stack1), depth0))
    + "</span></span>\n</a>";
},"useData":true});