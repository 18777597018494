var Backbone = require('backbone');
var Events = require("../events");
var $ = require("jquery");
var translate = require("../../config/translations").translate;

module.exports = Backbone.Model.extend({
    idAttribute: "hash_id",
    defaults: {
        ads_count: "~",
        followers_count: "~",
        following_count: "~",

    },
    initialize: function() {
        // this.render();
        // fetchAds();
    },
    url: function() {
        var url = "/api/users/" + this.get('hash_id');
        return url;
    },
    parse: function(resp, xhr) {
        return resp.user || resp;
    },
    follow: function() {
        var self = this;
        $.ajax({
            url: '/api/users/' + this.id + '/follow',
            type: 'POST',
            contentType: 'application/json',
            success: function(resp) {
                self.attributes.is_following = true;
                window._tracking.interaction('follow', self.id);
                window._cache.social.follows.add(self, { at: 0 });
                window._cache.users[self.attributes.hash_id] = self;
                self.trigger('change');
            },
            error: function(resp) {
                console.log("Follow Request Failed, Move along :)");
            },
        });
    },
    unfollow: function() {
        var self = this;
        $.ajax({
            url: '/api/users/' + this.id + '/follow',
            type: 'DELETE',
            contentType: 'application/json',
            success: function(resp) {
                self.attributes.is_following = false;
                window._tracking.interaction('follow', self.id);
                self.trigger('change');
            },
            error: function(resp) {
                console.log("Follow Request Failed, Move along :)");
            },
        });
    },
    getAdCollection: function() {
        var AdCollection = require("../collections/ads");
        this.userAdCollection = this.userAdCollection || new AdCollection([], { user: this });
        return this.userAdCollection;
    },
    dataMerge: function(attributes) {
        Object.assign(this.attributes, attributes);
        this.trigger('change');
        return this;
    },
    renderAndFetch: function() {
        var self = this;
        var UserView = require("../views/user");
        var userId = this.get('hash_id');
        if (window._cache.users[self.id]) {
            var view = new UserView({ model: window._cache.users[self.id] });
        } else {
            var view = new UserView({ model: self });
            this.fetch({
                success: function() {
                    if (view.$el.is(':visible')) {
                        window._cache.users[self.id] = self;
                        view.render();
                    }
                }
            });
        }
        Events.trigger("router:navigate", translate("users", self.attributes.country) + "/" + userId, { trigger: false });
    }
});