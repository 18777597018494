var Backbone = require('backbone');
var MiniConversationView = require('../views/conversation_brief');
var ConversationCollection = require('../collections/conversations');
var Conversation = require('../models/conversation');
var AdConversationsTemplate = require("../templates/selling_conversations.hbs");
var Events = require("../events");

module.exports = Backbone.View.extend({
	tagName: "div",
	className: "single-item-conversation-list",
	events : {
		"click .details": "itemDetailsClick"
	},
	initialize: function (options) {
    	var self = this;
    	this.$el.data('item-id', this.model.id);
	    this.listenTo(self.model.conversations, "change", this.render);
    	Events.on("update:timestamps", this.updateTimestamps.bind(this));
  	},
	updateModel: function(notification) {
		this.model.updateConversation(notification.conversation);
	},
	itemDetailsClick: function(e) {
		e.preventDefault();
	},
	updateTimestamps: function() {
		
	},
	setUnread: function() {
		this.$el.attr('unread', this.model.getUnread());
	},
	render: function() {
		var self = this;
		var view = AdConversationsTemplate(this.model.attributes);
		self.$el.html(view);
		self.setUnread();
		return self;
	}
});